import React from "react";

function Logo({
  width,
  theme = { primaryColor: "white", hiddenColor: "#5F43F7" }
}) {
  const hiddenColor = theme.hiddenColor;
  const primaryColor = theme.primaryColor;

  return (
    <svg
      width={width}
      viewBox="0 0 2021 346"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M209.97 148.976C209.97 188.219 199.021 217.814 177.122 237.761C155.224 257.491 124.654 267.356 85.4103 267.356H20.041V35.1491H92.2399C116.089 35.1491 136.795 39.4854 154.357 48.1579C172.136 56.8304 185.795 69.6224 195.335 86.5339C205.091 103.229 209.97 124.043 209.97 148.976ZM166.39 150.277C166.39 122.525 159.994 102.144 147.202 89.1356C134.627 76.1268 116.306 69.6224 92.2399 69.6224H61.6692V232.558H86.7112C139.83 232.558 166.39 205.131 166.39 150.277Z"
        fill={primaryColor}
      />
      <path
        d="M326.505 87.8348C350.138 87.8348 368.784 94.8812 382.443 108.974C396.319 123.067 403.257 142.689 403.257 167.839V188.653H286.828C287.262 204.697 291.707 217.164 300.162 226.053C308.618 234.726 320.326 239.062 335.286 239.062C346.56 239.062 356.751 237.978 365.857 235.81C374.963 233.425 384.286 230.064 393.826 225.728V258.575C385.153 262.695 376.155 265.73 366.832 267.682C357.509 269.633 346.344 270.608 333.335 270.608C316.206 270.608 300.921 267.356 287.479 260.852C274.253 254.131 263.846 244.049 256.258 230.606C248.886 217.164 245.2 200.469 245.2 180.522C245.2 150.385 252.68 127.403 267.64 111.576C282.6 95.7485 302.222 87.8348 326.505 87.8348ZM326.505 118.08C315.448 118.08 306.45 121.658 299.512 128.813C292.791 135.751 288.888 146.158 287.804 160.034H363.58C363.363 147.892 360.22 137.919 354.149 130.113C348.295 122.091 339.08 118.08 326.505 118.08Z"
        fill={primaryColor}
      />
      <path
        d="M564.584 216.297C564.584 234.075 558.188 247.626 545.396 256.949C532.821 266.055 514.717 270.608 491.084 270.608C478.726 270.608 468.102 269.741 459.213 268.007C450.54 266.272 442.084 263.562 433.846 259.876V225.078C442.518 229.197 452.058 232.666 462.465 235.485C473.089 238.303 483.062 239.713 492.385 239.713C503.659 239.713 511.79 237.978 516.777 234.509C521.763 231.04 524.257 226.379 524.257 220.525C524.257 217.056 523.281 214.02 521.33 211.418C519.378 208.6 515.476 205.564 509.622 202.312C503.985 199.06 495.529 195.049 484.255 190.279C472.98 185.943 463.549 181.498 455.961 176.945C448.589 172.175 442.952 166.646 439.049 160.359C435.363 154.071 433.52 145.941 433.52 135.967C433.52 120.14 439.7 108.215 452.058 100.193C464.633 91.9542 481.328 87.8348 502.142 87.8348C512.982 87.8348 523.173 88.9188 532.712 91.087C542.469 93.2551 552.226 96.5073 561.982 100.844L548.974 131.089C540.735 127.62 532.713 124.693 524.907 122.308C517.102 119.923 509.08 118.731 500.841 118.731C482.845 118.731 473.848 123.826 473.848 134.016C473.848 137.702 474.932 140.846 477.1 143.447C479.485 146.049 483.496 148.868 489.133 151.903C494.987 154.722 503.226 158.191 513.85 162.31C524.257 166.43 533.255 170.766 540.843 175.319C548.431 179.655 554.285 185.076 558.405 191.58C562.524 198.084 564.584 206.323 564.584 216.297Z"
        fill={primaryColor}
      />
      <path
        d="M624.501 23.116C630.788 23.116 636.1 24.742 640.437 27.9942C644.99 31.0296 647.266 36.5584 647.266 44.5805C647.266 52.6026 644.99 58.2397 640.437 61.4919C636.1 64.7441 630.788 66.3702 624.501 66.3702C618.213 66.3702 612.793 64.7441 608.24 61.4919C603.904 58.2397 601.735 52.6026 601.735 44.5805C601.735 36.5584 603.904 31.0296 608.24 27.9942C612.793 24.742 618.213 23.116 624.501 23.116ZM644.99 91.087V267.356H604.012V91.087H644.99Z"
        fill={primaryColor}
      />
      <path
        d="M756.576 87.8348C778.908 87.8348 796.361 96.5073 808.936 113.852H810.562L814.465 91.087H848.613V268.657C848.613 293.808 841.458 312.887 827.148 325.896C813.056 338.905 791.916 345.409 763.731 345.409C751.155 345.409 739.556 344.542 728.932 342.807C718.308 341.29 708.226 338.58 698.687 334.677V300.854C718.85 309.96 741.399 314.513 766.332 314.513C779.775 314.513 789.965 310.719 796.903 303.131C804.058 295.759 807.635 284.918 807.635 270.608V266.055C807.635 262.803 807.744 259.009 807.961 254.673C808.177 250.336 808.503 246.867 808.936 244.266H807.635C801.565 253.372 794.084 260.093 785.195 264.429C776.523 268.549 766.658 270.608 755.6 270.608C734.136 270.608 717.333 262.586 705.191 246.542C693.049 230.498 686.979 208.166 686.979 179.547C686.979 150.927 693.049 128.487 705.191 112.226C717.549 95.9653 734.678 87.8348 756.576 87.8348ZM767.308 121.332C741.724 121.332 728.932 140.954 728.932 180.197C728.932 218.79 741.941 238.086 767.958 238.086C782.268 238.086 792.784 234.184 799.505 226.379C806.443 218.356 809.912 204.914 809.912 186.051V179.872C809.912 159.058 806.443 144.098 799.505 134.992C792.784 125.886 782.051 121.332 767.308 121.332Z"
        fill={primaryColor}
      />
      <path
        d="M995.28 87.8348C1015.01 87.8348 1030.4 92.9299 1041.46 103.12C1052.74 113.31 1058.37 129.788 1058.37 152.554V267.356H1017.39V160.684C1017.39 134.233 1006.55 121.007 984.873 121.007C968.395 121.007 957.121 126.211 951.05 136.618C944.979 147.025 941.944 161.877 941.944 181.173V267.356H900.966V91.087H932.512L938.366 114.178H940.643C946.28 105.071 953.977 98.4586 963.733 94.3392C973.707 90.0029 984.222 87.8348 995.28 87.8348Z"
        fill={primaryColor}
      />
      <path
        d="M1288.5 35.1491L1208.17 267.356H1163.61L1083.28 35.1491H1125.89L1172.72 177.27C1174.02 181.173 1175.54 186.268 1177.27 192.556C1179.01 198.626 1180.63 204.914 1182.15 211.418C1183.89 217.923 1185.19 223.56 1186.05 228.33C1186.7 223.56 1187.79 217.923 1189.31 211.418C1190.82 204.914 1192.45 198.626 1194.18 192.556C1195.92 186.268 1197.55 181.173 1199.06 177.27L1245.89 35.1491H1288.5Z"
        fill={primaryColor}
      />
      <path
        d="M1378.69 87.8348C1401.46 87.8348 1418.69 92.8215 1430.4 102.795C1442.33 112.768 1448.29 128.162 1448.29 148.976V267.356H1419.34L1411.21 242.965H1409.91C1402.32 252.505 1394.3 259.551 1385.85 264.104C1377.39 268.44 1365.9 270.608 1351.37 270.608C1335.55 270.608 1322.43 266.164 1312.02 257.274C1301.61 248.385 1296.41 234.617 1296.41 215.971C1296.41 197.759 1303.02 184.1 1316.25 174.994C1329.69 165.888 1349.96 160.792 1377.07 159.708L1407.64 158.733V149.952C1407.64 138.894 1404.93 130.981 1399.51 126.211C1394.09 121.224 1386.5 118.731 1376.74 118.731C1367.85 118.731 1359.29 120.032 1351.05 122.633C1343.03 125.235 1335 128.27 1326.98 131.739L1313.97 103.12C1322.65 98.567 1332.51 94.8812 1343.57 92.0626C1354.84 89.244 1366.55 87.8348 1378.69 87.8348ZM1407.64 184.1L1386.17 184.75C1368.18 185.401 1355.71 188.545 1348.77 194.182C1341.83 199.602 1338.36 206.974 1338.36 216.297C1338.36 224.752 1340.75 230.823 1345.52 234.509C1350.51 237.978 1357.01 239.713 1365.03 239.713C1376.96 239.713 1387.04 236.352 1395.28 229.631C1403.52 222.693 1407.64 212.502 1407.64 199.06V184.1Z"
        fill={primaryColor}
      />
      <path
        d="M1540.62 267.356H1499.64V20.189H1540.62V267.356Z"
        fill={primaryColor}
      />
      <path
        d="M1633.99 267.356H1593.01V20.189H1633.99V267.356Z"
        fill={primaryColor}
      />
      <path
        d="M1757.28 87.8348C1780.92 87.8348 1799.56 94.8812 1813.22 108.974C1827.1 123.067 1834.04 142.689 1834.04 167.839V188.653H1717.61C1718.04 204.697 1722.48 217.164 1730.94 226.053C1739.4 234.726 1751.1 239.062 1766.06 239.062C1777.34 239.062 1787.53 237.978 1796.63 235.81C1805.74 233.425 1815.06 230.064 1824.6 225.728V258.575C1815.93 262.695 1806.93 265.73 1797.61 267.682C1788.29 269.633 1777.12 270.608 1764.11 270.608C1746.98 270.608 1731.7 267.356 1718.26 260.852C1705.03 254.131 1694.62 244.049 1687.04 230.606C1679.66 217.164 1675.98 200.469 1675.98 180.522C1675.98 150.385 1683.46 127.403 1698.42 111.576C1713.38 95.7485 1733 87.8348 1757.28 87.8348ZM1757.28 118.08C1746.23 118.08 1737.23 121.658 1730.29 128.813C1723.57 135.751 1719.67 146.158 1718.58 160.034H1794.36C1794.14 147.892 1791 137.919 1784.93 130.113C1779.07 122.091 1769.86 118.08 1757.28 118.08Z"
        fill={primaryColor}
      />
      <path
        d="M1842.66 91.087H1887.22L1922.34 190.604C1924.73 196.892 1926.68 203.071 1928.19 209.142C1929.71 215.213 1930.8 221.392 1931.45 227.679H1932.75C1933.61 222.259 1934.92 216.297 1936.65 209.792C1938.38 203.288 1940.34 196.892 1942.5 190.604L1976.33 91.087H2020.56L1945.43 290.772C1938.71 308.334 1929.49 321.776 1917.79 331.099C1906.3 340.639 1892.09 345.409 1875.18 345.409C1869.76 345.409 1865.1 345.084 1861.2 344.433C1857.3 344 1853.83 343.458 1850.79 342.807V310.285C1853.18 310.719 1856 311.153 1859.25 311.586C1862.72 312.02 1866.29 312.237 1869.98 312.237C1880.17 312.237 1888.19 309.201 1894.05 303.131C1900.12 297.277 1904.78 289.905 1908.03 281.016L1913.23 266.706L1842.66 91.087Z"
        fill={primaryColor}
      />
      <path
        d="M209.263 148.269C209.263 187.512 198.313 217.107 176.415 237.054C154.517 256.784 123.946 266.649 84.7032 266.649H19.334V34.4421H91.5329C115.382 34.4421 136.088 38.7783 153.65 47.4509C171.429 56.1234 185.088 68.9154 194.628 85.8269C204.384 102.521 209.263 123.336 209.263 148.269ZM165.683 149.57C165.683 121.818 159.287 101.437 146.495 88.4286C133.92 75.4198 115.599 68.9154 91.5329 68.9154H60.9622V231.851H86.0041C139.123 231.851 165.683 204.424 165.683 149.57Z"
        fill={primaryColor}
      />
      <path
        d="M325.798 87.1277C349.431 87.1277 368.077 94.1742 381.736 108.267C395.612 122.36 402.55 141.982 402.55 167.132V187.946H286.121C286.555 203.99 291 216.457 299.455 225.346C307.911 234.019 319.619 238.355 334.579 238.355C345.853 238.355 356.044 237.271 365.15 235.103C374.256 232.718 383.579 229.357 393.119 225.021V257.868C384.446 261.988 375.448 265.023 366.125 266.974C356.802 268.926 345.637 269.901 332.628 269.901C315.499 269.901 300.214 266.649 286.772 260.145C273.546 253.424 263.139 243.342 255.551 229.899C248.179 216.457 244.493 199.762 244.493 179.815C244.493 149.678 251.973 126.696 266.933 110.869C281.893 95.0414 301.515 87.1277 325.798 87.1277ZM325.798 117.373C314.741 117.373 305.743 120.951 298.805 128.105C292.084 135.044 288.181 145.451 287.097 159.327H362.873C362.656 147.185 359.513 137.212 353.442 129.406C347.588 121.384 338.373 117.373 325.798 117.373Z"
        fill={primaryColor}
      />
      <path
        d="M563.877 215.59C563.877 233.368 557.481 246.919 544.689 256.242C532.114 265.348 514.01 269.901 490.377 269.901C478.019 269.901 467.395 269.034 458.506 267.3C449.833 265.565 441.377 262.855 433.139 259.169V224.371C441.811 228.49 451.351 231.959 461.758 234.778C472.382 237.596 482.355 239.006 491.678 239.006C502.952 239.006 511.083 237.271 516.07 233.802C521.056 230.333 523.55 225.672 523.55 219.818C523.55 216.349 522.574 213.313 520.623 210.711C518.671 207.893 514.769 204.857 508.915 201.605C503.278 198.353 494.822 194.342 483.548 189.572C472.273 185.236 462.842 180.791 455.253 176.238C447.882 171.468 442.245 165.939 438.342 159.652C434.656 153.364 432.813 145.234 432.813 135.26C432.813 119.433 438.992 107.508 451.351 99.4861C463.926 91.2472 480.621 87.1277 501.435 87.1277C512.275 87.1277 522.466 88.2118 532.005 90.3799C541.762 92.5481 551.519 95.8003 561.275 100.137L548.266 130.382C540.028 126.913 532.005 123.986 524.2 121.601C516.395 119.216 508.373 118.024 500.134 118.024C482.138 118.024 473.141 123.119 473.141 133.309C473.141 136.995 474.225 140.139 476.393 142.74C478.778 145.342 482.789 148.161 488.426 151.196C494.28 154.015 502.519 157.484 513.143 161.603C523.55 165.723 532.547 170.059 540.136 174.612C547.724 178.948 553.578 184.369 557.698 190.873C561.817 197.377 563.877 205.616 563.877 215.59Z"
        fill={primaryColor}
      />
      <path
        d="M623.794 22.4089C630.081 22.4089 635.393 24.035 639.73 27.2872C644.283 30.3226 646.559 35.8513 646.559 43.8734C646.559 51.8956 644.283 57.5327 639.73 60.7849C635.393 64.0371 630.081 65.6632 623.794 65.6632C617.506 65.6632 612.086 64.0371 607.533 60.7849C603.196 57.5327 601.028 51.8956 601.028 43.8734C601.028 35.8513 603.196 30.3226 607.533 27.2872C612.086 24.035 617.506 22.4089 623.794 22.4089ZM644.283 90.3799V266.649H603.305V90.3799H644.283Z"
        fill={primaryColor}
      />
      <path
        d="M755.869 87.1277C778.201 87.1277 795.654 95.8003 808.229 113.145H809.855L813.758 90.3799H847.906V267.95C847.906 293.1 840.751 312.18 826.441 325.189C812.349 338.198 791.209 344.702 763.024 344.702C750.448 344.702 738.849 343.835 728.225 342.1C717.601 340.583 707.519 337.872 697.98 333.97V300.147C718.143 309.253 740.692 313.806 765.625 313.806C779.068 313.806 789.258 310.012 796.196 302.423C803.351 295.052 806.928 284.211 806.928 269.901V265.348C806.928 262.096 807.037 258.302 807.254 253.966C807.47 249.629 807.796 246.16 808.229 243.559H806.928C800.857 252.665 793.377 259.386 784.488 263.722C775.816 267.842 765.951 269.901 754.893 269.901C733.429 269.901 716.625 261.879 704.484 245.835C692.342 229.791 686.272 207.459 686.272 178.84C686.272 150.22 692.342 127.78 704.484 111.519C716.842 95.2582 733.971 87.1277 755.869 87.1277ZM766.601 120.625C741.017 120.625 728.225 140.247 728.225 179.49C728.225 218.083 741.234 237.379 767.251 237.379C781.561 237.379 792.077 233.477 798.798 225.672C805.736 217.649 809.205 204.207 809.205 185.344V179.165C809.205 158.351 805.736 143.391 798.798 134.285C792.077 125.178 781.344 120.625 766.601 120.625Z"
        fill={primaryColor}
      />
      <path
        d="M994.573 87.1277C1014.3 87.1277 1029.7 92.2229 1040.75 102.413C1052.03 112.603 1057.67 129.081 1057.67 151.847V266.649H1016.69V159.977C1016.69 133.526 1005.85 120.3 984.166 120.3C967.688 120.3 956.414 125.504 950.343 135.911C944.272 146.318 941.237 161.17 941.237 180.466V266.649H900.259V90.3799H931.805L937.659 113.471H939.936C945.573 104.364 953.27 97.7516 963.026 93.6321C973 89.2959 983.515 87.1277 994.573 87.1277Z"
        fill={primaryColor}
      />
      <path
        d="M1287.79 34.4421L1207.46 266.649H1162.91L1082.58 34.4421H1125.18L1172.01 176.563C1173.31 180.466 1174.83 185.561 1176.57 191.849C1178.3 197.919 1179.93 204.207 1181.44 210.711C1183.18 217.216 1184.48 222.853 1185.35 227.623C1186 222.853 1187.08 217.216 1188.6 210.711C1190.12 204.207 1191.74 197.919 1193.48 191.849C1195.21 185.561 1196.84 180.466 1198.36 176.563L1245.19 34.4421H1287.79Z"
        fill={primaryColor}
      />
      <path
        d="M1377.98 87.1277C1400.75 87.1277 1417.99 92.1144 1429.69 102.088C1441.62 112.061 1447.58 127.455 1447.58 148.269V266.649H1418.64L1410.51 242.258H1409.21C1401.62 251.798 1393.59 258.844 1385.14 263.397C1376.68 267.733 1365.19 269.901 1350.67 269.901C1334.84 269.901 1321.72 265.457 1311.31 256.567C1300.91 247.678 1295.7 233.91 1295.7 215.264C1295.7 197.052 1302.32 183.393 1315.54 174.287C1328.98 165.181 1349.26 160.085 1376.36 159.001L1406.93 158.026V149.245C1406.93 138.187 1404.22 130.274 1398.8 125.504C1393.38 120.517 1385.79 118.024 1376.03 118.024C1367.14 118.024 1358.58 119.325 1350.34 121.926C1342.32 124.528 1334.3 127.563 1326.27 131.032L1313.27 102.413C1321.94 97.86 1331.8 94.1742 1342.86 91.3556C1354.13 88.537 1365.84 87.1277 1377.98 87.1277ZM1406.93 183.393L1385.46 184.043C1367.47 184.694 1355 187.838 1348.06 193.475C1341.13 198.895 1337.66 206.267 1337.66 215.59C1337.66 224.045 1340.04 230.116 1344.81 233.802C1349.8 237.271 1356.3 239.006 1364.33 239.006C1376.25 239.006 1386.33 235.645 1394.57 228.924C1402.81 221.986 1406.93 211.795 1406.93 198.353V183.393Z"
        fill={primaryColor}
      />
      <path
        d="M1539.91 266.649H1498.93V19.4819H1539.91V266.649Z"
        fill={primaryColor}
      />
      <path
        d="M1633.28 266.649H1592.3V19.4819H1633.28V266.649Z"
        fill={primaryColor}
      />
      <path
        d="M1756.58 87.1277C1780.21 87.1277 1798.85 94.1742 1812.51 108.267C1826.39 122.36 1833.33 141.982 1833.33 167.132V187.946H1716.9C1717.33 203.99 1721.78 216.457 1730.23 225.346C1738.69 234.019 1750.4 238.355 1765.36 238.355C1776.63 238.355 1786.82 237.271 1795.93 235.103C1805.03 232.718 1814.36 229.357 1823.9 225.021V257.868C1815.22 261.988 1806.23 265.023 1796.9 266.974C1787.58 268.926 1776.41 269.901 1763.41 269.901C1746.28 269.901 1730.99 266.649 1717.55 260.145C1704.32 253.424 1693.92 243.342 1686.33 229.899C1678.96 216.457 1675.27 199.762 1675.27 179.815C1675.27 149.678 1682.75 126.696 1697.71 110.869C1712.67 95.0414 1732.29 87.1277 1756.58 87.1277ZM1756.58 117.373C1745.52 117.373 1736.52 120.951 1729.58 128.105C1722.86 135.044 1718.96 145.451 1717.88 159.327H1793.65C1793.43 147.185 1790.29 137.212 1784.22 129.406C1778.37 121.384 1769.15 117.373 1756.58 117.373Z"
        fill={primaryColor}
      />
      <path
        d="M1841.95 90.3799H1886.51L1921.63 189.897C1924.02 196.185 1925.97 202.364 1927.49 208.435C1929 214.506 1930.09 220.685 1930.74 226.972H1932.04C1932.91 221.552 1934.21 215.59 1935.94 209.085C1937.68 202.581 1939.63 196.185 1941.8 189.897L1975.62 90.3799H2019.85L1944.72 290.065C1938 307.627 1928.79 321.069 1917.08 330.392C1905.59 339.932 1891.39 344.702 1874.48 344.702C1869.06 344.702 1864.39 344.377 1860.49 343.726C1856.59 343.293 1853.12 342.751 1850.08 342.1V309.578C1852.47 310.012 1855.29 310.446 1858.54 310.879C1862.01 311.313 1865.59 311.53 1869.27 311.53C1879.46 311.53 1887.48 308.494 1893.34 302.423C1899.41 296.57 1904.07 289.198 1907.32 280.309L1912.53 265.999L1841.95 90.3799Z"
        fill={primaryColor}
      />
      <path
        d="M208.556 147.562C208.556 186.805 197.606 216.4 175.708 236.347C153.81 256.077 123.239 265.942 83.9962 265.942H18.627V33.735H90.8258C114.675 33.735 135.381 38.0713 152.943 46.7438C170.722 55.4164 184.381 68.2084 193.921 85.1198C203.677 101.814 208.556 122.629 208.556 147.562ZM164.976 148.863C164.976 121.111 158.58 100.73 145.788 87.7216C133.213 74.7128 114.892 68.2084 90.8258 68.2084H60.2551V231.144H85.2971C138.416 231.144 164.976 203.717 164.976 148.863Z"
        fill={primaryColor}
      />
      <path
        d="M325.091 86.4207C348.724 86.4207 367.37 93.4671 381.029 107.56C394.905 121.653 401.843 141.275 401.843 166.425V187.239H285.414C285.848 203.283 290.293 215.75 298.748 224.639C307.204 233.312 318.912 237.648 333.872 237.648C345.146 237.648 355.337 236.564 364.443 234.396C373.549 232.011 382.872 228.65 392.412 224.314V257.161C383.739 261.281 374.741 264.316 365.418 266.267C356.095 268.219 344.93 269.194 331.921 269.194C314.792 269.194 299.507 265.942 286.065 259.438C272.839 252.717 262.432 242.635 254.844 229.192C247.472 215.75 243.786 199.055 243.786 179.108C243.786 148.971 251.266 125.989 266.226 110.162C281.186 94.3344 300.808 86.4207 325.091 86.4207ZM325.091 116.666C314.034 116.666 305.036 120.244 298.098 127.398C291.377 134.336 287.474 144.744 286.39 158.62H362.166C361.949 146.478 358.806 136.505 352.735 128.699C346.881 120.677 337.666 116.666 325.091 116.666Z"
        fill={primaryColor}
      />
      <path
        d="M563.17 214.883C563.17 232.661 556.774 246.212 543.982 255.535C531.407 264.641 513.303 269.194 489.67 269.194C477.312 269.194 466.688 268.327 457.799 266.593C449.126 264.858 440.67 262.148 432.431 258.462V223.664C441.104 227.783 450.644 231.252 461.051 234.071C471.675 236.889 481.648 238.299 490.971 238.299C502.245 238.299 510.376 236.564 515.363 233.095C520.349 229.626 522.843 224.964 522.843 219.111C522.843 215.642 521.867 212.606 519.916 210.004C517.964 207.186 514.062 204.15 508.208 200.898C502.571 197.646 494.115 193.635 482.841 188.865C471.566 184.529 462.135 180.084 454.546 175.531C447.175 170.761 441.538 165.232 437.635 158.945C433.949 152.657 432.106 144.527 432.106 134.553C432.106 118.726 438.285 106.801 450.644 98.7791C463.219 90.5402 479.914 86.4207 500.728 86.4207C511.568 86.4207 521.759 87.5048 531.298 89.6729C541.055 91.841 550.812 95.0932 560.568 99.4295L547.559 129.675C539.321 126.206 531.298 123.279 523.493 120.894C515.688 118.509 507.666 117.317 499.427 117.317C481.431 117.317 472.434 122.412 472.434 132.602C472.434 136.288 473.518 139.432 475.686 142.033C478.071 144.635 482.082 147.454 487.719 150.489C493.573 153.308 501.812 156.777 512.436 160.896C522.843 165.016 531.84 169.352 539.429 173.905C547.017 178.241 552.871 183.662 556.991 190.166C561.11 196.67 563.17 204.909 563.17 214.883Z"
        fill={primaryColor}
      />
      <path
        d="M623.087 21.7019C629.374 21.7019 634.686 23.328 639.023 26.5802C643.576 29.6156 645.852 35.1443 645.852 43.1664C645.852 51.1885 643.576 56.8257 639.023 60.0779C634.686 63.3301 629.374 64.9562 623.087 64.9562C616.799 64.9562 611.379 63.3301 606.826 60.0779C602.489 56.8257 600.321 51.1885 600.321 43.1664C600.321 35.1443 602.489 29.6156 606.826 26.5802C611.379 23.328 616.799 21.7019 623.087 21.7019ZM643.576 89.6729V265.942H602.598V89.6729H643.576Z"
        fill={primaryColor}
      />
      <path
        d="M755.162 86.4207C777.493 86.4207 794.947 95.0932 807.522 112.438H809.148L813.051 89.6729H847.199V267.243C847.199 292.393 840.044 311.473 825.734 324.482C811.642 337.491 790.502 343.995 762.317 343.995C749.741 343.995 738.142 343.128 727.518 341.393C716.894 339.876 706.812 337.165 697.273 333.263V299.44C717.436 308.546 739.985 313.099 764.918 313.099C778.361 313.099 788.551 309.305 795.489 301.716C802.644 294.345 806.221 283.504 806.221 269.194V264.641C806.221 261.389 806.33 257.595 806.547 253.259C806.763 248.922 807.089 245.453 807.522 242.852H806.221C800.15 251.958 792.67 258.679 783.781 263.015C775.109 267.135 765.244 269.194 754.186 269.194C732.721 269.194 715.918 261.172 703.777 245.128C691.635 229.084 685.565 206.752 685.565 178.133C685.565 149.513 691.635 127.073 703.777 110.812C716.135 94.5512 733.264 86.4207 755.162 86.4207ZM765.894 119.918C740.31 119.918 727.518 139.54 727.518 178.783C727.518 217.376 740.527 236.672 766.544 236.672C780.854 236.672 791.37 232.77 798.091 224.964C805.029 216.942 808.498 203.5 808.498 184.637V178.458C808.498 157.644 805.029 142.684 798.091 133.578C791.37 124.471 780.637 119.918 765.894 119.918Z"
        fill={primaryColor}
      />
      <path
        d="M993.866 86.4207C1013.6 86.4207 1028.99 91.5158 1040.05 101.706C1051.32 111.896 1056.96 128.374 1056.96 151.14V265.942H1015.98V159.27C1015.98 132.819 1005.14 119.593 983.459 119.593C966.981 119.593 955.707 124.797 949.636 135.204C943.565 145.611 940.53 160.462 940.53 179.759V265.942H899.552V89.6729H931.098L936.952 112.764H939.229C944.866 103.657 952.563 97.0446 962.319 92.9251C972.293 88.5888 982.808 86.4207 993.866 86.4207Z"
        fill={primaryColor}
      />
      <path
        d="M1287.08 33.735L1206.76 265.942H1162.2L1081.87 33.735H1124.47L1171.31 175.856C1172.61 179.759 1174.12 184.854 1175.86 191.142C1177.59 197.212 1179.22 203.5 1180.74 210.004C1182.47 216.509 1183.77 222.146 1184.64 226.916C1185.29 222.146 1186.37 216.509 1187.89 210.004C1189.41 203.5 1191.04 197.212 1192.77 191.142C1194.51 184.854 1196.13 179.759 1197.65 175.856L1244.48 33.735H1287.08Z"
        fill={primaryColor}
      />
      <path
        d="M1377.28 86.4207C1400.04 86.4207 1417.28 91.4074 1428.99 101.381C1440.91 111.354 1446.87 126.748 1446.87 147.562V265.942H1417.93L1409.8 241.551H1408.5C1400.91 251.091 1392.89 258.137 1384.43 262.69C1375.98 267.026 1364.49 269.194 1349.96 269.194C1334.13 269.194 1321.01 264.75 1310.61 255.86C1300.2 246.971 1295 233.203 1295 214.557C1295 196.345 1301.61 182.686 1314.83 173.58C1328.28 164.474 1348.55 159.378 1375.65 158.294L1406.22 157.319V148.538C1406.22 137.48 1403.51 129.567 1398.09 124.797C1392.67 119.81 1385.08 117.317 1375.33 117.317C1366.44 117.317 1357.87 118.618 1349.63 121.219C1341.61 123.821 1333.59 126.856 1325.57 130.325L1312.56 101.706C1321.23 97.153 1331.1 93.4671 1342.15 90.6486C1353.43 87.83 1365.14 86.4207 1377.28 86.4207ZM1406.22 182.686L1384.76 183.336C1366.76 183.987 1354.3 187.131 1347.36 192.768C1340.42 198.188 1336.95 205.56 1336.95 214.883C1336.95 223.338 1339.33 229.409 1344.1 233.095C1349.09 236.564 1355.6 238.299 1363.62 238.299C1375.54 238.299 1385.62 234.938 1393.86 228.217C1402.1 221.279 1406.22 211.088 1406.22 197.646V182.686Z"
        fill={primaryColor}
      />
      <path
        d="M1539.2 265.942H1498.22V18.7749H1539.2V265.942Z"
        fill={primaryColor}
      />
      <path
        d="M1632.58 265.942H1591.6V18.7749H1632.58V265.942Z"
        fill={primaryColor}
      />
      <path
        d="M1755.87 86.4207C1779.5 86.4207 1798.15 93.4671 1811.81 107.56C1825.68 121.653 1832.62 141.275 1832.62 166.425V187.239H1716.19C1716.63 203.283 1721.07 215.75 1729.53 224.639C1737.98 233.312 1749.69 237.648 1764.65 237.648C1775.92 237.648 1786.11 236.564 1795.22 234.396C1804.33 232.011 1813.65 228.65 1823.19 224.314V257.161C1814.52 261.281 1805.52 264.316 1796.2 266.267C1786.87 268.219 1775.71 269.194 1762.7 269.194C1745.57 269.194 1730.29 265.942 1716.84 259.438C1703.62 252.717 1693.21 242.635 1685.62 229.192C1678.25 215.75 1674.56 199.055 1674.56 179.108C1674.56 148.971 1682.04 125.989 1697 110.162C1711.96 94.3344 1731.59 86.4207 1755.87 86.4207ZM1755.87 116.666C1744.81 116.666 1735.81 120.244 1728.88 127.398C1722.15 134.336 1718.25 144.744 1717.17 158.62H1792.94C1792.73 146.478 1789.58 136.505 1783.51 128.699C1777.66 120.677 1768.44 116.666 1755.87 116.666Z"
        fill={primaryColor}
      />
      <path
        d="M1841.25 89.6729H1885.8L1920.93 189.19C1923.31 195.478 1925.26 201.657 1926.78 207.728C1928.3 213.799 1929.38 219.978 1930.03 226.265H1931.33C1932.2 220.845 1933.5 214.883 1935.24 208.378C1936.97 201.874 1938.92 195.478 1941.09 189.19L1974.91 89.6729H2019.14L1944.02 289.358C1937.3 306.92 1928.08 320.362 1916.37 329.685C1904.88 339.225 1890.68 343.995 1873.77 343.995C1868.35 343.995 1863.69 343.67 1859.78 343.019C1855.88 342.586 1852.41 342.044 1849.38 341.393V308.871C1851.76 309.305 1854.58 309.739 1857.83 310.172C1861.3 310.606 1864.88 310.823 1868.57 310.823C1878.76 310.823 1886.78 307.787 1892.63 301.716C1898.7 295.862 1903.36 288.491 1906.62 279.601L1911.82 265.292L1841.25 89.6729Z"
        fill={primaryColor}
      />
      <path
        d="M207.849 146.855C207.849 186.098 196.899 215.693 175.001 235.64C153.103 255.37 122.532 265.235 83.2892 265.235H17.9199V33.028H90.1188C113.968 33.028 134.674 37.3643 152.236 46.0368C170.015 54.7094 183.674 67.5014 193.214 84.4128C202.97 101.107 207.849 121.922 207.849 146.855ZM164.269 148.156C164.269 120.404 157.873 100.023 145.081 87.0145C132.506 74.0057 114.185 67.5013 90.1188 67.5013H59.5481V230.437H84.5901C137.709 230.437 164.269 203.01 164.269 148.156Z"
        fill={primaryColor}
      />
      <path
        d="M324.384 85.7137C348.017 85.7137 366.663 92.7601 380.322 106.853C394.198 120.946 401.136 140.567 401.136 165.718V186.532H284.707C285.141 202.576 289.585 215.043 298.041 223.932C306.497 232.605 318.205 236.941 333.165 236.941C344.439 236.941 354.629 235.857 363.736 233.689C372.842 231.304 382.165 227.943 391.705 223.607V256.454C383.032 260.574 374.034 263.609 364.711 265.56C355.388 267.512 344.222 268.487 331.214 268.487C314.085 268.487 298.8 265.235 285.358 258.731C272.132 252.01 261.725 241.928 254.136 228.485C246.765 215.043 243.079 198.348 243.079 178.401C243.079 148.264 250.559 125.282 265.519 109.455C280.479 93.6274 300.101 85.7137 324.384 85.7137ZM324.384 115.959C313.327 115.959 304.329 119.537 297.391 126.691C290.67 133.629 286.767 144.036 285.683 157.913H361.459C361.242 145.771 358.099 135.798 352.028 127.992C346.174 119.97 336.959 115.959 324.384 115.959Z"
        fill={primaryColor}
      />
      <path
        d="M562.463 214.176C562.463 231.954 556.067 245.505 543.275 254.828C530.7 263.934 512.596 268.487 488.963 268.487C476.605 268.487 465.981 267.62 457.092 265.886C448.419 264.151 439.963 261.441 431.724 257.755V222.957C440.397 227.076 449.937 230.545 460.344 233.364C470.968 236.182 480.941 237.591 490.264 237.591C501.538 237.591 509.669 235.857 514.656 232.388C519.642 228.919 522.136 224.257 522.136 218.404C522.136 214.934 521.16 211.899 519.209 209.297C517.257 206.479 513.355 203.443 507.501 200.191C501.864 196.939 493.408 192.928 482.134 188.158C470.859 183.822 461.428 179.377 453.839 174.824C446.468 170.054 440.831 164.525 436.928 158.238C433.242 151.95 431.399 143.82 431.399 133.846C431.399 118.019 437.578 106.094 449.937 98.072C462.512 89.8331 479.207 85.7137 500.021 85.7137C510.861 85.7137 521.052 86.7977 530.591 88.9659C540.348 91.134 550.105 94.3862 559.861 98.7225L546.852 128.968C538.614 125.499 530.591 122.572 522.786 120.187C514.981 117.802 506.959 116.61 498.72 116.61C480.724 116.61 471.727 121.705 471.727 131.895C471.727 135.581 472.811 138.725 474.979 141.326C477.364 143.928 481.375 146.747 487.012 149.782C492.866 152.601 501.105 156.07 511.729 160.189C522.136 164.309 531.133 168.645 538.722 173.198C546.31 177.534 552.164 182.954 556.284 189.459C560.403 195.963 562.463 204.202 562.463 214.176Z"
        fill={primaryColor}
      />
      <path
        d="M622.38 20.9949C628.667 20.9949 633.979 22.621 638.315 25.8732C642.869 28.9085 645.145 34.4373 645.145 42.4594C645.145 50.4815 642.869 56.1186 638.315 59.3708C633.979 62.623 628.667 64.2491 622.38 64.2491C616.092 64.2491 610.672 62.623 606.119 59.3708C601.782 56.1186 599.614 50.4815 599.614 42.4594C599.614 34.4373 601.782 28.9085 606.119 25.8732C610.672 22.621 616.092 20.9949 622.38 20.9949ZM642.869 88.9659V265.235H601.891V88.9659H642.869Z"
        fill={primaryColor}
      />
      <path
        d="M754.455 85.7137C776.786 85.7137 794.24 94.3862 806.815 111.731H808.441L812.344 88.9659H846.492V266.536C846.492 291.686 839.337 310.766 825.027 323.775C810.935 336.784 789.795 343.288 761.609 343.288C749.034 343.288 737.435 342.421 726.811 340.686C716.187 339.169 706.105 336.458 696.565 332.556V298.733C716.729 307.839 739.278 312.392 764.211 312.392C777.654 312.392 787.844 308.598 794.782 301.009C801.937 293.638 805.514 282.797 805.514 268.487V263.934C805.514 260.682 805.623 256.888 805.839 252.552C806.056 248.215 806.381 244.746 806.815 242.145H805.514C799.443 251.251 791.963 257.972 783.074 262.308C774.401 266.428 764.536 268.487 753.479 268.487C732.014 268.487 715.211 260.465 703.07 244.421C690.928 228.377 684.858 206.045 684.858 177.426C684.858 148.806 690.928 126.366 703.07 110.105C715.428 93.8442 732.557 85.7137 754.455 85.7137ZM765.187 119.211C739.603 119.211 726.811 138.833 726.811 178.076C726.811 216.669 739.82 235.965 765.837 235.965C780.147 235.965 790.663 232.063 797.384 224.257C804.322 216.235 807.791 202.793 807.791 183.93V177.751C807.791 156.937 804.322 141.977 797.384 132.871C790.663 123.764 779.93 119.211 765.187 119.211Z"
        fill={primaryColor}
      />
      <path
        d="M993.159 85.7137C1012.89 85.7137 1028.28 90.8088 1039.34 100.999C1050.61 111.189 1056.25 127.667 1056.25 150.432V265.235H1015.27V158.563C1015.27 132.112 1004.43 118.886 982.752 118.886C966.274 118.886 955 124.09 948.929 134.497C942.858 144.904 939.823 159.755 939.823 179.052V265.235H898.845V88.9659H930.391L936.245 112.057H938.522C944.159 102.95 951.856 96.3375 961.612 92.2181C971.586 87.8818 982.101 85.7137 993.159 85.7137Z"
        fill={primaryColor}
      />
      <path
        d="M1286.38 33.028L1206.05 265.235H1161.49L1081.16 33.028H1123.77L1170.6 175.149C1171.9 179.052 1173.42 184.147 1175.15 190.435C1176.89 196.505 1178.51 202.793 1180.03 209.297C1181.77 215.802 1183.07 221.439 1183.93 226.209C1184.58 221.439 1185.67 215.802 1187.19 209.297C1188.7 202.793 1190.33 196.505 1192.06 190.435C1193.8 184.147 1195.42 179.052 1196.94 175.149L1243.77 33.028H1286.38Z"
        fill={primaryColor}
      />
      <path
        d="M1376.57 85.7137C1399.34 85.7137 1416.57 90.7004 1428.28 100.674C1440.2 110.647 1446.17 126.041 1446.17 146.855V265.235H1417.22L1409.09 240.844H1407.79C1400.2 250.383 1392.18 257.43 1383.73 261.983C1375.27 266.319 1363.78 268.487 1349.25 268.487C1333.42 268.487 1320.31 264.043 1309.9 255.153C1299.49 246.264 1294.29 232.496 1294.29 213.85C1294.29 195.638 1300.9 181.979 1314.13 172.873C1327.57 163.767 1347.84 158.671 1374.94 157.587L1405.51 156.612V147.831C1405.51 136.773 1402.8 128.86 1397.38 124.09C1391.96 119.103 1384.38 116.61 1374.62 116.61C1365.73 116.61 1357.17 117.91 1348.93 120.512C1340.9 123.114 1332.88 126.149 1324.86 129.618L1311.85 100.999C1320.52 96.4459 1330.39 92.7601 1341.45 89.9415C1352.72 87.123 1364.43 85.7137 1376.57 85.7137ZM1405.51 181.979L1384.05 182.629C1366.05 183.28 1353.59 186.424 1346.65 192.061C1339.71 197.481 1336.24 204.853 1336.24 214.176C1336.24 222.631 1338.63 228.702 1343.4 232.388C1348.38 235.857 1354.89 237.591 1362.91 237.591C1374.84 237.591 1384.92 234.231 1393.16 227.51C1401.4 220.572 1405.51 210.381 1405.51 196.939V181.979Z"
        fill={primaryColor}
      />
      <path
        d="M1538.49 265.235H1497.52V18.0679H1538.49V265.235Z"
        fill={primaryColor}
      />
      <path
        d="M1631.87 265.235H1590.89V18.0679H1631.87V265.235Z"
        fill={primaryColor}
      />
      <path
        d="M1755.16 85.7137C1778.79 85.7137 1797.44 92.7601 1811.1 106.853C1824.98 120.946 1831.91 140.567 1831.91 165.718V186.532H1715.49C1715.92 202.576 1720.36 215.043 1728.82 223.932C1737.27 232.605 1748.98 236.941 1763.94 236.941C1775.22 236.941 1785.41 235.857 1794.51 233.689C1803.62 231.304 1812.94 227.943 1822.48 223.607V256.454C1813.81 260.574 1804.81 263.609 1795.49 265.56C1786.17 267.512 1775 268.487 1761.99 268.487C1744.86 268.487 1729.58 265.235 1716.14 258.731C1702.91 252.01 1692.5 241.928 1684.91 228.485C1677.54 215.043 1673.86 198.348 1673.86 178.401C1673.86 148.264 1681.34 125.282 1696.3 109.455C1711.26 93.6274 1730.88 85.7137 1755.16 85.7137ZM1755.16 115.959C1744.1 115.959 1735.11 119.537 1728.17 126.691C1721.45 133.629 1717.55 144.036 1716.46 157.913H1792.24C1792.02 145.771 1788.88 135.798 1782.81 127.992C1776.95 119.97 1767.74 115.959 1755.16 115.959Z"
        fill={primaryColor}
      />
      <path
        d="M1840.54 88.9659H1885.1L1920.22 188.483C1922.6 194.771 1924.56 200.95 1926.07 207.021C1927.59 213.092 1928.67 219.271 1929.33 225.558H1930.63C1931.49 220.138 1932.79 214.176 1934.53 207.671C1936.26 201.167 1938.21 194.771 1940.38 188.483L1974.21 88.9659H2018.44L1943.31 288.651C1936.59 306.213 1927.37 319.655 1915.67 328.978C1904.17 338.518 1889.97 343.288 1873.06 343.288C1867.64 343.288 1862.98 342.963 1859.08 342.312C1855.18 341.879 1851.71 341.337 1848.67 340.686V308.164C1851.06 308.598 1853.87 309.032 1857.13 309.465C1860.6 309.899 1864.17 310.116 1867.86 310.116C1878.05 310.116 1886.07 307.08 1891.92 301.009C1898 295.155 1902.66 287.784 1905.91 278.894L1911.11 264.585L1840.54 88.9659Z"
        fill={primaryColor}
      />
      <path
        d="M207.141 146.148C207.141 185.391 196.192 214.986 174.294 234.933C152.396 254.663 121.825 264.528 82.5821 264.528H17.2129V32.321H89.4118C113.261 32.321 133.967 36.6572 151.529 45.3298C169.308 54.0023 182.967 66.7943 192.507 83.7058C202.263 100.4 207.141 121.214 207.141 146.148ZM163.562 147.449C163.562 119.697 157.166 99.3163 144.374 86.3075C131.799 73.2987 113.478 66.7943 89.4118 66.7943H58.8411V229.73H83.883C137.002 229.73 163.562 202.303 163.562 147.449Z"
        fill={primaryColor}
      />
      <path
        d="M323.677 85.0066C347.31 85.0066 365.956 92.0531 379.615 106.146C393.491 120.239 400.429 139.86 400.429 165.011V185.825H284C284.434 201.869 288.878 214.336 297.334 223.225C305.79 231.898 317.498 236.234 332.458 236.234C343.732 236.234 353.922 235.15 363.029 232.982C372.135 230.597 381.458 227.236 390.998 222.9V255.747C382.325 259.867 373.327 262.902 364.004 264.853C354.681 266.805 343.515 267.78 330.507 267.78C313.378 267.78 298.093 264.528 284.651 258.024C271.425 251.303 261.018 241.221 253.429 227.778C246.058 214.336 242.372 197.641 242.372 177.694C242.372 147.557 249.852 124.575 264.812 108.748C279.772 92.9203 299.394 85.0066 323.677 85.0066ZM323.677 115.252C312.62 115.252 303.622 118.83 296.684 125.984C289.962 132.922 286.06 143.329 284.976 157.206H360.752C360.535 145.064 357.391 135.091 351.321 127.285C345.467 119.263 336.252 115.252 323.677 115.252Z"
        fill={primaryColor}
      />
      <path
        d="M561.756 213.469C561.756 231.247 555.36 244.798 542.568 254.121C529.993 263.227 511.889 267.78 488.256 267.78C475.898 267.78 465.274 266.913 456.385 265.179C447.712 263.444 439.256 260.734 431.017 257.048V222.25C439.69 226.369 449.23 229.838 459.637 232.657C470.261 235.475 480.234 236.884 489.557 236.884C500.831 236.884 508.962 235.15 513.949 231.681C518.935 228.212 521.429 223.55 521.429 217.696C521.429 214.227 520.453 211.192 518.502 208.59C516.55 205.772 512.648 202.736 506.794 199.484C501.157 196.232 492.701 192.221 481.427 187.451C470.152 183.115 460.721 178.67 453.132 174.117C445.761 169.347 440.124 163.818 436.221 157.531C432.535 151.243 430.692 143.113 430.692 133.139C430.692 117.312 436.871 105.387 449.23 97.365C461.805 89.1261 478.5 85.0066 499.314 85.0066C510.154 85.0066 520.345 86.0907 529.884 88.2588C539.641 90.427 549.398 93.6792 559.154 98.0154L546.145 128.261C537.906 124.792 529.884 121.865 522.079 119.48C514.274 117.095 506.252 115.903 498.013 115.903C480.017 115.903 471.02 120.998 471.02 131.188C471.02 134.874 472.104 138.018 474.272 140.619C476.657 143.221 480.668 146.04 486.305 149.075C492.159 151.894 500.398 155.363 511.022 159.482C521.429 163.602 530.426 167.938 538.015 172.491C545.603 176.827 551.457 182.247 555.577 188.752C559.696 195.256 561.756 203.495 561.756 213.469Z"
        fill={primaryColor}
      />
      <path
        d="M621.673 20.2878C627.96 20.2878 633.272 21.9139 637.608 25.1661C642.162 28.2015 644.438 33.7303 644.438 41.7523C644.438 49.7745 642.162 55.4116 637.608 58.6638C633.272 61.916 627.96 63.5421 621.673 63.5421C615.385 63.5421 609.965 61.916 605.412 58.6638C601.075 55.4116 598.907 49.7745 598.907 41.7523C598.907 33.7303 601.075 28.2015 605.412 25.1661C609.965 21.9139 615.385 20.2878 621.673 20.2878ZM642.161 88.2588V264.528H601.184V88.2588H642.161Z"
        fill={primaryColor}
      />
      <path
        d="M753.748 85.0066C776.079 85.0066 793.533 93.6792 806.108 111.024H807.734L811.637 88.2588H845.785V265.829C845.785 290.979 838.63 310.059 824.32 323.068C810.227 336.077 789.088 342.581 760.902 342.581C748.327 342.581 736.728 341.714 726.104 339.979C715.48 338.462 705.398 335.751 695.858 331.849V298.026C716.022 307.132 738.571 311.685 763.504 311.685C776.947 311.685 787.137 307.891 794.075 300.302C801.23 292.931 804.807 282.09 804.807 267.78V263.227C804.807 259.975 804.916 256.181 805.132 251.845C805.349 247.508 805.674 244.039 806.108 241.438H804.807C798.736 250.544 791.256 257.265 782.367 261.601C773.694 265.721 763.829 267.78 752.772 267.78C731.307 267.78 714.504 259.758 702.363 243.714C690.221 227.67 684.151 205.338 684.151 176.719C684.151 148.099 690.221 125.659 702.363 109.398C714.721 93.1371 731.849 85.0066 753.748 85.0066ZM764.48 118.504C738.896 118.504 726.104 138.126 726.104 177.369C726.104 215.962 739.113 235.258 765.13 235.258C779.44 235.258 789.956 231.356 796.677 223.55C803.615 215.528 807.084 202.086 807.084 183.223V177.044C807.084 156.23 803.615 141.27 796.677 132.164C789.956 123.057 779.223 118.504 764.48 118.504Z"
        fill={primaryColor}
      />
      <path
        d="M992.452 85.0066C1012.18 85.0066 1027.58 90.1018 1038.63 100.292C1049.91 110.482 1055.54 126.96 1055.54 149.725V264.528H1014.57V157.856C1014.57 131.405 1003.73 118.179 982.045 118.179C965.567 118.179 954.292 123.383 948.222 133.79C942.151 144.197 939.115 159.048 939.115 178.345V264.528H898.138V88.2588H929.684L935.538 111.349H937.815C943.452 102.243 951.149 95.6305 960.905 91.5111C970.879 87.1748 981.394 85.0066 992.452 85.0066Z"
        fill={primaryColor}
      />
      <path
        d="M1285.67 32.321L1205.34 264.528H1160.79L1080.46 32.321H1123.06L1169.89 174.442C1171.19 178.345 1172.71 183.44 1174.45 189.728C1176.18 195.798 1177.81 202.086 1179.32 208.59C1181.06 215.095 1182.36 220.732 1183.23 225.502C1183.88 220.732 1184.96 215.095 1186.48 208.59C1188 202.086 1189.62 195.798 1191.36 189.728C1193.09 183.44 1194.72 178.345 1196.23 174.442L1243.07 32.321H1285.67Z"
        fill={primaryColor}
      />
      <path
        d="M1375.86 85.0066C1398.63 85.0066 1415.87 89.9933 1427.57 99.9668C1439.5 109.94 1445.46 125.334 1445.46 146.148V264.528H1416.52L1408.39 240.137H1407.08C1399.5 249.676 1391.47 256.723 1383.02 261.276C1374.56 265.612 1363.07 267.78 1348.54 267.78C1332.72 267.78 1319.6 263.336 1309.19 254.446C1298.79 245.557 1293.58 231.789 1293.58 213.143C1293.58 194.931 1300.2 181.272 1313.42 172.166C1326.86 163.059 1347.14 157.964 1374.24 156.88L1404.81 155.905V147.124C1404.81 136.066 1402.1 128.153 1396.68 123.383C1391.26 118.396 1383.67 115.903 1373.91 115.903C1365.02 115.903 1356.46 117.203 1348.22 119.805C1340.2 122.407 1332.18 125.442 1324.15 128.911L1311.14 100.292C1319.82 95.7389 1329.68 92.0531 1340.74 89.2345C1352.01 86.4159 1363.72 85.0066 1375.86 85.0066ZM1404.81 181.272L1383.34 181.922C1365.35 182.573 1352.88 185.716 1345.94 191.354C1339.01 196.774 1335.54 204.146 1335.54 213.469C1335.54 221.924 1337.92 227.995 1342.69 231.681C1347.68 235.15 1354.18 236.884 1362.2 236.884C1374.13 236.884 1384.21 233.524 1392.45 226.803C1400.69 219.865 1404.81 209.674 1404.81 196.232V181.272Z"
        fill={primaryColor}
      />
      <path
        d="M1537.79 264.528H1496.81V17.3608H1537.79V264.528Z"
        fill={primaryColor}
      />
      <path
        d="M1631.16 264.528H1590.18V17.3608H1631.16V264.528Z"
        fill={primaryColor}
      />
      <path
        d="M1754.46 85.0066C1778.09 85.0066 1796.73 92.0531 1810.39 106.146C1824.27 120.239 1831.21 139.86 1831.21 165.011V185.825H1714.78C1715.21 201.869 1719.66 214.336 1728.11 223.225C1736.57 231.898 1748.28 236.234 1763.24 236.234C1774.51 236.234 1784.7 235.15 1793.81 232.982C1802.91 230.597 1812.24 227.236 1821.78 222.9V255.747C1813.1 259.867 1804.11 262.902 1794.78 264.853C1785.46 266.805 1774.29 267.78 1761.28 267.78C1744.16 267.78 1728.87 264.528 1715.43 258.024C1702.2 251.303 1691.8 241.221 1684.21 227.778C1676.84 214.336 1673.15 197.641 1673.15 177.694C1673.15 147.557 1680.63 124.575 1695.59 108.748C1710.55 92.9203 1730.17 85.0066 1754.46 85.0066ZM1754.46 115.252C1743.4 115.252 1734.4 118.83 1727.46 125.984C1720.74 132.922 1716.84 143.329 1715.75 157.206H1791.53C1791.31 145.064 1788.17 135.091 1782.1 127.285C1776.24 119.263 1767.03 115.252 1754.46 115.252Z"
        fill={primaryColor}
      />
      <path
        d="M1839.83 88.2588H1884.39L1919.51 187.776C1921.9 194.064 1923.85 200.243 1925.37 206.314C1926.88 212.385 1927.97 218.564 1928.62 224.851H1929.92C1930.79 219.431 1932.09 213.469 1933.82 206.964C1935.56 200.46 1937.51 194.064 1939.68 187.776L1973.5 88.2588H2017.73L1942.6 287.944C1935.88 305.506 1926.67 318.948 1914.96 328.271C1903.47 337.811 1889.27 342.581 1872.35 342.581C1866.93 342.581 1862.27 342.256 1858.37 341.605C1854.47 341.172 1851 340.63 1847.96 339.979V307.457C1850.35 307.891 1853.17 308.324 1856.42 308.758C1859.89 309.192 1863.47 309.409 1867.15 309.409C1877.34 309.409 1885.36 306.373 1891.22 300.302C1897.29 294.448 1901.95 287.077 1905.2 278.187L1910.41 263.878L1839.83 88.2588Z"
        fill={primaryColor}
      />
      <path
        d="M206.434 145.441C206.434 184.684 195.485 214.279 173.587 234.226C151.689 253.956 121.118 263.821 81.8751 263.821H16.5059V31.6139H88.7047C112.554 31.6139 133.26 35.9502 150.822 44.6227C168.6 53.2953 182.26 66.0873 191.8 82.9987C201.556 99.6934 206.434 120.507 206.434 145.441ZM162.855 146.742C162.855 118.99 156.459 98.6093 143.667 85.6005C131.092 72.5917 112.771 66.0873 88.7047 66.0873H58.134V229.023H83.176C136.295 229.023 162.855 201.596 162.855 146.742Z"
        fill={primaryColor}
      />
      <path
        d="M322.97 84.2996C346.603 84.2996 365.249 91.346 378.908 105.439C392.784 119.532 399.722 139.153 399.722 164.304V185.118H283.293C283.727 201.162 288.171 213.629 296.627 222.518C305.083 231.191 316.791 235.527 331.751 235.527C343.025 235.527 353.215 234.443 362.322 232.275C371.428 229.89 380.751 226.529 390.291 222.193V255.04C381.618 259.16 372.62 262.195 363.297 264.146C353.974 266.098 342.808 267.073 329.8 267.073C312.671 267.073 297.386 263.821 283.944 257.317C270.718 250.596 260.311 240.514 252.722 227.071C245.351 213.629 241.665 196.934 241.665 176.987C241.665 146.85 249.145 123.868 264.105 108.041C279.065 92.2133 298.687 84.2996 322.97 84.2996ZM322.97 114.545C311.912 114.545 302.915 118.123 295.977 125.277C289.255 132.215 285.353 142.622 284.269 156.498H360.045C359.828 144.357 356.684 134.384 350.614 126.578C344.76 118.556 335.545 114.545 322.97 114.545Z"
        fill={primaryColor}
      />
      <path
        d="M561.049 212.762C561.049 230.54 554.653 244.091 541.861 253.414C529.286 262.52 511.182 267.073 487.549 267.073C475.191 267.073 464.567 266.206 455.678 264.472C447.005 262.737 438.549 260.027 430.31 256.341V221.543C438.983 225.662 448.523 229.131 458.93 231.95C469.554 234.768 479.527 236.177 488.85 236.177C500.124 236.177 508.255 234.443 513.242 230.974C518.228 227.505 520.722 222.843 520.722 216.989C520.722 213.52 519.746 210.485 517.795 207.883C515.843 205.065 511.941 202.029 506.087 198.777C500.45 195.525 491.994 191.514 480.72 186.744C469.445 182.408 460.014 177.963 452.425 173.41C445.054 168.64 439.417 163.111 435.514 156.824C431.828 150.536 429.985 142.406 429.985 132.432C429.985 116.605 436.164 104.68 448.523 96.658C461.098 88.4191 477.793 84.2996 498.607 84.2996C509.447 84.2996 519.638 85.3837 529.177 87.5518C538.934 89.7199 548.691 92.9722 558.447 97.3084L545.438 127.554C537.199 124.085 529.177 121.158 521.372 118.773C513.567 116.388 505.545 115.196 497.306 115.196C479.31 115.196 470.312 120.291 470.312 130.481C470.312 134.167 471.397 137.31 473.565 139.912C475.95 142.514 479.961 145.333 485.598 148.368C491.452 151.187 499.691 154.656 510.315 158.775C520.722 162.894 529.719 167.231 537.308 171.784C544.896 176.12 550.75 181.54 554.87 188.045C558.989 194.549 561.049 202.788 561.049 212.762Z"
        fill={primaryColor}
      />
      <path
        d="M620.966 19.5808C627.253 19.5808 632.565 21.2069 636.901 24.4591C641.455 27.4945 643.731 33.0232 643.731 41.0453C643.731 49.0674 641.455 54.7046 636.901 57.9568C632.565 61.209 627.253 62.8351 620.966 62.8351C614.678 62.8351 609.258 61.209 604.705 57.9568C600.368 54.7046 598.2 49.0674 598.2 41.0453C598.2 33.0232 600.368 27.4945 604.705 24.4591C609.258 21.2069 614.678 19.5808 620.966 19.5808ZM641.454 87.5518V263.821H600.477V87.5518H641.454Z"
        fill={primaryColor}
      />
      <path
        d="M753.041 84.2996C775.372 84.2996 792.826 92.9721 805.401 110.317H807.027L810.93 87.5518H845.078V265.122C845.078 290.272 837.923 309.352 823.613 322.361C809.52 335.37 788.381 341.874 760.195 341.874C747.62 341.874 736.021 341.007 725.397 339.272C714.773 337.755 704.691 335.044 695.151 331.142V297.319C715.315 306.425 737.864 310.978 762.797 310.978C776.24 310.978 786.43 307.184 793.368 299.595C800.523 292.224 804.1 281.383 804.1 267.073V262.52C804.1 259.268 804.209 255.474 804.425 251.138C804.642 246.801 804.967 243.332 805.401 240.73H804.1C798.029 249.837 790.549 256.558 781.66 260.894C772.987 265.014 763.122 267.073 752.065 267.073C730.6 267.073 713.797 259.051 701.656 243.007C689.514 226.963 683.443 204.631 683.443 176.012C683.443 147.392 689.514 124.952 701.656 108.691C714.014 92.4301 731.142 84.2996 753.041 84.2996ZM763.773 117.797C738.189 117.797 725.397 137.419 725.397 176.662C725.397 215.255 738.406 234.551 764.423 234.551C778.733 234.551 789.248 230.649 795.97 222.843C802.908 214.821 806.377 201.379 806.377 182.516V176.337C806.377 155.523 802.908 140.563 795.97 131.457C789.248 122.35 778.516 117.797 763.773 117.797Z"
        fill={primaryColor}
      />
      <path
        d="M991.745 84.2996C1011.47 84.2996 1026.87 89.3947 1037.93 99.585C1049.2 109.775 1054.84 126.253 1054.84 149.018V263.821H1013.86V157.149C1013.86 130.698 1003.02 117.472 981.338 117.472C964.86 117.472 953.585 122.676 947.515 133.083C941.444 143.49 938.408 158.341 938.408 177.638V263.821H897.431V87.5518H928.977L934.831 110.642H937.108C942.745 101.536 950.442 94.9235 960.198 90.804C970.172 86.4677 980.687 84.2996 991.745 84.2996Z"
        fill={primaryColor}
      />
      <path
        d="M1284.96 31.6139L1204.63 263.821H1160.08L1079.75 31.6139H1122.35L1169.19 173.735C1170.49 177.638 1172 182.733 1173.74 189.021C1175.47 195.091 1177.1 201.379 1178.62 207.883C1180.35 214.388 1181.65 220.025 1182.52 224.795C1183.17 220.025 1184.25 214.388 1185.77 207.883C1187.29 201.379 1188.92 195.091 1190.65 189.021C1192.38 182.733 1194.01 177.638 1195.53 173.735L1242.36 31.6139H1284.96Z"
        fill={primaryColor}
      />
      <path
        d="M1375.16 84.2996C1397.92 84.2996 1415.16 89.2863 1426.87 99.2597C1438.79 109.233 1444.75 124.627 1444.75 145.441V263.821H1415.81L1407.68 239.43H1406.38C1398.79 248.969 1390.77 256.016 1382.31 260.569C1373.86 264.905 1362.36 267.073 1347.84 267.073C1332.01 267.073 1318.89 262.629 1308.49 253.739C1298.08 244.85 1292.88 231.082 1292.88 212.436C1292.88 194.224 1299.49 180.565 1312.71 171.459C1326.16 162.352 1346.43 157.257 1373.53 156.173L1404.1 155.198V146.417C1404.1 135.359 1401.39 127.445 1395.97 122.676C1390.55 117.689 1382.96 115.196 1373.2 115.196C1364.32 115.196 1355.75 116.496 1347.51 119.098C1339.49 121.7 1331.47 124.735 1323.45 128.204L1310.44 99.585C1319.11 95.0319 1328.97 91.3461 1340.03 88.5275C1351.31 85.7089 1363.01 84.2996 1375.16 84.2996ZM1404.1 180.565L1382.64 181.215C1364.64 181.866 1352.17 185.009 1345.24 190.647C1338.3 196.067 1334.83 203.439 1334.83 212.762C1334.83 221.217 1337.21 227.288 1341.98 230.974C1346.97 234.443 1353.47 236.177 1361.5 236.177C1373.42 236.177 1383.5 232.817 1391.74 226.096C1399.98 219.158 1404.1 208.967 1404.1 195.525V180.565Z"
        fill={primaryColor}
      />
      <path
        d="M1537.08 263.821H1496.1V16.6538H1537.08V263.821Z"
        fill={primaryColor}
      />
      <path
        d="M1630.45 263.821H1589.48V16.6538H1630.45V263.821Z"
        fill={primaryColor}
      />
      <path
        d="M1753.75 84.2996C1777.38 84.2996 1796.03 91.346 1809.69 105.439C1823.56 119.532 1830.5 139.153 1830.5 164.304V185.118H1714.07C1714.5 201.162 1718.95 213.629 1727.41 222.518C1735.86 231.191 1747.57 235.527 1762.53 235.527C1773.8 235.527 1783.99 234.443 1793.1 232.275C1802.21 229.89 1811.53 226.529 1821.07 222.193V255.04C1812.4 259.16 1803.4 262.195 1794.08 264.146C1784.75 266.098 1773.59 267.073 1760.58 267.073C1743.45 267.073 1728.16 263.821 1714.72 257.317C1701.5 250.596 1691.09 240.514 1683.5 227.071C1676.13 213.629 1672.44 196.934 1672.44 176.987C1672.44 146.85 1679.92 123.868 1694.88 108.041C1709.84 92.2133 1729.46 84.2996 1753.75 84.2996ZM1753.75 114.545C1742.69 114.545 1733.69 118.123 1726.75 125.277C1720.03 132.215 1716.13 142.622 1715.05 156.498H1790.82C1790.61 144.357 1787.46 134.384 1781.39 126.578C1775.54 118.556 1766.32 114.545 1753.75 114.545Z"
        fill={primaryColor}
      />
      <path
        d="M1839.13 87.5518H1883.68L1918.8 187.069C1921.19 193.357 1923.14 199.536 1924.66 205.607C1926.18 211.677 1927.26 217.857 1927.91 224.144H1929.21C1930.08 218.724 1931.38 212.762 1933.11 206.257C1934.85 199.753 1936.8 193.357 1938.97 187.069L1972.79 87.5518H2017.02L1941.9 287.237C1935.17 304.799 1925.96 318.241 1914.25 327.564C1902.76 337.104 1888.56 341.874 1871.65 341.874C1866.23 341.874 1861.57 341.549 1857.66 340.898C1853.76 340.465 1850.29 339.923 1847.26 339.272V306.75C1849.64 307.184 1852.46 307.617 1855.71 308.051C1859.18 308.485 1862.76 308.702 1866.44 308.702C1876.63 308.702 1884.66 305.666 1890.51 299.595C1896.58 293.741 1901.24 286.37 1904.5 277.48L1909.7 263.171L1839.13 87.5518Z"
        fill={primaryColor}
      />
      <path
        d="M205.727 144.733C205.727 183.977 194.778 213.572 172.88 233.519C150.982 253.249 120.411 263.114 81.1676 263.114H15.7983V30.9064H87.9972C111.847 30.9064 132.552 35.2427 150.114 43.9152C167.893 52.5878 181.552 65.3798 191.092 82.2912C200.849 98.9858 205.727 119.8 205.727 144.733ZM162.147 146.034C162.147 118.282 155.751 97.9018 142.959 84.893C130.384 71.8842 112.064 65.3798 87.9972 65.3798H57.4265V228.315H82.4685C135.588 228.315 162.147 200.888 162.147 146.034Z"
        fill={primaryColor}
      />
      <path
        d="M322.262 83.5921C345.895 83.5921 364.541 90.6385 378.2 104.731C392.076 118.824 399.014 138.446 399.014 163.596V184.41H282.586C283.019 200.455 287.464 212.921 295.92 221.811C304.375 230.483 316.083 234.819 331.043 234.819C342.318 234.819 352.508 233.735 361.614 231.567C370.72 229.182 380.043 225.822 389.583 221.485V254.333C380.91 258.452 371.913 261.488 362.59 263.439C353.267 265.39 342.101 266.366 329.092 266.366C311.964 266.366 296.678 263.114 283.236 256.609C270.01 249.888 259.603 239.806 252.015 226.364C244.643 212.921 240.957 196.227 240.957 176.28C240.957 146.143 248.437 123.161 263.398 107.333C278.358 91.5058 297.979 83.5921 322.262 83.5921ZM322.262 113.838C311.205 113.838 302.207 117.415 295.269 124.57C288.548 131.508 284.645 141.915 283.561 155.791H359.338C359.121 143.649 355.977 133.676 349.906 125.871C344.052 117.849 334.838 113.838 322.262 113.838Z"
        fill={primaryColor}
      />
      <path
        d="M560.341 212.054C560.341 229.833 553.945 243.384 541.153 252.707C528.578 261.813 510.474 266.366 486.842 266.366C474.483 266.366 463.859 265.499 454.97 263.764C446.298 262.03 437.842 259.319 429.603 255.634V220.835C438.275 224.954 447.815 228.423 458.222 231.242C468.846 234.061 478.82 235.47 488.143 235.47C499.417 235.47 507.547 233.735 512.534 230.266C517.521 226.797 520.014 222.136 520.014 216.282C520.014 212.813 519.038 209.778 517.087 207.176C515.136 204.357 511.233 201.322 505.379 198.07C499.742 194.817 491.286 190.806 480.012 186.036C468.738 181.7 459.306 177.255 451.718 172.702C444.346 167.932 438.709 162.404 434.806 156.116C431.121 149.829 429.278 141.698 429.278 131.725C429.278 115.897 435.457 103.973 447.815 95.9505C460.39 87.7115 477.085 83.5921 497.899 83.5921C508.74 83.5921 518.93 84.6761 528.47 86.8443C538.226 89.0124 547.983 92.2646 557.74 96.6009L544.731 126.846C536.492 123.377 528.47 120.45 520.665 118.065C512.859 115.68 504.837 114.488 496.598 114.488C478.603 114.488 469.605 119.583 469.605 129.773C469.605 133.459 470.689 136.603 472.857 139.205C475.242 141.806 479.253 144.625 484.89 147.66C490.744 150.479 498.983 153.948 509.607 158.068C520.014 162.187 529.012 166.523 536.6 171.076C544.189 175.413 550.043 180.833 554.162 187.337C558.282 193.842 560.341 202.081 560.341 212.054Z"
        fill={primaryColor}
      />
      <path
        d="M620.258 18.8733C626.546 18.8733 631.858 20.4994 636.194 23.7516C640.747 26.787 643.023 32.3157 643.023 40.3378C643.023 48.3599 640.747 53.9971 636.194 57.2493C631.858 60.5015 626.546 62.1276 620.258 62.1276C613.971 62.1276 608.55 60.5015 603.997 57.2493C599.661 53.9971 597.493 48.3599 597.493 40.3378C597.493 32.3157 599.661 26.787 603.997 23.7516C608.55 20.4994 613.971 18.8733 620.258 18.8733ZM640.747 86.8443V263.114H599.769V86.8443H640.747Z"
        fill={primaryColor}
      />
      <path
        d="M752.333 83.5921C774.665 83.5921 792.118 92.2646 804.694 109.61H806.32L810.222 86.8443H844.37V264.414C844.37 289.565 837.216 308.644 822.906 321.653C808.813 334.662 787.674 341.166 759.488 341.166C746.913 341.166 735.313 340.299 724.689 338.565C714.065 337.047 703.984 334.337 694.444 330.434V296.611C714.608 305.717 737.156 310.271 762.09 310.271C775.532 310.271 785.722 306.476 792.66 298.888C799.815 291.516 803.393 280.676 803.393 266.366V261.813C803.393 258.561 803.501 254.766 803.718 250.43C803.935 246.094 804.26 242.625 804.694 240.023H803.393C797.322 249.129 789.842 255.85 780.952 260.187C772.28 264.306 762.415 266.366 751.357 266.366C729.893 266.366 713.09 258.344 700.948 242.3C688.807 226.255 682.736 203.924 682.736 175.304C682.736 146.685 688.807 124.245 700.948 107.984C713.307 91.7226 730.435 83.5921 752.333 83.5921ZM763.065 117.09C737.481 117.09 724.689 136.711 724.689 175.955C724.689 214.547 737.698 233.844 763.716 233.844C778.025 233.844 788.541 229.941 795.262 222.136C802.2 214.114 805.669 200.671 805.669 181.809V175.629C805.669 154.815 802.2 139.855 795.262 130.749C788.541 121.643 777.809 117.09 763.065 117.09Z"
        fill={primaryColor}
      />
      <path
        d="M991.037 83.5921C1010.77 83.5921 1026.16 88.6872 1037.22 98.8774C1048.49 109.068 1054.13 125.546 1054.13 148.311V263.114H1013.15V156.441C1013.15 129.99 1002.31 116.765 980.63 116.765C964.152 116.765 952.878 121.968 946.807 132.375C940.736 142.782 937.701 157.634 937.701 176.93V263.114H896.723V86.8443H928.27L934.124 109.935H936.4C942.037 100.829 949.734 94.216 959.491 90.0965C969.464 85.7602 979.98 83.5921 991.037 83.5921Z"
        fill={primaryColor}
      />
      <path
        d="M1284.26 30.9064L1203.93 263.114H1159.37L1079.04 30.9064H1121.65L1168.48 173.028C1169.78 176.93 1171.3 182.025 1173.03 188.313C1174.77 194.384 1176.39 200.671 1177.91 207.176C1179.64 213.68 1180.94 219.317 1181.81 224.087C1182.46 219.317 1183.55 213.68 1185.06 207.176C1186.58 200.671 1188.21 194.384 1189.94 188.313C1191.68 182.025 1193.3 176.93 1194.82 173.028L1241.65 30.9064H1284.26Z"
        fill={primaryColor}
      />
      <path
        d="M1374.45 83.5921C1397.21 83.5921 1414.45 88.5788 1426.16 98.5522C1438.08 108.526 1444.05 123.919 1444.05 144.733V263.114H1415.1L1406.97 238.722H1405.67C1398.08 248.262 1390.06 255.308 1381.6 259.861C1373.15 264.198 1361.66 266.366 1347.13 266.366C1331.3 266.366 1318.19 261.921 1307.78 253.032C1297.37 244.142 1292.17 230.375 1292.17 211.729C1292.17 193.516 1298.78 179.857 1312.01 170.751C1325.45 161.645 1345.72 156.55 1372.82 155.466L1403.39 154.49V145.709C1403.39 134.652 1400.68 126.738 1395.26 121.968C1389.84 116.981 1382.25 114.488 1372.5 114.488C1363.61 114.488 1355.04 115.789 1346.8 118.391C1338.78 120.992 1330.76 124.028 1322.74 127.497L1309.73 98.8774C1318.4 94.3244 1328.27 90.6385 1339.32 87.82C1350.6 85.0014 1362.31 83.5921 1374.45 83.5921ZM1403.39 179.857L1381.93 180.508C1363.93 181.158 1351.47 184.302 1344.53 189.939C1337.59 195.359 1334.12 202.731 1334.12 212.054C1334.12 220.51 1336.51 226.581 1341.28 230.266C1346.26 233.735 1352.77 235.47 1360.79 235.47C1372.71 235.47 1382.8 232.109 1391.03 225.388C1399.27 218.45 1403.39 208.26 1403.39 194.817V179.857Z"
        fill={primaryColor}
      />
      <path
        d="M1536.37 263.114H1495.4V15.9463H1536.37V263.114Z"
        fill={primaryColor}
      />
      <path
        d="M1629.75 263.114H1588.77V15.9463H1629.75V263.114Z"
        fill={primaryColor}
      />
      <path
        d="M1753.04 83.5921C1776.67 83.5921 1795.32 90.6385 1808.98 104.731C1822.85 118.824 1829.79 138.446 1829.79 163.596V184.41H1713.36C1713.8 200.455 1718.24 212.921 1726.7 221.811C1735.15 230.483 1746.86 234.819 1761.82 234.819C1773.1 234.819 1783.29 233.735 1792.39 231.567C1801.5 229.182 1810.82 225.822 1820.36 221.485V254.333C1811.69 258.452 1802.69 261.488 1793.37 263.439C1784.04 265.39 1772.88 266.366 1759.87 266.366C1742.74 266.366 1727.46 263.114 1714.01 256.609C1700.79 249.888 1690.38 239.806 1682.79 226.364C1675.42 212.921 1671.74 196.227 1671.74 176.28C1671.74 146.143 1679.22 123.161 1694.18 107.333C1709.14 91.5058 1728.76 83.5921 1753.04 83.5921ZM1753.04 113.838C1741.98 113.838 1732.99 117.415 1726.05 124.57C1719.33 131.508 1715.42 141.915 1714.34 155.791H1790.12C1789.9 143.649 1786.76 133.676 1780.68 125.871C1774.83 117.849 1765.62 113.838 1753.04 113.838Z"
        fill={primaryColor}
      />
      <path
        d="M1838.42 86.8443H1882.97L1918.1 186.362C1920.48 192.649 1922.43 198.828 1923.95 204.899C1925.47 210.97 1926.55 217.149 1927.2 223.437H1928.5C1929.37 218.016 1930.67 212.054 1932.41 205.55C1934.14 199.045 1936.09 192.649 1938.26 186.362L1972.08 86.8443H2016.31L1941.19 286.529C1934.47 304.091 1925.25 317.534 1913.54 326.857C1902.05 336.397 1887.85 341.166 1870.94 341.166C1865.52 341.166 1860.86 340.841 1856.96 340.191C1853.05 339.757 1849.58 339.215 1846.55 338.565V306.043C1848.93 306.476 1851.75 306.91 1855 307.344C1858.47 307.777 1862.05 307.994 1865.74 307.994C1875.93 307.994 1883.95 304.959 1889.8 298.888C1895.87 293.034 1900.54 285.662 1903.79 276.773L1908.99 262.463L1838.42 86.8443Z"
        fill={primaryColor}
      />
      <path
        d="M205.02 144.026C205.02 183.27 194.071 212.865 172.173 232.812C150.274 252.542 119.704 262.407 80.4606 262.407H15.0913V30.1994H87.2902C111.14 30.1994 131.845 34.5357 149.407 43.2082C167.186 51.8807 180.845 64.6727 190.385 81.5842C200.142 98.2788 205.02 119.093 205.02 144.026ZM161.44 145.327C161.44 117.575 155.044 97.1947 142.252 84.1859C129.677 71.1771 111.356 64.6727 87.2902 64.6727H56.7195V227.608H81.7614C134.881 227.608 161.44 200.181 161.44 145.327Z"
        fill={primaryColor}
      />
      <path
        d="M321.555 82.885C345.188 82.885 363.834 89.9315 377.493 104.024C391.369 118.117 398.307 137.739 398.307 162.889V183.703H281.879C282.312 199.747 286.757 212.214 295.213 221.104C303.668 229.776 315.376 234.112 330.336 234.112C341.611 234.112 351.801 233.028 360.907 230.86C370.013 228.475 379.336 225.115 388.876 220.778V253.626C380.203 257.745 371.206 260.78 361.883 262.732C352.56 264.683 341.394 265.659 328.385 265.659C311.257 265.659 295.971 262.407 282.529 255.902C269.303 249.181 258.896 239.099 251.308 225.657C243.936 212.214 240.25 195.52 240.25 175.573C240.25 145.436 247.73 122.454 262.691 106.626C277.651 90.7988 297.272 82.885 321.555 82.885ZM321.555 113.131C310.498 113.131 301.5 116.708 294.562 123.863C287.841 130.801 283.938 141.208 282.854 155.084H358.63C358.414 142.942 355.27 132.969 349.199 125.164C343.345 117.142 334.131 113.131 321.555 113.131Z"
        fill={primaryColor}
      />
      <path
        d="M559.634 211.347C559.634 229.126 553.238 242.677 540.446 252C527.871 261.106 509.767 265.659 486.135 265.659C473.776 265.659 463.152 264.792 454.263 263.057C445.59 261.323 437.135 258.612 428.896 254.927V220.128C437.568 224.247 447.108 227.716 457.515 230.535C468.139 233.354 478.113 234.763 487.435 234.763C498.71 234.763 506.84 233.028 511.827 229.559C516.814 226.09 519.307 221.429 519.307 215.575C519.307 212.106 518.331 209.07 516.38 206.469C514.429 203.65 510.526 200.615 504.672 197.363C499.035 194.11 490.579 190.099 479.305 185.329C468.031 180.993 458.599 176.548 451.011 171.995C443.639 167.225 438.002 161.697 434.099 155.409C430.414 149.122 428.571 140.991 428.571 131.018C428.571 115.19 434.75 103.266 447.108 95.2434C459.683 87.0045 476.378 82.885 497.192 82.885C508.033 82.885 518.223 83.9691 527.763 86.1372C537.519 88.3054 547.276 91.5576 557.033 95.8939L544.024 126.139C535.785 122.67 527.763 119.743 519.958 117.358C512.152 114.973 504.13 113.781 495.891 113.781C477.896 113.781 468.898 118.876 468.898 129.066C468.898 132.752 469.982 135.896 472.15 138.498C474.535 141.099 478.546 143.918 484.183 146.953C490.037 149.772 498.276 153.241 508.9 157.36C519.307 161.48 528.305 165.816 535.893 170.369C543.482 174.706 549.336 180.126 553.455 186.63C557.575 193.135 559.634 201.374 559.634 211.347Z"
        fill={primaryColor}
      />
      <path
        d="M619.551 18.1662C625.839 18.1662 631.151 19.7923 635.487 23.0445C640.04 26.0799 642.316 31.6087 642.316 39.6308C642.316 47.6529 640.04 53.29 635.487 56.5422C631.151 59.7944 625.839 61.4205 619.551 61.4205C613.263 61.4205 607.843 59.7944 603.29 56.5422C598.954 53.29 596.786 47.6529 596.786 39.6308C596.786 31.6087 598.954 26.0799 603.29 23.0445C607.843 19.7923 613.263 18.1662 619.551 18.1662ZM640.04 86.1372V262.407H599.062V86.1372H640.04Z"
        fill={primaryColor}
      />
      <path
        d="M751.626 82.885C773.958 82.885 791.411 91.5576 803.987 108.903H805.613L809.515 86.1372H843.663V263.707C843.663 288.858 836.508 307.937 822.199 320.946C808.106 333.955 786.967 340.459 758.781 340.459C746.206 340.459 734.606 339.592 723.982 337.858C713.358 336.34 703.277 333.63 693.737 329.727V295.904C713.901 305.01 736.449 309.564 761.383 309.564C774.825 309.564 785.015 305.769 791.953 298.181C799.108 290.809 802.686 279.968 802.686 265.659V261.106C802.686 257.854 802.794 254.059 803.011 249.723C803.228 245.387 803.553 241.918 803.987 239.316H802.686C796.615 248.422 789.135 255.143 780.245 259.48C771.573 263.599 761.708 265.659 750.65 265.659C729.186 265.659 712.383 257.637 700.241 241.592C688.1 225.548 682.029 203.217 682.029 174.597C682.029 145.978 688.1 123.538 700.241 107.277C712.6 91.0156 729.728 82.885 751.626 82.885ZM762.358 116.383C736.774 116.383 723.982 136.004 723.982 175.248C723.982 213.84 736.991 233.137 763.009 233.137C777.318 233.137 787.834 229.234 794.555 221.429C801.493 213.407 804.962 199.964 804.962 181.102V174.922C804.962 154.108 801.493 139.148 794.555 130.042C787.834 120.936 777.102 116.383 762.358 116.383Z"
        fill={primaryColor}
      />
      <path
        d="M990.33 82.885C1010.06 82.885 1025.45 87.9802 1036.51 98.1704C1047.79 108.361 1053.42 124.838 1053.42 147.604V262.407H1012.44V155.734C1012.44 129.283 1001.6 116.058 979.923 116.058C963.445 116.058 952.171 121.261 946.1 131.668C940.029 142.075 936.994 156.927 936.994 176.223V262.407H896.016V86.1372H927.562L933.417 109.228H935.693C941.33 100.122 949.027 93.5089 958.784 89.3895C968.757 85.0532 979.273 82.885 990.33 82.885Z"
        fill={primaryColor}
      />
      <path
        d="M1283.55 30.1994L1203.22 262.407H1158.66L1078.34 30.1994H1120.94L1167.77 172.321C1169.07 176.223 1170.59 181.318 1172.32 187.606C1174.06 193.677 1175.68 199.964 1177.2 206.469C1178.94 212.973 1180.24 218.61 1181.1 223.38C1181.76 218.61 1182.84 212.973 1184.36 206.469C1185.87 199.964 1187.5 193.677 1189.24 187.606C1190.97 181.318 1192.6 176.223 1194.11 172.321L1240.95 30.1994H1283.55Z"
        fill={primaryColor}
      />
      <path
        d="M1373.74 82.885C1396.51 82.885 1413.74 87.8718 1425.45 97.8452C1437.38 107.819 1443.34 123.212 1443.34 144.026V262.407H1414.39L1406.26 238.015H1404.96C1397.37 247.555 1389.35 254.601 1380.9 259.154C1372.44 263.491 1360.95 265.659 1346.42 265.659C1330.6 265.659 1317.48 261.214 1307.07 252.325C1296.66 243.435 1291.46 229.668 1291.46 211.022C1291.46 192.809 1298.07 179.15 1311.3 170.044C1324.74 160.938 1345.01 155.843 1372.12 154.759L1402.69 153.783V145.002C1402.69 133.945 1399.98 126.031 1394.56 121.261C1389.14 116.274 1381.55 113.781 1371.79 113.781C1362.9 113.781 1354.34 115.082 1346.1 117.684C1338.08 120.285 1330.05 123.321 1322.03 126.79L1309.02 98.1704C1317.7 93.6173 1327.56 89.9315 1338.62 87.1129C1349.89 84.2943 1361.6 82.885 1373.74 82.885ZM1402.69 179.15L1381.22 179.801C1363.23 180.451 1350.76 183.595 1343.82 189.232C1336.88 194.652 1333.41 202.024 1333.41 211.347C1333.41 219.803 1335.8 225.874 1340.57 229.559C1345.56 233.028 1352.06 234.763 1360.08 234.763C1372.01 234.763 1382.09 231.402 1390.33 224.681C1398.57 217.743 1402.69 207.553 1402.69 194.11V179.15Z"
        fill={primaryColor}
      />
      <path
        d="M1535.67 262.407H1494.69V15.2393H1535.67V262.407Z"
        fill={primaryColor}
      />
      <path
        d="M1629.04 262.407H1588.06V15.2393H1629.04V262.407Z"
        fill={primaryColor}
      />
      <path
        d="M1752.33 82.885C1775.97 82.885 1794.61 89.9315 1808.27 104.024C1822.15 118.117 1829.09 137.739 1829.09 162.889V183.703H1712.66C1713.09 199.747 1717.53 212.214 1725.99 221.104C1734.45 229.776 1746.15 234.112 1761.11 234.112C1772.39 234.112 1782.58 233.028 1791.69 230.86C1800.79 228.475 1810.11 225.115 1819.65 220.778V253.626C1810.98 257.745 1801.98 260.78 1792.66 262.732C1783.34 264.683 1772.17 265.659 1759.16 265.659C1742.03 265.659 1726.75 262.407 1713.31 255.902C1700.08 249.181 1689.67 239.099 1682.09 225.657C1674.71 212.214 1671.03 195.52 1671.03 175.573C1671.03 145.436 1678.51 122.454 1693.47 106.626C1708.43 90.7988 1728.05 82.885 1752.33 82.885ZM1752.33 113.131C1741.28 113.131 1732.28 116.708 1725.34 123.863C1718.62 130.801 1714.72 141.208 1713.63 155.084H1789.41C1789.19 142.942 1786.05 132.969 1779.98 125.164C1774.12 117.142 1764.91 113.131 1752.33 113.131Z"
        fill={primaryColor}
      />
      <path
        d="M1837.71 86.1372H1882.27L1917.39 185.655C1919.78 191.942 1921.73 198.121 1923.24 204.192C1924.76 210.263 1925.85 216.442 1926.5 222.73H1927.8C1928.66 217.309 1929.97 211.347 1931.7 204.843C1933.43 198.338 1935.39 191.942 1937.55 185.655L1971.38 86.1372H2015.61L1940.48 285.822C1933.76 303.384 1924.55 316.827 1912.84 326.15C1901.35 335.69 1887.14 340.459 1870.23 340.459C1864.81 340.459 1860.15 340.134 1856.25 339.484C1852.35 339.05 1848.88 338.508 1845.84 337.858V305.336C1848.23 305.769 1851.05 306.203 1854.3 306.637C1857.77 307.07 1861.34 307.287 1865.03 307.287C1875.22 307.287 1883.24 304.252 1889.1 298.181C1895.17 292.327 1899.83 284.955 1903.08 276.066L1908.28 261.756L1837.71 86.1372Z"
        fill={primaryColor}
      />
      <path
        d="M204.313 143.319C204.313 182.563 193.364 212.158 171.466 232.105C149.567 251.835 118.997 261.7 79.7535 261.7H14.3843V29.4924H86.5832C110.433 29.4924 131.138 33.8286 148.7 42.5012C166.479 51.1737 180.138 63.9657 189.678 80.8771C199.435 97.5718 204.313 118.386 204.313 143.319ZM160.733 144.62C160.733 116.868 154.337 96.4877 141.545 83.4789C128.97 70.4701 110.649 63.9657 86.5832 63.9657H56.0125V226.901H81.0544C134.174 226.901 160.733 199.474 160.733 144.62Z"
        fill={primaryColor}
      />
      <path
        d="M320.848 82.178C344.481 82.178 363.127 89.2245 376.786 103.317C390.662 117.41 397.6 137.032 397.6 162.182V182.996H281.172C281.605 199.04 286.05 211.507 294.506 220.397C302.961 229.069 314.669 233.405 329.629 233.405C340.904 233.405 351.094 232.321 360.2 230.153C369.306 227.768 378.629 224.408 388.169 220.071V252.919C379.496 257.038 370.499 260.073 361.176 262.025C351.853 263.976 340.687 264.952 327.678 264.952C310.55 264.952 295.264 261.7 281.822 255.195C268.596 248.474 258.189 238.392 250.601 224.95C243.229 211.507 239.543 194.813 239.543 174.866C239.543 144.729 247.023 121.746 261.984 105.919C276.944 90.0917 296.565 82.178 320.848 82.178ZM320.848 112.424C309.791 112.424 300.793 116.001 293.855 123.156C287.134 130.094 283.231 140.501 282.147 154.377H357.923C357.707 142.235 354.563 132.262 348.492 124.457C342.638 116.435 333.424 112.424 320.848 112.424Z"
        fill={primaryColor}
      />
      <path
        d="M558.927 210.64C558.927 228.419 552.531 241.97 539.739 251.293C527.164 260.399 509.06 264.952 485.428 264.952C473.069 264.952 462.445 264.085 453.556 262.35C444.883 260.615 436.428 257.905 428.189 254.219V219.421C436.861 223.54 446.401 227.009 456.808 229.828C467.432 232.647 477.405 234.056 486.728 234.056C498.003 234.056 506.133 232.321 511.12 228.852C516.107 225.383 518.6 220.722 518.6 214.868C518.6 211.399 517.624 208.363 515.673 205.762C513.722 202.943 509.819 199.908 503.965 196.656C498.328 193.403 489.872 189.392 478.598 184.622C467.324 180.286 457.892 175.841 450.304 171.288C442.932 166.518 437.295 160.99 433.392 154.702C429.707 148.415 427.864 140.284 427.864 130.311C427.864 114.483 434.043 102.558 446.401 94.5364C458.976 86.2975 475.671 82.178 496.485 82.178C507.326 82.178 517.516 83.2621 527.056 85.4302C536.812 87.5984 546.569 90.8506 556.326 95.1868L543.317 125.432C535.078 121.963 527.056 119.036 519.25 116.651C511.445 114.266 503.423 113.074 495.184 113.074C477.189 113.074 468.191 118.169 468.191 128.359C468.191 132.045 469.275 135.189 471.443 137.791C473.828 140.392 477.839 143.211 483.476 146.246C489.33 149.065 497.569 152.534 508.193 156.653C518.6 160.773 527.598 165.109 535.186 169.662C542.775 173.999 548.629 179.419 552.748 185.923C556.868 192.428 558.927 200.667 558.927 210.64Z"
        fill={primaryColor}
      />
      <path
        d="M618.844 17.4592C625.132 17.4592 630.444 19.0853 634.78 22.3375C639.333 25.3729 641.609 30.9016 641.609 38.9237C641.609 46.9458 639.333 52.583 634.78 55.8352C630.444 59.0874 625.132 60.7135 618.844 60.7135C612.556 60.7135 607.136 59.0874 602.583 55.8352C598.247 52.583 596.079 46.9458 596.079 38.9237C596.079 30.9016 598.247 25.3729 602.583 22.3375C607.136 19.0853 612.556 17.4592 618.844 17.4592ZM639.333 85.4302V261.7H598.355V85.4302H639.333Z"
        fill={primaryColor}
      />
      <path
        d="M750.919 82.178C773.251 82.178 790.704 90.8506 803.279 108.196H804.906L808.808 85.4302H842.956V263C842.956 288.151 835.801 307.23 821.492 320.239C807.399 333.248 786.26 339.752 758.074 339.752C745.499 339.752 733.899 338.885 723.275 337.151C712.651 335.633 702.57 332.923 693.03 329.02V295.197C713.193 304.303 735.742 308.856 760.676 308.856C774.118 308.856 784.308 305.062 791.246 297.474C798.401 290.102 801.979 279.261 801.979 264.952V260.399C801.979 257.146 802.087 253.352 802.304 249.016C802.521 244.68 802.846 241.211 803.279 238.609H801.979C795.908 247.715 788.428 254.436 779.538 258.773C770.866 262.892 761.001 264.952 749.943 264.952C728.479 264.952 711.676 256.93 699.534 240.885C687.393 224.841 681.322 202.509 681.322 173.89C681.322 145.271 687.393 122.831 699.534 106.57C711.893 90.3085 729.021 82.178 750.919 82.178ZM761.651 115.676C736.067 115.676 723.275 135.297 723.275 174.541C723.275 213.133 736.284 232.43 762.302 232.43C776.611 232.43 787.127 228.527 793.848 220.722C800.786 212.7 804.255 199.257 804.255 180.395V174.215C804.255 153.401 800.786 138.441 793.848 129.335C787.127 120.229 776.395 115.676 761.651 115.676Z"
        fill={primaryColor}
      />
      <path
        d="M989.623 82.178C1009.35 82.178 1024.75 87.2731 1035.8 97.4634C1047.08 107.654 1052.72 124.131 1052.72 146.897V261.7H1011.74V155.027C1011.74 128.576 1000.9 115.35 979.216 115.35C962.738 115.35 951.464 120.554 945.393 130.961C939.322 141.368 936.287 156.22 936.287 175.516V261.7H895.309V85.4302H926.855L932.709 108.521H934.986C940.623 99.4147 948.32 92.8019 958.077 88.6824C968.05 84.3462 978.565 82.178 989.623 82.178Z"
        fill={primaryColor}
      />
      <path
        d="M1282.84 29.4924L1202.51 261.7H1157.96L1077.63 29.4924H1120.23L1167.06 171.614C1168.36 175.516 1169.88 180.611 1171.62 186.899C1173.35 192.97 1174.98 199.257 1176.49 205.762C1178.23 212.266 1179.53 217.903 1180.4 222.673C1181.05 217.903 1182.13 212.266 1183.65 205.762C1185.17 199.257 1186.79 192.97 1188.53 186.899C1190.26 180.611 1191.89 175.516 1193.41 171.614L1240.24 29.4924H1282.84Z"
        fill={primaryColor}
      />
      <path
        d="M1373.03 82.178C1395.8 82.178 1413.04 87.1647 1424.74 97.1381C1436.67 107.112 1442.63 122.505 1442.63 143.319V261.7H1413.69L1405.56 237.308H1404.26C1396.67 246.848 1388.65 253.894 1380.19 258.447C1371.73 262.784 1360.24 264.952 1345.72 264.952C1329.89 264.952 1316.77 260.507 1306.36 251.618C1295.96 242.728 1290.75 228.961 1290.75 210.315C1290.75 192.102 1297.37 178.443 1310.59 169.337C1324.03 160.231 1344.31 155.136 1371.41 154.052L1401.98 153.076V144.295C1401.98 133.238 1399.27 125.324 1393.85 120.554C1388.43 115.567 1380.84 113.074 1371.08 113.074C1362.19 113.074 1353.63 114.375 1345.39 116.977C1337.37 119.578 1329.35 122.614 1321.32 126.083L1308.32 97.4634C1316.99 92.9103 1326.85 89.2245 1337.91 86.4059C1349.19 83.5873 1360.89 82.178 1373.03 82.178ZM1401.98 178.443L1380.51 179.094C1362.52 179.744 1350.05 182.888 1343.11 188.525C1336.18 193.945 1332.71 201.317 1332.71 210.64C1332.71 219.096 1335.09 225.166 1339.86 228.852C1344.85 232.321 1351.35 234.056 1359.38 234.056C1371.3 234.056 1381.38 230.695 1389.62 223.974C1397.86 217.036 1401.98 206.846 1401.98 193.403V178.443Z"
        fill={primaryColor}
      />
      <path
        d="M1534.96 261.7H1493.98V14.5322H1534.96V261.7Z"
        fill={primaryColor}
      />
      <path
        d="M1628.33 261.7H1587.35V14.5322H1628.33V261.7Z"
        fill={primaryColor}
      />
      <path
        d="M1751.63 82.178C1775.26 82.178 1793.91 89.2245 1807.56 103.317C1821.44 117.41 1828.38 137.032 1828.38 162.182V182.996H1711.95C1712.38 199.04 1716.83 211.507 1725.28 220.397C1733.74 229.069 1745.45 233.405 1760.41 233.405C1771.68 233.405 1781.87 232.321 1790.98 230.153C1800.08 227.768 1809.41 224.408 1818.95 220.071V252.919C1810.27 257.038 1801.28 260.073 1791.95 262.025C1782.63 263.976 1771.46 264.952 1758.46 264.952C1741.33 264.952 1726.04 261.7 1712.6 255.195C1699.37 248.474 1688.97 238.392 1681.38 224.95C1674.01 211.507 1670.32 194.813 1670.32 174.866C1670.32 144.729 1677.8 121.746 1692.76 105.919C1707.72 90.0917 1727.34 82.178 1751.63 82.178ZM1751.63 112.424C1740.57 112.424 1731.57 116.001 1724.63 123.156C1717.91 130.094 1714.01 140.501 1712.93 154.377H1788.7C1788.48 142.235 1785.34 132.262 1779.27 124.457C1773.42 116.435 1764.2 112.424 1751.63 112.424Z"
        fill={primaryColor}
      />
      <path
        d="M1837 85.4302H1881.56L1916.68 184.948C1919.07 191.235 1921.02 197.414 1922.54 203.485C1924.06 209.556 1925.14 215.735 1925.79 222.023H1927.09C1927.96 216.602 1929.26 210.64 1930.99 204.136C1932.73 197.631 1934.68 191.235 1936.85 184.948L1970.67 85.4302H2014.9L1939.77 285.115C1933.05 302.677 1923.84 316.12 1912.13 325.443C1900.64 334.983 1886.44 339.752 1869.53 339.752C1864.11 339.752 1859.44 339.427 1855.54 338.777C1851.64 338.343 1848.17 337.801 1845.13 337.151V304.629C1847.52 305.062 1850.34 305.496 1853.59 305.93C1857.06 306.363 1860.64 306.58 1864.32 306.58C1874.51 306.58 1882.54 303.545 1888.39 297.474C1894.46 291.62 1899.12 284.248 1902.37 275.359L1907.58 261.049L1837 85.4302Z"
        fill={primaryColor}
      />
      <path
        d="M203.606 142.612C203.606 181.856 192.657 211.451 170.759 231.397C148.86 251.128 118.29 260.993 79.0465 260.993H13.6772V28.7853H85.8761C109.726 28.7853 130.431 33.1216 147.993 41.7941C165.772 50.4667 179.431 63.2587 188.971 80.1701C198.728 96.8648 203.606 117.679 203.606 142.612ZM160.026 143.913C160.026 116.161 153.63 95.7807 140.838 82.7719C128.263 69.7631 109.942 63.2587 85.8761 63.2587H55.3054V226.194H80.3474C133.467 226.194 160.026 198.767 160.026 143.913Z"
        fill={primaryColor}
      />
      <path
        d="M320.141 81.471C343.774 81.471 362.42 88.5174 376.079 102.61C389.955 116.703 396.893 136.325 396.893 161.475V182.289H280.464C280.898 198.333 285.343 210.8 293.799 219.69C302.254 228.362 313.962 232.698 328.922 232.698C340.197 232.698 350.387 231.614 359.493 229.446C368.599 227.061 377.922 223.701 387.462 219.364V252.212C378.789 256.331 369.792 259.366 360.469 261.318C351.146 263.269 339.98 264.245 326.971 264.245C309.843 264.245 294.557 260.993 281.115 254.488C267.889 247.767 257.482 237.685 249.894 224.243C242.522 210.8 238.836 194.106 238.836 174.159C238.836 144.022 246.316 121.039 261.276 105.212C276.237 89.3847 295.858 81.471 320.141 81.471ZM320.141 111.716C309.084 111.716 300.086 115.294 293.148 122.449C286.427 129.387 282.524 139.794 281.44 153.67H357.216C357 141.528 353.856 131.555 347.785 123.75C341.931 115.728 332.717 111.716 320.141 111.716Z"
        fill={primaryColor}
      />
      <path
        d="M558.22 209.933C558.22 227.712 551.824 241.262 539.032 250.585C526.457 259.692 508.353 264.245 484.721 264.245C472.362 264.245 461.738 263.377 452.849 261.643C444.176 259.908 435.721 257.198 427.482 253.512V218.714C436.154 222.833 445.694 226.302 456.101 229.121C466.725 231.94 476.698 233.349 486.021 233.349C497.296 233.349 505.426 231.614 510.413 228.145C515.4 224.676 517.893 220.015 517.893 214.161C517.893 210.692 516.917 207.656 514.966 205.055C513.015 202.236 509.112 199.201 503.258 195.948C497.621 192.696 489.165 188.685 477.891 183.915C466.617 179.579 457.185 175.134 449.597 170.581C442.225 165.811 436.588 160.283 432.685 153.995C428.999 147.708 427.157 139.577 427.157 129.604C427.157 113.776 433.336 101.851 445.694 93.8294C458.269 85.5904 474.964 81.471 495.778 81.471C506.619 81.471 516.809 82.5551 526.349 84.7232C536.105 86.8913 545.862 90.1435 555.619 94.4798L542.61 124.725C534.371 121.256 526.349 118.329 518.543 115.944C510.738 113.559 502.716 112.367 494.477 112.367C476.482 112.367 467.484 117.462 467.484 127.652C467.484 131.338 468.568 134.482 470.736 137.084C473.121 139.685 477.132 142.504 482.769 145.539C488.623 148.358 496.862 151.827 507.486 155.946C517.893 160.066 526.891 164.402 534.479 168.955C542.068 173.292 547.922 178.712 552.041 185.216C556.161 191.721 558.22 199.96 558.22 209.933Z"
        fill={primaryColor}
      />
      <path
        d="M618.137 16.7522C624.425 16.7522 629.737 18.3783 634.073 21.6305C638.626 24.6659 640.902 30.1946 640.902 38.2167C640.902 46.2388 638.626 51.876 634.073 55.1282C629.737 58.3804 624.425 60.0065 618.137 60.0065C611.849 60.0065 606.429 58.3804 601.876 55.1282C597.54 51.876 595.372 46.2388 595.372 38.2167C595.372 30.1946 597.54 24.6659 601.876 21.6305C606.429 18.3783 611.849 16.7522 618.137 16.7522ZM638.626 84.7232V260.993H597.648V84.7232H638.626Z"
        fill={primaryColor}
      />
      <path
        d="M750.212 81.471C772.544 81.471 789.997 90.1435 802.572 107.489H804.199L808.101 84.7232H842.249V262.293C842.249 287.444 835.094 306.523 820.785 319.532C806.692 332.541 785.553 339.045 757.367 339.045C744.792 339.045 733.192 338.178 722.568 336.444C711.944 334.926 701.863 332.216 692.323 328.313V294.49C712.486 303.596 735.035 308.149 759.969 308.149C773.411 308.149 783.601 304.355 790.539 296.767C797.694 289.395 801.272 278.554 801.272 264.245V259.692C801.272 256.439 801.38 252.645 801.597 248.309C801.814 243.973 802.139 240.504 802.572 237.902H801.272C795.201 247.008 787.721 253.729 778.831 258.066C770.159 262.185 760.294 264.245 749.236 264.245C727.772 264.245 710.969 256.223 698.827 240.178C686.686 224.134 680.615 201.802 680.615 173.183C680.615 144.564 686.686 122.124 698.827 105.863C711.186 89.6015 728.314 81.471 750.212 81.471ZM760.944 114.969C735.36 114.969 722.568 134.59 722.568 173.834C722.568 212.426 735.577 231.723 761.595 231.723C775.904 231.723 786.42 227.82 793.141 220.015C800.079 211.993 803.548 198.55 803.548 179.687V173.508C803.548 152.694 800.079 137.734 793.141 128.628C786.42 119.522 775.688 114.969 760.944 114.969Z"
        fill={primaryColor}
      />
      <path
        d="M988.916 81.471C1008.65 81.471 1024.04 86.5661 1035.1 96.7564C1046.37 106.947 1052.01 123.424 1052.01 146.19V260.993H1011.03V154.32C1011.03 127.869 1000.19 114.643 978.509 114.643C962.031 114.643 950.757 119.847 944.686 130.254C938.615 140.661 935.58 155.513 935.58 174.809V260.993H894.602V84.7232H926.148L932.002 107.814H934.279C939.916 98.7077 947.613 92.0949 957.37 87.9754C967.343 83.6391 977.858 81.471 988.916 81.471Z"
        fill={primaryColor}
      />
      <path
        d="M1282.14 28.7853L1201.81 260.993H1157.25L1076.92 28.7853H1119.52L1166.36 170.907C1167.66 174.809 1169.18 179.904 1170.91 186.192C1172.64 192.263 1174.27 198.55 1175.79 205.055C1177.52 211.559 1178.82 217.196 1179.69 221.966C1180.34 217.196 1181.43 211.559 1182.94 205.055C1184.46 198.55 1186.09 192.263 1187.82 186.192C1189.56 179.904 1191.18 174.809 1192.7 170.907L1239.53 28.7853H1282.14Z"
        fill={primaryColor}
      />
      <path
        d="M1372.33 81.471C1395.09 81.471 1412.33 86.4577 1424.04 96.4311C1435.96 106.405 1441.92 121.798 1441.92 142.612V260.993H1412.98L1404.85 236.601H1403.55C1395.96 246.141 1387.94 253.187 1379.48 257.74C1371.03 262.077 1359.54 264.245 1345.01 264.245C1329.18 264.245 1316.06 259.8 1305.66 250.911C1295.25 242.021 1290.05 228.254 1290.05 209.608C1290.05 191.395 1296.66 177.736 1309.89 168.63C1323.33 159.524 1343.6 154.429 1370.7 153.345L1401.27 152.369V143.588C1401.27 132.531 1398.56 124.617 1393.14 119.847C1387.72 114.86 1380.13 112.367 1370.38 112.367C1361.49 112.367 1352.92 113.668 1344.68 116.27C1336.66 118.871 1328.64 121.907 1320.62 125.376L1307.61 96.7564C1316.28 92.2033 1326.15 88.5174 1337.2 85.6989C1348.48 82.8803 1360.19 81.471 1372.33 81.471ZM1401.27 177.736L1379.81 178.387C1361.81 179.037 1349.35 182.181 1342.41 187.818C1335.47 193.238 1332 200.61 1332 209.933C1332 218.389 1334.39 224.459 1339.16 228.145C1344.14 231.614 1350.65 233.349 1358.67 233.349C1370.59 233.349 1380.67 229.988 1388.91 223.267C1397.15 216.329 1401.27 206.139 1401.27 192.696V177.736Z"
        fill={primaryColor}
      />
      <path
        d="M1534.25 260.993H1493.27V13.8252H1534.25V260.993Z"
        fill={primaryColor}
      />
      <path
        d="M1627.63 260.993H1586.65V13.8252H1627.63V260.993Z"
        fill={primaryColor}
      />
      <path
        d="M1750.92 81.471C1774.55 81.471 1793.2 88.5174 1806.86 102.61C1820.73 116.703 1827.67 136.325 1827.67 161.475V182.289H1711.24C1711.68 198.333 1716.12 210.8 1724.58 219.69C1733.03 228.362 1744.74 232.698 1759.7 232.698C1770.97 232.698 1781.16 231.614 1790.27 229.446C1799.38 227.061 1808.7 223.701 1818.24 219.364V252.212C1809.57 256.331 1800.57 259.366 1791.25 261.318C1781.92 263.269 1770.76 264.245 1757.75 264.245C1740.62 264.245 1725.34 260.993 1711.89 254.488C1698.67 247.767 1688.26 237.685 1680.67 224.243C1673.3 210.8 1669.61 194.106 1669.61 174.159C1669.61 144.022 1677.09 121.039 1692.05 105.212C1707.01 89.3847 1726.64 81.471 1750.92 81.471ZM1750.92 111.716C1739.86 111.716 1730.86 115.294 1723.93 122.449C1717.2 129.387 1713.3 139.794 1712.22 153.67H1787.99C1787.78 141.528 1784.63 131.555 1778.56 123.75C1772.71 115.728 1763.49 111.716 1750.92 111.716Z"
        fill={primaryColor}
      />
      <path
        d="M1836.3 84.7232H1880.85L1915.98 184.241C1918.36 190.528 1920.31 196.707 1921.83 202.778C1923.35 208.849 1924.43 215.028 1925.08 221.316H1926.38C1927.25 215.895 1928.55 209.933 1930.29 203.429C1932.02 196.924 1933.97 190.528 1936.14 184.241L1969.96 84.7232H2014.19L1939.07 284.408C1932.35 301.97 1923.13 315.413 1911.42 324.736C1899.93 334.275 1885.73 339.045 1868.82 339.045C1863.4 339.045 1858.74 338.72 1854.83 338.07C1850.93 337.636 1847.46 337.094 1844.43 336.444V303.922C1846.81 304.355 1849.63 304.789 1852.88 305.222C1856.35 305.656 1859.93 305.873 1863.62 305.873C1873.81 305.873 1881.83 302.838 1887.68 296.767C1893.75 290.913 1898.41 283.541 1901.67 274.652L1906.87 260.342L1836.3 84.7232Z"
        fill={primaryColor}
      />
      <path
        d="M202.899 141.905C202.899 181.149 191.95 210.744 170.052 230.69C148.153 250.42 117.583 260.285 78.3395 260.285H12.9702V28.0783H85.1691C109.019 28.0783 129.724 32.4146 147.286 41.0871C165.065 49.7596 178.724 62.5516 188.264 79.4631C198.02 96.1577 202.899 116.972 202.899 141.905ZM159.319 143.206C159.319 115.454 152.923 95.0736 140.131 82.0648C127.556 69.056 109.235 62.5516 85.1691 62.5516H54.5984V225.487H79.6404C132.76 225.487 159.319 198.06 159.319 143.206Z"
        fill={primaryColor}
      />
      <path
        d="M319.434 80.764C343.067 80.764 361.713 87.8104 375.372 101.903C389.248 115.996 396.186 135.618 396.186 160.768V181.582H279.757C280.191 197.626 284.636 210.093 293.091 218.983C301.547 227.655 313.255 231.991 328.215 231.991C339.49 231.991 349.68 230.907 358.786 228.739C367.892 226.354 377.215 222.994 386.755 218.657V251.505C378.082 255.624 369.085 258.659 359.762 260.611C350.439 262.562 339.273 263.538 326.264 263.538C309.136 263.538 293.85 260.286 280.408 253.781C267.182 247.06 256.775 236.978 249.187 223.536C241.815 210.093 238.129 193.399 238.129 173.452C238.129 143.315 245.609 120.332 260.569 104.505C275.53 88.6777 295.151 80.764 319.434 80.764ZM319.434 111.009C308.377 111.009 299.379 114.587 292.441 121.742C285.72 128.68 281.817 139.087 280.733 152.963H356.509C356.293 140.821 353.149 130.848 347.078 123.043C341.224 115.021 332.009 111.009 319.434 111.009Z"
        fill={primaryColor}
      />
      <path
        d="M557.513 209.226C557.513 227.005 551.117 240.555 538.325 249.878C525.75 258.985 507.646 263.538 484.013 263.538C471.655 263.538 461.031 262.67 452.142 260.936C443.469 259.201 435.014 256.491 426.775 252.805V218.007C435.447 222.126 444.987 225.595 455.394 228.414C466.018 231.232 475.991 232.642 485.314 232.642C496.589 232.642 504.719 230.907 509.706 227.438C514.693 223.969 517.186 219.308 517.186 213.454C517.186 209.985 516.21 206.949 514.259 204.348C512.308 201.529 508.405 198.494 502.551 195.241C496.914 191.989 488.458 187.978 477.184 183.208C465.91 178.872 456.478 174.427 448.89 169.874C441.518 165.104 435.881 159.576 431.978 153.288C428.292 147 426.45 138.87 426.45 128.897C426.45 113.069 432.629 101.144 444.987 93.1223C457.562 84.8834 474.257 80.764 495.071 80.764C505.912 80.764 516.102 81.848 525.642 84.0162C535.398 86.1843 545.155 89.4365 554.911 93.7728L541.903 124.018C533.664 120.549 525.642 117.622 517.836 115.237C510.031 112.852 502.009 111.66 493.77 111.66C475.775 111.66 466.777 116.755 466.777 126.945C466.777 130.631 467.861 133.775 470.029 136.377C472.414 138.978 476.425 141.797 482.062 144.832C487.916 147.651 496.155 151.12 506.779 155.239C517.186 159.359 526.184 163.695 533.772 168.248C541.361 172.584 547.215 178.005 551.334 184.509C555.454 191.014 557.513 199.253 557.513 209.226Z"
        fill={primaryColor}
      />
      <path
        d="M617.43 16.0452C623.718 16.0452 629.03 17.6712 633.366 20.9234C637.919 23.9588 640.195 29.4876 640.195 37.5097C640.195 45.5318 637.919 51.1689 633.366 54.4211C629.03 57.6733 623.718 59.2994 617.43 59.2994C611.142 59.2994 605.722 57.6733 601.169 54.4211C596.833 51.1689 594.665 45.5318 594.665 37.5097C594.665 29.4876 596.833 23.9588 601.169 20.9234C605.722 17.6712 611.142 16.0452 617.43 16.0452ZM637.919 84.0162V260.285H596.941V84.0162H637.919Z"
        fill={primaryColor}
      />
      <path
        d="M749.505 80.764C771.837 80.764 789.29 89.4365 801.865 106.782H803.492L807.394 84.0162H841.542V261.586C841.542 286.737 834.387 305.816 820.078 318.825C805.985 331.834 784.846 338.338 756.66 338.338C744.085 338.338 732.485 337.471 721.861 335.737C711.237 334.219 701.156 331.509 691.616 327.606V293.783C711.779 302.889 734.328 307.442 759.262 307.442C772.704 307.442 782.894 303.648 789.832 296.06C796.987 288.688 800.565 277.847 800.565 263.538V258.985C800.565 255.732 800.673 251.938 800.89 247.602C801.107 243.266 801.432 239.797 801.865 237.195H800.565C794.494 246.301 787.014 253.022 778.124 257.359C769.452 261.478 759.587 263.538 748.529 263.538C727.065 263.538 710.262 255.516 698.12 239.471C685.979 223.427 679.908 201.095 679.908 172.476C679.908 143.857 685.979 121.416 698.12 105.155C710.479 88.8945 727.607 80.764 749.505 80.764ZM760.237 114.262C734.653 114.262 721.861 133.883 721.861 173.126C721.861 211.719 734.87 231.016 760.888 231.016C775.197 231.016 785.713 227.113 792.434 219.308C799.372 211.286 802.841 197.843 802.841 178.98V172.801C802.841 151.987 799.372 137.027 792.434 127.921C785.713 118.815 774.981 114.262 760.237 114.262Z"
        fill={primaryColor}
      />
      <path
        d="M988.209 80.764C1007.94 80.764 1023.33 85.8591 1034.39 96.0493C1045.66 106.24 1051.3 122.717 1051.3 145.483V260.285H1010.32V153.613C1010.32 127.162 999.483 113.936 977.802 113.936C961.324 113.936 950.05 119.14 943.979 129.547C937.908 139.954 934.873 154.806 934.873 174.102V260.285H893.895V84.0162H925.441L931.295 107.107H933.572C939.209 98.0006 946.906 91.3878 956.663 87.2684C966.636 82.9321 977.151 80.764 988.209 80.764Z"
        fill={primaryColor}
      />
      <path
        d="M1281.43 28.0783L1201.1 260.285H1156.54L1076.21 28.0783H1118.82L1165.65 170.199C1166.95 174.102 1168.47 179.197 1170.2 185.485C1171.94 191.556 1173.56 197.843 1175.08 204.348C1176.82 210.852 1178.12 216.489 1178.98 221.259C1179.63 216.489 1180.72 210.852 1182.24 204.348C1183.75 197.843 1185.38 191.556 1187.11 185.485C1188.85 179.197 1190.47 174.102 1191.99 170.199L1238.82 28.0783H1281.43Z"
        fill={primaryColor}
      />
      <path
        d="M1371.62 80.764C1394.39 80.764 1411.62 85.7507 1423.33 95.7241C1435.26 105.698 1441.22 121.091 1441.22 141.905V260.285H1412.27L1404.14 235.894H1402.84C1395.25 245.434 1387.23 252.48 1378.78 257.033C1370.32 261.37 1358.83 263.538 1344.3 263.538C1328.47 263.538 1315.36 259.093 1304.95 250.204C1294.54 241.314 1289.34 227.547 1289.34 208.901C1289.34 190.688 1295.95 177.029 1309.18 167.923C1322.62 158.817 1342.89 153.722 1369.99 152.638L1400.57 151.662V142.881C1400.57 131.824 1397.85 123.91 1392.43 119.14C1387.01 114.153 1379.43 111.66 1369.67 111.66C1360.78 111.66 1352.22 112.961 1343.98 115.563C1335.95 118.164 1327.93 121.2 1319.91 124.669L1306.9 96.0493C1315.57 91.4962 1325.44 87.8104 1336.5 84.9918C1347.77 82.1732 1359.48 80.764 1371.62 80.764ZM1400.57 177.029L1379.1 177.68C1361.11 178.33 1348.64 181.474 1341.7 187.111C1334.76 192.531 1331.29 199.903 1331.29 209.226C1331.29 217.682 1333.68 223.752 1338.45 227.438C1343.43 230.907 1349.94 232.642 1357.96 232.642C1369.89 232.642 1379.97 229.281 1388.21 222.56C1396.45 215.622 1400.57 205.432 1400.57 191.989V177.029Z"
        fill={primaryColor}
      />
      <path
        d="M1533.54 260.285H1492.57V13.1182H1533.54V260.285Z"
        fill={primaryColor}
      />
      <path
        d="M1626.92 260.285H1585.94V13.1182H1626.92V260.285Z"
        fill={primaryColor}
      />
      <path
        d="M1750.21 80.764C1773.85 80.764 1792.49 87.8104 1806.15 101.903C1820.03 115.996 1826.96 135.618 1826.96 160.768V181.582H1710.54C1710.97 197.626 1715.41 210.093 1723.87 218.983C1732.33 227.655 1744.03 231.991 1758.99 231.991C1770.27 231.991 1780.46 230.907 1789.56 228.739C1798.67 226.354 1807.99 222.994 1817.53 218.657V251.505C1808.86 255.624 1799.86 258.659 1790.54 260.611C1781.22 262.562 1770.05 263.538 1757.04 263.538C1739.91 263.538 1724.63 260.286 1711.19 253.781C1697.96 247.06 1687.55 236.978 1679.96 223.536C1672.59 210.093 1668.91 193.399 1668.91 173.452C1668.91 143.315 1676.39 120.332 1691.35 104.505C1706.31 88.6777 1725.93 80.764 1750.21 80.764ZM1750.21 111.009C1739.15 111.009 1730.16 114.587 1723.22 121.742C1716.5 128.68 1712.6 139.087 1711.51 152.963H1787.29C1787.07 140.821 1783.93 130.848 1777.86 123.043C1772 115.021 1762.79 111.009 1750.21 111.009Z"
        fill={primaryColor}
      />
      <path
        d="M1835.59 84.0162H1880.15L1915.27 183.534C1917.65 189.821 1919.61 196 1921.12 202.071C1922.64 208.142 1923.73 214.321 1924.38 220.609H1925.68C1926.54 215.188 1927.84 209.226 1929.58 202.722C1931.31 196.217 1933.26 189.821 1935.43 183.534L1969.26 84.0162H2013.49L1938.36 283.701C1931.64 301.263 1922.42 314.706 1910.72 324.029C1899.23 333.568 1885.02 338.338 1868.11 338.338C1862.69 338.338 1858.03 338.013 1854.13 337.363C1850.23 336.929 1846.76 336.387 1843.72 335.737V303.215C1846.11 303.648 1848.92 304.082 1852.18 304.515C1855.65 304.949 1859.22 305.166 1862.91 305.166C1873.1 305.166 1881.12 302.13 1886.98 296.06C1893.05 290.206 1897.71 282.834 1900.96 273.945L1906.16 259.635L1835.59 84.0162Z"
        fill={primaryColor}
      />
      <path
        d="M202.192 141.198C202.192 180.442 191.243 210.037 169.345 229.983C147.446 249.713 116.876 259.578 77.6324 259.578H12.2632V27.3713H84.4621C108.312 27.3713 129.017 31.7075 146.579 40.3801C164.358 49.0526 178.017 61.8446 187.557 78.7561C197.313 95.4507 202.192 116.265 202.192 141.198ZM158.612 142.499C158.612 114.747 152.216 94.3666 139.424 81.3578C126.849 68.349 108.528 61.8446 84.4621 61.8446H53.8914V224.78H78.9333C132.053 224.78 158.612 197.353 158.612 142.499Z"
        fill={primaryColor}
      />
      <path
        d="M318.727 80.0569C342.36 80.0569 361.006 87.1034 374.665 101.196C388.541 115.289 395.479 134.911 395.479 160.061V180.875H279.05C279.484 196.919 283.929 209.386 292.384 218.276C300.84 226.948 312.548 231.284 327.508 231.284C338.783 231.284 348.973 230.2 358.079 228.032C367.185 225.647 376.508 222.287 386.048 217.95V250.798C377.375 254.917 368.378 257.952 359.055 259.904C349.732 261.855 338.566 262.831 325.557 262.831C308.429 262.831 293.143 259.578 279.701 253.074C266.475 246.353 256.068 236.271 248.48 222.829C241.108 209.386 237.422 192.691 237.422 172.745C237.422 142.608 244.902 119.625 259.862 103.798C274.823 87.9706 294.444 80.0569 318.727 80.0569ZM318.727 110.302C307.67 110.302 298.672 113.88 291.734 121.035C285.013 127.973 281.11 138.38 280.026 152.256H355.802C355.586 140.114 352.442 130.141 346.371 122.336C340.517 114.313 331.302 110.302 318.727 110.302Z"
        fill={primaryColor}
      />
      <path
        d="M556.806 208.519C556.806 226.298 550.41 239.848 537.618 249.171C525.043 258.278 506.939 262.831 483.306 262.831C470.948 262.831 460.324 261.963 451.435 260.229C442.762 258.494 434.307 255.784 426.068 252.098V217.3C434.74 221.419 444.28 224.888 454.687 227.707C465.311 230.525 475.284 231.935 484.607 231.935C495.882 231.935 504.012 230.2 508.999 226.731C513.986 223.262 516.479 218.601 516.479 212.747C516.479 209.278 515.503 206.242 513.552 203.641C511.601 200.822 507.698 197.787 501.844 194.534C496.207 191.282 487.751 187.271 476.477 182.501C465.203 178.165 455.771 173.72 448.183 169.167C440.811 164.397 435.174 158.869 431.271 152.581C427.585 146.293 425.742 138.163 425.742 128.19C425.742 112.362 431.922 100.437 444.28 92.4153C456.855 84.1764 473.55 80.0569 494.364 80.0569C505.205 80.0569 515.395 81.141 524.935 83.3091C534.691 85.4773 544.448 88.7295 554.204 93.0657L541.196 123.311C532.957 119.842 524.935 116.915 517.129 114.53C509.324 112.145 501.302 110.953 493.063 110.953C475.068 110.953 466.07 116.048 466.07 126.238C466.07 129.924 467.154 133.068 469.322 135.67C471.707 138.271 475.718 141.09 481.355 144.125C487.209 146.944 495.448 150.413 506.072 154.532C516.479 158.652 525.477 162.988 533.065 167.541C540.654 171.877 546.508 177.298 550.627 183.802C554.747 190.307 556.806 198.545 556.806 208.519Z"
        fill={primaryColor}
      />
      <path
        d="M616.723 15.3381C623.011 15.3381 628.323 16.9642 632.659 20.2164C637.212 23.2518 639.488 28.7805 639.488 36.8026C639.488 44.8248 637.212 50.4619 632.659 53.7141C628.323 56.9663 623.011 58.5924 616.723 58.5924C610.435 58.5924 605.015 56.9663 600.462 53.7141C596.126 50.4619 593.958 44.8248 593.958 36.8026C593.958 28.7805 596.126 23.2518 600.462 20.2164C605.015 16.9642 610.435 15.3381 616.723 15.3381ZM637.212 83.3091V259.578H596.234V83.3091H637.212Z"
        fill={primaryColor}
      />
      <path
        d="M748.798 80.0569C771.13 80.0569 788.583 88.7295 801.158 106.075H802.784L806.687 83.3091H840.835V260.879C840.835 286.03 833.68 305.109 819.371 318.118C805.278 331.127 784.138 337.631 755.953 337.631C743.378 337.631 731.778 336.764 721.154 335.03C710.53 333.512 700.449 330.802 690.909 326.899V293.076C711.072 302.182 733.621 306.735 758.555 306.735C771.997 306.735 782.187 302.941 789.125 295.353C796.28 287.981 799.857 277.14 799.857 262.831V258.278C799.857 255.025 799.966 251.231 800.183 246.895C800.4 242.559 800.725 239.09 801.158 236.488H799.857C793.787 245.594 786.307 252.315 777.417 256.651C768.745 260.771 758.88 262.831 747.822 262.831C726.358 262.831 709.555 254.809 697.413 238.764C685.272 222.72 679.201 200.388 679.201 171.769C679.201 143.15 685.272 120.709 697.413 104.448C709.771 88.1874 726.9 80.0569 748.798 80.0569ZM759.53 113.555C733.946 113.555 721.154 133.176 721.154 172.419C721.154 211.012 734.163 230.309 760.181 230.309C774.49 230.309 785.006 226.406 791.727 218.601C798.665 210.579 802.134 197.136 802.134 178.273V172.094C802.134 151.28 798.665 136.32 791.727 127.214C785.006 118.108 774.273 113.555 759.53 113.555Z"
        fill={primaryColor}
      />
      <path
        d="M987.502 80.0569C1007.23 80.0569 1022.63 85.1521 1033.68 95.3423C1044.96 105.533 1050.59 122.01 1050.59 144.776V259.578H1009.62V152.906C1009.62 126.455 998.776 113.229 977.095 113.229C960.617 113.229 949.343 118.433 943.272 128.84C937.201 139.247 934.166 154.099 934.166 173.395V259.578H893.188V83.3091H924.734L930.588 106.4H932.865C938.502 97.2936 946.199 90.6808 955.956 86.5613C965.929 82.2251 976.444 80.0569 987.502 80.0569Z"
        fill={primaryColor}
      />
      <path
        d="M1280.72 27.3713L1200.39 259.578H1155.84L1075.51 27.3713H1118.11L1164.94 169.492C1166.24 173.395 1167.76 178.49 1169.5 184.778C1171.23 190.849 1172.86 197.136 1174.37 203.641C1176.11 210.145 1177.41 215.782 1178.28 220.552C1178.93 215.782 1180.01 210.145 1181.53 203.641C1183.05 197.136 1184.67 190.849 1186.41 184.778C1188.14 178.49 1189.77 173.395 1191.29 169.492L1238.12 27.3713H1280.72Z"
        fill={primaryColor}
      />
      <path
        d="M1370.91 80.0569C1393.68 80.0569 1410.92 85.0436 1422.62 95.0171C1434.55 104.99 1440.51 120.384 1440.51 141.198V259.578H1411.57L1403.44 235.187H1402.13C1394.55 244.727 1386.52 251.773 1378.07 256.326C1369.61 260.663 1358.12 262.831 1343.59 262.831C1327.77 262.831 1314.65 258.386 1304.24 249.497C1293.84 240.607 1288.63 226.84 1288.63 208.194C1288.63 189.981 1295.25 176.322 1308.47 167.216C1321.91 158.11 1342.19 153.015 1369.29 151.931L1399.86 150.955V142.174C1399.86 131.116 1397.15 123.203 1391.73 118.433C1386.31 113.446 1378.72 110.953 1368.96 110.953C1360.07 110.953 1351.51 112.254 1343.27 114.855C1335.25 117.457 1327.23 120.493 1319.2 123.962L1306.19 95.3423C1314.87 90.7892 1324.73 87.1034 1335.79 84.2848C1347.06 81.4662 1358.77 80.0569 1370.91 80.0569ZM1399.86 176.322L1378.39 176.973C1360.4 177.623 1347.93 180.767 1340.99 186.404C1334.06 191.824 1330.59 199.196 1330.59 208.519C1330.59 216.975 1332.97 223.045 1337.74 226.731C1342.73 230.2 1349.23 231.935 1357.25 231.935C1369.18 231.935 1379.26 228.574 1387.5 221.853C1395.74 214.915 1399.86 204.725 1399.86 191.282V176.322Z"
        fill={primaryColor}
      />
      <path
        d="M1532.84 259.578H1491.86V12.4111H1532.84V259.578Z"
        fill={primaryColor}
      />
      <path
        d="M1626.21 259.578H1585.23V12.4111H1626.21V259.578Z"
        fill={primaryColor}
      />
      <path
        d="M1749.51 80.0569C1773.14 80.0569 1791.78 87.1034 1805.44 101.196C1819.32 115.289 1826.26 134.911 1826.26 160.061V180.875H1709.83C1710.26 196.919 1714.71 209.386 1723.16 218.276C1731.62 226.948 1743.33 231.284 1758.29 231.284C1769.56 231.284 1779.75 230.2 1788.86 228.032C1797.96 225.647 1807.29 222.287 1816.83 217.95V250.798C1808.15 254.917 1799.16 257.952 1789.83 259.904C1780.51 261.855 1769.34 262.831 1756.33 262.831C1739.21 262.831 1723.92 259.578 1710.48 253.074C1697.25 246.353 1686.85 236.271 1679.26 222.829C1671.89 209.386 1668.2 192.691 1668.2 172.745C1668.2 142.608 1675.68 119.625 1690.64 103.798C1705.6 87.9706 1725.22 80.0569 1749.51 80.0569ZM1749.51 110.302C1738.45 110.302 1729.45 113.88 1722.51 121.035C1715.79 127.973 1711.89 138.38 1710.8 152.256H1786.58C1786.36 140.114 1783.22 130.141 1777.15 122.336C1771.3 114.313 1762.08 110.302 1749.51 110.302Z"
        fill={primaryColor}
      />
      <path
        d="M1834.88 83.3091H1879.44L1914.56 182.827C1916.95 189.114 1918.9 195.293 1920.42 201.364C1921.93 207.435 1923.02 213.614 1923.67 219.902H1924.97C1925.84 214.481 1927.14 208.519 1928.87 202.014C1930.61 195.51 1932.56 189.114 1934.73 182.827L1968.55 83.3091H2012.78L1937.65 282.994C1930.93 300.556 1921.72 313.999 1910.01 323.322C1898.52 332.861 1884.32 337.631 1867.41 337.631C1861.98 337.631 1857.32 337.306 1853.42 336.656C1849.52 336.222 1846.05 335.68 1843.01 335.03V302.508C1845.4 302.941 1848.22 303.375 1851.47 303.808C1854.94 304.242 1858.52 304.459 1862.2 304.459C1872.39 304.459 1880.41 301.423 1886.27 295.353C1892.34 289.499 1897 282.127 1900.25 273.238L1905.46 258.928L1834.88 83.3091Z"
        fill={primaryColor}
      />
      <path
        d="M201.484 140.491C201.484 179.734 190.535 209.329 168.637 229.276C146.739 249.006 116.168 258.871 76.9249 258.871H11.5557V26.6637H83.7545C107.604 26.6637 128.31 31 145.872 39.6726C163.65 48.3451 177.31 61.1371 186.849 78.0485C196.606 94.7432 201.484 115.557 201.484 140.491ZM157.905 141.792C157.905 114.04 151.509 93.6591 138.717 80.6503C126.142 67.6415 107.821 61.1371 83.7545 61.1371H53.1838V224.072H78.2258C131.345 224.072 157.905 196.645 157.905 141.792Z"
        fill={primaryColor}
      />
      <path
        d="M318.02 79.3494C341.652 79.3494 360.298 86.3959 373.958 100.489C387.834 114.582 394.772 134.203 394.772 159.354V180.168H278.343C278.777 196.212 283.221 208.679 291.677 217.568C300.133 226.241 311.841 230.577 326.801 230.577C338.075 230.577 348.265 229.493 357.371 227.325C366.478 224.94 375.801 221.579 385.34 217.243V250.09C376.668 254.209 367.67 257.245 358.347 259.196C349.024 261.147 337.858 262.123 324.849 262.123C307.721 262.123 292.436 258.871 278.993 252.367C265.768 245.645 255.361 235.563 247.772 222.121C240.401 208.679 236.715 191.984 236.715 172.037C236.715 141.9 244.195 118.918 259.155 103.09C274.115 87.2631 293.737 79.3494 318.02 79.3494ZM318.02 109.595C306.962 109.595 297.965 113.172 291.026 120.327C284.305 127.265 280.403 137.672 279.319 151.548H355.095C354.878 139.407 351.734 129.433 345.663 121.628C339.81 113.606 330.595 109.595 318.02 109.595Z"
        fill={primaryColor}
      />
      <path
        d="M556.099 207.811C556.099 225.59 549.703 239.141 536.911 248.464C524.336 257.57 506.232 262.123 482.599 262.123C470.241 262.123 459.617 261.256 450.727 259.521C442.055 257.787 433.599 255.077 425.36 251.391V216.592C434.033 220.712 443.573 224.181 453.98 226.999C464.603 229.818 474.577 231.227 483.9 231.227C495.174 231.227 503.305 229.493 508.291 226.024C513.278 222.555 515.771 217.893 515.771 212.039C515.771 208.57 514.796 205.535 512.844 202.933C510.893 200.115 506.99 197.079 501.136 193.827C495.499 190.575 487.044 186.564 475.769 181.794C464.495 177.458 455.064 173.013 447.475 168.46C440.104 163.69 434.466 158.161 430.564 151.874C426.878 145.586 425.035 137.455 425.035 127.482C425.035 111.655 431.214 99.7299 443.573 91.7078C456.148 83.4689 472.842 79.3494 493.656 79.3494C504.497 79.3494 514.687 80.4335 524.227 82.6016C533.984 84.7697 543.74 88.022 553.497 92.3582L540.488 122.604C532.249 119.135 524.227 116.208 516.422 113.823C508.617 111.438 500.594 110.245 492.356 110.245C474.36 110.245 465.362 115.34 465.362 125.531C465.362 129.217 466.446 132.36 468.614 134.962C470.999 137.564 475.01 140.382 480.648 143.418C486.502 146.236 494.741 149.705 505.364 153.825C515.771 157.944 524.769 162.281 532.358 166.834C539.946 171.17 545.8 176.59 549.92 183.095C554.039 189.599 556.099 197.838 556.099 207.811Z"
        fill={primaryColor}
      />
      <path
        d="M616.015 14.6306C622.303 14.6306 627.615 16.2567 631.951 19.5089C636.504 22.5443 638.781 28.073 638.781 36.0951C638.781 44.1172 636.504 49.7544 631.951 53.0066C627.615 56.2588 622.303 57.8849 616.015 57.8849C609.728 57.8849 604.307 56.2588 599.754 53.0066C595.418 49.7544 593.25 44.1172 593.25 36.0951C593.25 28.073 595.418 22.5443 599.754 19.5089C604.307 16.2567 609.728 14.6306 616.015 14.6306ZM636.504 82.6016V258.871H595.527V82.6016H636.504Z"
        fill={primaryColor}
      />
      <path
        d="M748.09 79.3494C770.422 79.3494 787.876 88.0219 800.451 105.367H802.077L805.98 82.6016H840.128V260.172C840.128 285.322 832.973 304.402 818.663 317.411C804.57 330.419 783.431 336.924 755.245 336.924C742.67 336.924 731.07 336.057 720.447 334.322C709.823 332.804 699.741 330.094 690.201 326.192V292.369C710.365 301.475 732.913 306.028 757.847 306.028C771.289 306.028 781.48 302.234 788.418 294.645C795.573 287.274 799.15 276.433 799.15 262.123V257.57C799.15 254.318 799.258 250.524 799.475 246.187C799.692 241.851 800.017 238.382 800.451 235.78H799.15C793.079 244.886 785.599 251.608 776.71 255.944C768.037 260.063 758.172 262.123 747.115 262.123C725.65 262.123 708.847 254.101 696.706 238.057C684.564 222.013 678.493 199.681 678.493 171.061C678.493 142.442 684.564 120.002 696.706 103.741C709.064 87.4799 726.192 79.3494 748.09 79.3494ZM758.823 112.847C733.239 112.847 720.447 132.469 720.447 171.712C720.447 210.305 733.455 229.601 759.473 229.601C773.783 229.601 784.298 225.698 791.019 217.893C797.958 209.871 801.427 196.429 801.427 177.566V171.387C801.427 150.573 797.958 135.613 791.019 126.506C784.298 117.4 773.566 112.847 758.823 112.847Z"
        fill={primaryColor}
      />
      <path
        d="M986.794 79.3494C1006.52 79.3494 1021.92 84.4445 1032.98 94.6348C1044.25 104.825 1049.89 121.303 1049.89 144.068V258.871H1008.91V152.199C1008.91 125.747 998.069 112.522 976.387 112.522C959.909 112.522 948.635 117.725 942.564 128.132C936.494 138.539 933.458 153.391 933.458 172.688V258.871H892.481V82.6016H924.027L929.881 105.692H932.157C937.795 96.5861 945.491 89.9733 955.248 85.8538C965.221 81.5175 975.737 79.3494 986.794 79.3494Z"
        fill={primaryColor}
      />
      <path
        d="M1280.01 26.6637L1199.68 258.871H1155.13L1074.8 26.6637H1117.4L1164.23 168.785C1165.54 172.688 1167.05 177.783 1168.79 184.07C1170.52 190.141 1172.15 196.429 1173.67 202.933C1175.4 209.437 1176.7 215.075 1177.57 219.845C1178.22 215.075 1179.3 209.437 1180.82 202.933C1182.34 196.429 1183.96 190.141 1185.7 184.07C1187.43 177.783 1189.06 172.688 1190.58 168.785L1237.41 26.6637H1280.01Z"
        fill={primaryColor}
      />
      <path
        d="M1370.21 79.3494C1392.97 79.3494 1410.21 84.3361 1421.92 94.3095C1433.84 104.283 1439.8 119.677 1439.8 140.491V258.871H1410.86L1402.73 234.479H1401.43C1393.84 244.019 1385.82 251.066 1377.36 255.619C1368.91 259.955 1357.41 262.123 1342.89 262.123C1327.06 262.123 1313.94 257.678 1303.54 248.789C1293.13 239.9 1287.93 226.132 1287.93 207.486C1287.93 189.274 1294.54 175.615 1307.76 166.508C1321.21 157.402 1341.48 152.307 1368.58 151.223L1399.15 150.247V141.466C1399.15 130.409 1396.44 122.495 1391.02 117.725C1385.6 112.739 1378.01 110.245 1368.25 110.245C1359.37 110.245 1350.8 111.546 1342.56 114.148C1334.54 116.75 1326.52 119.785 1318.5 123.254L1305.49 94.6348C1314.16 90.0817 1324.02 86.3959 1335.08 83.5773C1346.36 80.7587 1358.06 79.3494 1370.21 79.3494ZM1399.15 175.615L1377.69 176.265C1359.69 176.915 1347.22 180.059 1340.29 185.696C1333.35 191.117 1329.88 198.488 1329.88 207.811C1329.88 216.267 1332.26 222.338 1337.03 226.024C1342.02 229.493 1348.52 231.227 1356.55 231.227C1368.47 231.227 1378.55 227.867 1386.79 221.145C1395.03 214.207 1399.15 204.017 1399.15 190.575V175.615Z"
        fill={primaryColor}
      />
      <path
        d="M1532.13 258.871H1491.15V11.7036H1532.13V258.871Z"
        fill={primaryColor}
      />
      <path
        d="M1625.5 258.871H1584.53V11.7036H1625.5V258.871Z"
        fill={primaryColor}
      />
      <path
        d="M1748.8 79.3494C1772.43 79.3494 1791.08 86.3959 1804.74 100.489C1818.61 114.582 1825.55 134.203 1825.55 159.354V180.168H1709.12C1709.55 196.212 1714 208.679 1722.45 217.568C1730.91 226.241 1742.62 230.577 1757.58 230.577C1768.85 230.577 1779.04 229.493 1788.15 227.325C1797.26 224.94 1806.58 221.579 1816.12 217.243V250.09C1807.45 254.209 1798.45 257.245 1789.13 259.196C1779.8 261.147 1768.64 262.123 1755.63 262.123C1738.5 262.123 1723.21 258.871 1709.77 252.367C1696.55 245.645 1686.14 235.563 1678.55 222.121C1671.18 208.679 1667.49 191.984 1667.49 172.037C1667.49 141.9 1674.97 118.918 1689.93 103.09C1704.89 87.2631 1724.51 79.3494 1748.8 79.3494ZM1748.8 109.595C1737.74 109.595 1728.74 113.172 1721.8 120.327C1715.08 127.265 1711.18 137.672 1710.1 151.548H1785.87C1785.66 139.407 1782.51 129.433 1776.44 121.628C1770.59 113.606 1761.37 109.595 1748.8 109.595Z"
        fill={primaryColor}
      />
      <path
        d="M1834.18 82.6016H1878.73L1913.85 182.119C1916.24 188.407 1918.19 194.586 1919.71 200.657C1921.23 206.727 1922.31 212.907 1922.96 219.194H1924.26C1925.13 213.774 1926.43 207.811 1928.16 201.307C1929.9 194.803 1931.85 188.407 1934.02 182.119L1967.84 82.6016H2012.07L1936.95 282.287C1930.22 299.849 1921.01 313.291 1909.3 322.614C1897.81 332.154 1883.61 336.924 1866.7 336.924C1861.28 336.924 1856.62 336.599 1852.71 335.948C1848.81 335.514 1845.34 334.972 1842.31 334.322V301.8C1844.69 302.234 1847.51 302.667 1850.76 303.101C1854.23 303.535 1857.81 303.751 1861.49 303.751C1871.68 303.751 1879.71 300.716 1885.56 294.645C1891.63 288.791 1896.29 281.42 1899.55 272.53L1904.75 258.22L1834.18 82.6016Z"
        fill={primaryColor}
      />
      <path
        d="M200.777 139.784C200.777 179.027 189.828 208.622 167.93 228.569C146.032 248.299 115.461 258.164 76.2179 258.164H10.8486V25.9567H83.0475C106.897 25.9567 127.603 30.293 145.165 38.9655C162.943 47.6381 176.603 60.4301 186.142 77.3415C195.899 94.0361 200.777 114.85 200.777 139.784ZM157.198 141.085C157.198 113.333 150.802 92.9521 138.01 79.9433C125.435 66.9345 107.114 60.43 83.0475 60.43H52.4768V223.365H77.5188C130.638 223.365 157.198 195.938 157.198 141.085Z"
        fill={primaryColor}
      />
      <path
        d="M317.313 78.6424C340.945 78.6424 359.591 85.6888 373.251 99.7817C387.127 113.875 394.065 133.496 394.065 158.647V179.461H277.636C278.069 195.505 282.514 207.972 290.97 216.861C299.426 225.533 311.134 229.87 326.094 229.87C337.368 229.87 347.558 228.786 356.664 226.618C365.771 224.233 375.094 220.872 384.633 216.536V249.383C375.961 253.502 366.963 256.538 357.64 258.489C348.317 260.44 337.151 261.416 324.142 261.416C307.014 261.416 291.729 258.164 278.286 251.66C265.061 244.938 254.654 234.856 247.065 221.414C239.694 207.972 236.008 191.277 236.008 171.33C236.008 141.193 243.488 118.211 258.448 102.383C273.408 86.5561 293.03 78.6424 317.313 78.6424ZM317.313 108.888C306.255 108.888 297.257 112.465 290.319 119.62C283.598 126.558 279.696 136.965 278.612 150.841H354.388C354.171 138.7 351.027 128.726 344.956 120.921C339.102 112.899 329.888 108.888 317.313 108.888Z"
        fill={primaryColor}
      />
      <path
        d="M555.392 207.104C555.392 224.883 548.996 238.434 536.204 247.757C523.628 256.863 505.525 261.416 481.892 261.416C469.534 261.416 458.91 260.549 450.02 258.814C441.348 257.08 432.892 254.37 424.653 250.684V215.885C433.326 220.005 442.866 223.474 453.273 226.292C463.896 229.111 473.87 230.52 483.193 230.52C494.467 230.52 502.598 228.786 507.584 225.317C512.571 221.848 515.064 217.186 515.064 211.332C515.064 207.863 514.089 204.828 512.137 202.226C510.186 199.407 506.283 196.372 500.429 193.12C494.792 189.868 486.337 185.857 475.062 181.087C463.788 176.75 454.357 172.306 446.768 167.753C439.396 162.983 433.759 157.454 429.857 151.166C426.171 144.879 424.328 136.748 424.328 126.775C424.328 110.948 430.507 99.0228 442.866 91.0008C455.441 82.7618 472.135 78.6424 492.949 78.6424C503.79 78.6424 513.98 79.7264 523.52 81.8946C533.277 84.0627 543.033 87.3149 552.79 91.6512L539.781 121.897C531.542 118.428 523.52 115.501 515.715 113.116C507.91 110.731 499.887 109.538 491.649 109.538C473.653 109.538 464.655 114.633 464.655 124.824C464.655 128.509 465.739 131.653 467.907 134.255C470.292 136.857 474.303 139.675 479.941 142.711C485.795 145.529 494.033 148.998 504.657 153.118C515.064 157.237 524.062 161.574 531.651 166.127C539.239 170.463 545.093 175.883 549.213 182.388C553.332 188.892 555.392 197.131 555.392 207.104Z"
        fill={primaryColor}
      />
      <path
        d="M615.308 13.9236C621.596 13.9236 626.908 15.5497 631.244 18.8019C635.797 21.8373 638.074 27.366 638.074 35.3881C638.074 43.4102 635.797 49.0474 631.244 52.2996C626.908 55.5518 621.596 57.1778 615.308 57.1778C609.021 57.1778 603.6 55.5518 599.047 52.2996C594.711 49.0474 592.543 43.4102 592.543 35.3881C592.543 27.366 594.711 21.8373 599.047 18.8019C603.6 15.5497 609.021 13.9236 615.308 13.9236ZM635.797 81.8946V258.164H594.82V81.8946H635.797Z"
        fill={primaryColor}
      />
      <path
        d="M747.383 78.6424C769.715 78.6424 787.169 87.3149 799.744 104.66H801.37L805.273 81.8946H839.421V259.465C839.421 284.615 832.266 303.695 817.956 316.704C803.863 329.712 782.724 336.217 754.538 336.217C741.963 336.217 730.363 335.349 719.74 333.615C709.116 332.097 699.034 329.387 689.494 325.484V291.662C709.658 300.768 732.206 305.321 757.14 305.321C770.582 305.321 780.773 301.527 787.711 293.938C794.866 286.566 798.443 275.726 798.443 261.416V256.863C798.443 253.611 798.551 249.817 798.768 245.48C798.985 241.144 799.31 237.675 799.744 235.073H798.443C792.372 244.179 784.892 250.901 776.003 255.237C767.33 259.356 757.465 261.416 746.408 261.416C724.943 261.416 708.14 253.394 695.999 237.35C683.857 221.306 677.786 198.974 677.786 170.354C677.786 141.735 683.857 119.295 695.999 103.034C708.357 86.7729 725.485 78.6424 747.383 78.6424ZM758.116 112.14C732.532 112.14 719.74 131.762 719.74 171.005C719.74 209.598 732.748 228.894 758.766 228.894C773.076 228.894 783.591 224.991 790.312 217.186C797.25 209.164 800.719 195.722 800.719 176.859V170.68C800.719 149.866 797.25 134.905 790.312 125.799C783.591 116.693 772.859 112.14 758.116 112.14Z"
        fill={primaryColor}
      />
      <path
        d="M986.087 78.6424C1005.82 78.6424 1021.21 83.7375 1032.27 93.9277C1043.54 104.118 1049.18 120.596 1049.18 143.361V258.164H1008.2V151.492C1008.2 125.04 997.362 111.815 975.68 111.815C959.202 111.815 947.928 117.018 941.857 127.425C935.787 137.832 932.751 152.684 932.751 171.981V258.164H891.773V81.8946H923.32L929.174 104.985H931.45C937.088 95.8791 944.784 89.2663 954.541 85.1468C964.514 80.8105 975.03 78.6424 986.087 78.6424Z"
        fill={primaryColor}
      />
      <path
        d="M1279.31 25.9567L1198.98 258.164H1154.42L1074.09 25.9567H1116.7L1163.53 168.078C1164.83 171.981 1166.35 177.076 1168.08 183.363C1169.82 189.434 1171.44 195.722 1172.96 202.226C1174.69 208.73 1175.99 214.368 1176.86 219.137C1177.51 214.368 1178.6 208.73 1180.11 202.226C1181.63 195.722 1183.26 189.434 1184.99 183.363C1186.73 177.076 1188.35 171.981 1189.87 168.078L1236.7 25.9567H1279.31Z"
        fill={primaryColor}
      />
      <path
        d="M1369.5 78.6424C1392.26 78.6424 1409.5 83.6291 1421.21 93.6025C1433.13 103.576 1439.1 118.97 1439.1 139.784V258.164H1410.15L1402.02 233.772H1400.72C1393.13 243.312 1385.11 250.359 1376.65 254.912C1368.2 259.248 1356.71 261.416 1342.18 261.416C1326.35 261.416 1313.24 256.971 1302.83 248.082C1292.42 239.193 1287.22 225.425 1287.22 206.779C1287.22 188.567 1293.83 174.908 1307.06 165.801C1320.5 156.695 1340.77 151.6 1367.87 150.516L1398.44 149.54V140.759C1398.44 129.702 1395.73 121.788 1390.31 117.018C1384.89 112.032 1377.3 109.538 1367.55 109.538C1358.66 109.538 1350.09 110.839 1341.86 113.441C1333.83 116.043 1325.81 119.078 1317.79 122.547L1304.78 93.9277C1313.45 89.3747 1323.32 85.6888 1334.38 82.8702C1345.65 80.0517 1357.36 78.6424 1369.5 78.6424ZM1398.44 174.908L1376.98 175.558C1358.98 176.208 1346.52 179.352 1339.58 184.989C1332.64 190.41 1329.17 197.781 1329.17 207.104C1329.17 215.56 1331.56 221.631 1336.33 225.317C1341.31 228.786 1347.82 230.52 1355.84 230.52C1367.76 230.52 1377.85 227.16 1386.09 220.438C1394.32 213.5 1398.44 203.31 1398.44 189.868V174.908Z"
        fill={primaryColor}
      />
      <path
        d="M1531.42 258.164H1490.45V10.9966H1531.42V258.164Z"
        fill={primaryColor}
      />
      <path
        d="M1624.8 258.164H1583.82V10.9966H1624.8V258.164Z"
        fill={primaryColor}
      />
      <path
        d="M1748.09 78.6424C1771.72 78.6424 1790.37 85.6888 1804.03 99.7817C1817.9 113.875 1824.84 133.496 1824.84 158.647V179.461H1708.41C1708.85 195.505 1713.29 207.972 1721.75 216.861C1730.2 225.533 1741.91 229.87 1756.87 229.87C1768.15 229.87 1778.34 228.786 1787.44 226.618C1796.55 224.233 1805.87 220.872 1815.41 216.536V249.383C1806.74 253.502 1797.74 256.538 1788.42 258.489C1779.1 260.44 1767.93 261.416 1754.92 261.416C1737.79 261.416 1722.51 258.164 1709.06 251.66C1695.84 244.938 1685.43 234.856 1677.84 221.414C1670.47 207.972 1666.79 191.277 1666.79 171.33C1666.79 141.193 1674.27 118.211 1689.23 102.383C1704.19 86.5561 1723.81 78.6424 1748.09 78.6424ZM1748.09 108.888C1737.03 108.888 1728.04 112.465 1721.1 119.62C1714.38 126.558 1710.47 136.965 1709.39 150.841H1785.17C1784.95 138.7 1781.81 128.726 1775.73 120.921C1769.88 112.899 1760.67 108.888 1748.09 108.888Z"
        fill={primaryColor}
      />
      <path
        d="M1833.47 81.8946H1878.02L1913.15 181.412C1915.53 187.7 1917.48 193.879 1919 199.949C1920.52 206.02 1921.6 212.199 1922.25 218.487H1923.55C1924.42 213.067 1925.72 207.104 1927.46 200.6C1929.19 194.096 1931.14 187.7 1933.31 181.412L1967.13 81.8946H2011.36L1936.24 281.58C1929.52 299.142 1920.3 312.584 1908.59 321.907C1897.1 331.447 1882.9 336.217 1865.99 336.217C1860.57 336.217 1855.91 335.892 1852.01 335.241C1848.1 334.807 1844.63 334.265 1841.6 333.615V301.093C1843.98 301.527 1846.8 301.96 1850.05 302.394C1853.52 302.827 1857.1 303.044 1860.79 303.044C1870.98 303.044 1879 300.009 1884.85 293.938C1890.92 288.084 1895.59 280.712 1898.84 271.823L1904.04 257.513L1833.47 81.8946Z"
        fill={primaryColor}
      />
      <path
        d="M200.07 139.077C200.07 178.32 189.121 207.915 167.223 227.862C145.325 247.592 114.754 257.457 75.5108 257.457H10.1416V25.2497H82.3405C106.19 25.2497 126.896 29.5859 144.458 38.2585C162.236 46.931 175.895 59.723 185.435 76.6345C195.192 93.3291 200.07 114.143 200.07 139.077ZM156.491 140.378C156.491 112.626 150.095 92.245 137.303 79.2362C124.728 66.2274 106.407 59.723 82.3405 59.723H51.7698V222.658H76.8117C129.931 222.658 156.491 195.231 156.491 140.378Z"
        fill={primaryColor}
      />
      <path
        d="M316.606 77.9353C340.238 77.9353 358.884 84.9818 372.544 99.0747C386.42 113.168 393.358 132.789 393.358 157.94V178.754H276.929C277.362 194.798 281.807 207.265 290.263 216.154C298.719 224.826 310.427 229.163 325.387 229.163C336.661 229.163 346.851 228.079 355.957 225.911C365.064 223.526 374.386 220.165 383.926 215.829V248.676C375.254 252.795 366.256 255.831 356.933 257.782C347.61 259.733 336.444 260.709 323.435 260.709C306.307 260.709 291.022 257.457 277.579 250.952C264.354 244.231 253.947 234.149 246.358 220.707C238.986 207.265 235.301 190.57 235.301 170.623C235.301 140.486 242.781 117.504 257.741 101.676C272.701 85.849 292.323 77.9353 316.606 77.9353ZM316.606 108.181C305.548 108.181 296.55 111.758 289.612 118.913C282.891 125.851 278.989 136.258 277.905 150.134H353.681C353.464 137.993 350.32 128.019 344.249 120.214C338.395 112.192 329.181 108.181 316.606 108.181Z"
        fill={primaryColor}
      />
      <path
        d="M554.685 206.397C554.685 224.176 548.289 237.727 535.497 247.05C522.921 256.156 504.818 260.709 481.185 260.709C468.827 260.709 458.203 259.842 449.313 258.107C440.641 256.373 432.185 253.663 423.946 249.977V215.178C432.619 219.298 442.158 222.767 452.566 225.585C463.189 228.404 473.163 229.813 482.486 229.813C493.76 229.813 501.891 228.079 506.877 224.61C511.864 221.141 514.357 216.479 514.357 210.625C514.357 207.156 513.382 204.121 511.43 201.519C509.479 198.7 505.576 195.665 499.722 192.413C494.085 189.161 485.63 185.15 474.355 180.38C463.081 176.043 453.65 171.599 446.061 167.046C438.689 162.276 433.052 156.747 429.15 150.459C425.464 144.172 423.621 136.041 423.621 126.068C423.621 110.241 429.8 98.3158 442.158 90.2937C454.734 82.0548 471.428 77.9353 492.242 77.9353C503.083 77.9353 513.273 79.0194 522.813 81.1875C532.57 83.3557 542.326 86.6079 552.083 90.9442L539.074 121.19C530.835 117.721 522.813 114.794 515.008 112.409C507.203 110.024 499.18 108.831 490.941 108.831C472.946 108.831 463.948 113.926 463.948 124.117C463.948 127.802 465.032 130.946 467.2 133.548C469.585 136.15 473.596 138.968 479.234 142.004C485.088 144.822 493.326 148.291 503.95 152.411C514.357 156.53 523.355 160.867 530.944 165.42C538.532 169.756 544.386 175.176 548.505 181.681C552.625 188.185 554.685 196.424 554.685 206.397Z"
        fill={primaryColor}
      />
      <path
        d="M614.601 13.2165C620.889 13.2165 626.201 14.8426 630.537 18.0948C635.09 21.1302 637.367 26.659 637.367 34.6811C637.367 42.7032 635.09 48.3403 630.537 51.5925C626.201 54.8447 620.889 56.4708 614.601 56.4708C608.314 56.4708 602.893 54.8447 598.34 51.5925C594.004 48.3403 591.836 42.7032 591.836 34.6811C591.836 26.659 594.004 21.1302 598.34 18.0948C602.893 14.8426 608.314 13.2165 614.601 13.2165ZM635.09 81.1875V257.457H594.112V81.1875H635.09Z"
        fill={primaryColor}
      />
      <path
        d="M746.676 77.9353C769.008 77.9353 786.462 86.6079 799.037 103.953H800.663L804.566 81.1875H838.714V258.758C838.714 283.908 831.559 302.988 817.249 315.997C803.156 329.005 782.017 335.51 753.831 335.51C741.256 335.51 729.656 334.642 719.033 332.908C708.409 331.39 698.327 328.68 688.787 324.777V290.955C708.951 300.061 731.499 304.614 756.433 304.614C769.875 304.614 780.066 300.82 787.004 293.231C794.159 285.859 797.736 275.019 797.736 260.709V256.156C797.736 252.904 797.844 249.11 798.061 244.773C798.278 240.437 798.603 236.968 799.037 234.366H797.736C791.665 243.472 784.185 250.194 775.296 254.53C766.623 258.649 756.758 260.709 745.701 260.709C724.236 260.709 707.433 252.687 695.292 236.643C683.15 220.599 677.079 198.267 677.079 169.647C677.079 141.028 683.15 118.588 695.292 102.327C707.65 86.0659 724.778 77.9353 746.676 77.9353ZM757.409 111.433C731.825 111.433 719.033 131.055 719.033 170.298C719.033 208.891 732.041 228.187 758.059 228.187C772.369 228.187 782.884 224.284 789.605 216.479C796.543 208.457 800.012 195.015 800.012 176.152V169.973C800.012 149.159 796.543 134.198 789.605 125.092C782.884 115.986 772.152 111.433 757.409 111.433Z"
        fill={primaryColor}
      />
      <path
        d="M985.38 77.9353C1005.11 77.9353 1020.5 83.0305 1031.56 93.2207C1042.84 103.411 1048.47 119.889 1048.47 142.654V257.457H1007.5V150.785C1007.5 124.333 996.655 111.108 974.973 111.108C958.495 111.108 947.221 116.311 941.15 126.718C935.08 137.125 932.044 151.977 932.044 171.274V257.457H891.066V81.1875H922.613L928.467 104.278H930.743C936.38 95.172 944.077 88.5592 953.834 84.4398C963.807 80.1035 974.323 77.9353 985.38 77.9353Z"
        fill={primaryColor}
      />
      <path
        d="M1278.6 25.2497L1198.27 257.457H1153.71L1073.39 25.2497H1115.99L1162.82 167.371C1164.12 171.274 1165.64 176.369 1167.37 182.656C1169.11 188.727 1170.73 195.015 1172.25 201.519C1173.99 208.023 1175.29 213.661 1176.15 218.43C1176.81 213.661 1177.89 208.023 1179.41 201.519C1180.92 195.015 1182.55 188.727 1184.29 182.656C1186.02 176.369 1187.65 171.274 1189.16 167.371L1236 25.2497H1278.6Z"
        fill={primaryColor}
      />
      <path
        d="M1368.79 77.9353C1391.56 77.9353 1408.79 82.9221 1420.5 92.8955C1432.43 102.869 1438.39 118.263 1438.39 139.077V257.457H1409.44L1401.31 233.065H1400.01C1392.42 242.605 1384.4 249.652 1375.95 254.205C1367.49 258.541 1356 260.709 1341.47 260.709C1325.65 260.709 1312.53 256.264 1302.12 247.375C1291.71 238.486 1286.51 224.718 1286.51 206.072C1286.51 187.86 1293.12 174.201 1306.35 165.094C1319.79 155.988 1340.06 150.893 1367.17 149.809L1397.74 148.833V140.052C1397.74 128.995 1395.03 121.081 1389.61 116.311C1384.19 111.325 1376.6 108.831 1366.84 108.831C1357.95 108.831 1349.39 110.132 1341.15 112.734C1333.13 115.336 1325.1 118.371 1317.08 121.84L1304.07 93.2207C1312.75 88.6676 1322.61 84.9818 1333.67 82.1632C1344.94 79.3446 1356.65 77.9353 1368.79 77.9353ZM1397.74 174.201L1376.27 174.851C1358.28 175.501 1345.81 178.645 1338.87 184.282C1331.93 189.703 1328.46 197.074 1328.46 206.397C1328.46 214.853 1330.85 220.924 1335.62 224.61C1340.61 228.079 1347.11 229.813 1355.13 229.813C1367.06 229.813 1377.14 226.453 1385.38 219.731C1393.62 212.793 1397.74 202.603 1397.74 189.161V174.201Z"
        fill={primaryColor}
      />
      <path
        d="M1530.72 257.457H1489.74V10.2896H1530.72V257.457Z"
        fill={primaryColor}
      />
      <path
        d="M1624.09 257.457H1583.11V10.2896H1624.09V257.457Z"
        fill={primaryColor}
      />
      <path
        d="M1747.38 77.9353C1771.02 77.9353 1789.66 84.9818 1803.32 99.0747C1817.2 113.168 1824.14 132.789 1824.14 157.94V178.754H1707.71C1708.14 194.798 1712.59 207.265 1721.04 216.154C1729.5 224.826 1741.2 229.163 1756.16 229.163C1767.44 229.163 1777.63 228.079 1786.74 225.911C1795.84 223.526 1805.16 220.165 1814.7 215.829V248.676C1806.03 252.795 1797.03 255.831 1787.71 257.782C1778.39 259.733 1767.22 260.709 1754.21 260.709C1737.09 260.709 1721.8 257.457 1708.36 250.952C1695.13 244.231 1684.72 234.149 1677.14 220.707C1669.76 207.265 1666.08 190.57 1666.08 170.623C1666.08 140.486 1673.56 117.504 1688.52 101.676C1703.48 85.849 1723.1 77.9353 1747.38 77.9353ZM1747.38 108.181C1736.33 108.181 1727.33 111.758 1720.39 118.913C1713.67 125.851 1709.77 136.258 1708.68 150.134H1784.46C1784.24 137.993 1781.1 128.019 1775.03 120.214C1769.17 112.192 1759.96 108.181 1747.38 108.181Z"
        fill={primaryColor}
      />
      <path
        d="M1832.76 81.1875H1877.32L1912.44 180.705C1914.83 186.993 1916.78 193.172 1918.29 199.242C1919.81 205.313 1920.9 211.492 1921.55 217.78H1922.85C1923.71 212.36 1925.02 206.397 1926.75 199.893C1928.48 193.389 1930.44 186.993 1932.6 180.705L1966.43 81.1875H2010.66L1935.53 280.873C1928.81 298.435 1919.6 311.877 1907.89 321.2C1896.4 330.74 1882.2 335.51 1865.28 335.51C1859.86 335.51 1855.2 335.185 1851.3 334.534C1847.4 334.1 1843.93 333.558 1840.89 332.908V300.386C1843.28 300.82 1846.1 301.253 1849.35 301.687C1852.82 302.12 1856.39 302.337 1860.08 302.337C1870.27 302.337 1878.29 299.302 1884.15 293.231C1890.22 287.377 1894.88 280.005 1898.13 271.116L1903.33 256.806L1832.76 81.1875Z"
        fill={primaryColor}
      />
      <path
        d="M199.363 138.37C199.363 177.613 188.414 207.208 166.516 227.155C144.618 246.885 114.047 256.75 74.8038 256.75H9.43457V24.5426H81.6335C105.483 24.5426 126.189 28.8789 143.751 37.5515C161.529 46.224 175.188 59.016 184.728 75.9274C194.485 92.6221 199.363 113.436 199.363 138.37ZM155.784 139.671C155.784 111.918 149.388 91.538 136.596 78.5292C124.02 65.5204 105.7 59.016 81.6335 59.016H51.0628V221.951H76.1047C129.224 221.951 155.784 194.524 155.784 139.671Z"
        fill={primaryColor}
      />
      <path
        d="M315.899 77.2283C339.531 77.2283 358.177 84.2748 371.837 98.3676C385.713 112.461 392.651 132.082 392.651 157.232V178.047H276.222C276.655 194.091 281.1 206.558 289.556 215.447C298.012 224.119 309.719 228.456 324.68 228.456C335.954 228.456 346.144 227.372 355.25 225.203C364.356 222.819 373.679 219.458 383.219 215.122V247.969C374.547 252.088 365.549 255.124 356.226 257.075C346.903 259.026 335.737 260.002 322.728 260.002C305.6 260.002 290.315 256.75 276.872 250.245C263.647 243.524 253.24 233.442 245.651 220C238.279 206.558 234.594 189.863 234.594 169.916C234.594 139.779 242.074 116.797 257.034 100.969C271.994 85.142 291.616 77.2283 315.899 77.2283ZM315.899 107.474C304.841 107.474 295.843 111.051 288.905 118.206C282.184 125.144 278.282 135.551 277.197 149.427H352.974C352.757 137.286 349.613 127.312 343.542 119.507C337.688 111.485 328.474 107.474 315.899 107.474Z"
        fill={primaryColor}
      />
      <path
        d="M553.978 205.69C553.978 223.469 547.582 237.02 534.79 246.343C522.214 255.449 504.111 260.002 480.478 260.002C468.119 260.002 457.496 259.135 448.606 257.4C439.934 255.666 431.478 252.956 423.239 249.27V214.471C431.912 218.591 441.451 222.06 451.858 224.878C462.482 227.697 472.456 229.106 481.779 229.106C493.053 229.106 501.184 227.372 506.17 223.903C511.157 220.434 513.65 215.772 513.65 209.918C513.65 206.449 512.675 203.414 510.723 200.812C508.772 197.993 504.869 194.958 499.015 191.706C493.378 188.454 484.923 184.443 473.648 179.673C462.374 175.336 452.943 170.892 445.354 166.339C437.982 161.569 432.345 156.04 428.443 149.752C424.757 143.465 422.914 135.334 422.914 125.361C422.914 109.534 429.093 97.6088 441.451 89.5867C454.027 81.3478 470.721 77.2283 491.535 77.2283C502.376 77.2283 512.566 78.3124 522.106 80.4805C531.863 82.6487 541.619 85.9009 551.376 90.2371L538.367 120.483C530.128 117.014 522.106 114.087 514.301 111.702C506.496 109.317 498.473 108.124 490.234 108.124C472.239 108.124 463.241 113.219 463.241 123.41C463.241 127.095 464.325 130.239 466.493 132.841C468.878 135.443 472.889 138.261 478.527 141.297C484.381 144.115 492.619 147.584 503.243 151.704C513.65 155.823 522.648 160.159 530.237 164.713C537.825 169.049 543.679 174.469 547.798 180.974C551.918 187.478 553.978 195.717 553.978 205.69Z"
        fill={primaryColor}
      />
      <path
        d="M613.894 12.5095C620.182 12.5095 625.494 14.1356 629.83 17.3878C634.383 20.4232 636.66 25.9519 636.66 33.974C636.66 41.9961 634.383 47.6333 629.83 50.8855C625.494 54.1377 620.182 55.7638 613.894 55.7638C607.607 55.7638 602.186 54.1377 597.633 50.8855C593.297 47.6333 591.129 41.9961 591.129 33.974C591.129 25.9519 593.297 20.4232 597.633 17.3878C602.186 14.1356 607.607 12.5095 613.894 12.5095ZM634.383 80.4805V256.75H593.405V80.4805H634.383Z"
        fill={primaryColor}
      />
      <path
        d="M745.969 77.2283C768.301 77.2283 785.755 85.9009 798.33 103.246H799.956L803.859 80.4805H838.007V258.051C838.007 283.201 830.852 302.281 816.542 315.289C802.449 328.298 781.31 334.803 753.124 334.803C740.549 334.803 728.949 333.935 718.326 332.201C707.702 330.683 697.62 327.973 688.08 324.07V290.248C708.244 299.354 730.792 303.907 755.726 303.907C769.168 303.907 779.359 300.113 786.297 292.524C793.451 285.152 797.029 274.312 797.029 260.002V255.449C797.029 252.197 797.137 248.403 797.354 244.066C797.571 239.73 797.896 236.261 798.33 233.659H797.029C790.958 242.765 783.478 249.487 774.589 253.823C765.916 257.942 756.051 260.002 744.994 260.002C723.529 260.002 706.726 251.98 694.585 235.936C682.443 219.892 676.372 197.56 676.372 168.94C676.372 140.321 682.443 117.881 694.585 101.62C706.943 85.3588 724.071 77.2283 745.969 77.2283ZM756.702 110.726C731.118 110.726 718.326 130.348 718.326 169.591C718.326 208.184 731.334 227.48 757.352 227.48C771.662 227.48 782.177 223.577 788.898 215.772C795.836 207.75 799.305 194.308 799.305 175.445V169.266C799.305 148.452 795.836 133.491 788.898 124.385C782.177 115.279 771.445 110.726 756.702 110.726Z"
        fill={primaryColor}
      />
      <path
        d="M984.673 77.2283C1004.4 77.2283 1019.8 82.3234 1030.85 92.5137C1042.13 102.704 1047.77 119.182 1047.77 141.947V256.75H1006.79V150.078C1006.79 123.626 995.948 110.401 974.266 110.401C957.788 110.401 946.514 115.604 940.443 126.011C934.373 136.418 931.337 151.27 931.337 170.566V256.75H890.359V80.4805H921.906L927.76 103.571H930.036C935.673 94.465 943.37 87.8522 953.127 83.7327C963.1 79.3965 973.616 77.2283 984.673 77.2283Z"
        fill={primaryColor}
      />
      <path
        d="M1277.89 24.5426L1197.56 256.75H1153.01L1072.68 24.5426H1115.28L1162.11 166.664C1163.41 170.566 1164.93 175.662 1166.67 181.949C1168.4 188.02 1170.03 194.308 1171.55 200.812C1173.28 207.316 1174.58 212.954 1175.45 217.723C1176.1 212.954 1177.18 207.316 1178.7 200.812C1180.22 194.308 1181.84 188.02 1183.58 181.949C1185.31 175.662 1186.94 170.566 1188.46 166.664L1235.29 24.5426H1277.89Z"
        fill={primaryColor}
      />
      <path
        d="M1368.08 77.2283C1390.85 77.2283 1408.09 82.215 1419.79 92.1884C1431.72 102.162 1437.68 117.556 1437.68 138.37V256.75H1408.74L1400.61 232.358H1399.31C1391.72 241.898 1383.7 248.945 1375.24 253.498C1366.78 257.834 1355.29 260.002 1340.77 260.002C1324.94 260.002 1311.82 255.557 1301.41 246.668C1291.01 237.779 1285.8 224.011 1285.8 205.365C1285.8 187.153 1292.42 173.493 1305.64 164.387C1319.08 155.281 1339.36 150.186 1366.46 149.102L1397.03 148.126V139.345C1397.03 128.288 1394.32 120.374 1388.9 115.604C1383.48 110.618 1375.89 108.124 1366.13 108.124C1357.24 108.124 1348.68 109.425 1340.44 112.027C1332.42 114.629 1324.4 117.664 1316.37 121.133L1303.37 92.5137C1312.04 87.9606 1321.9 84.2748 1332.96 81.4562C1344.24 78.6376 1355.94 77.2283 1368.08 77.2283ZM1397.03 173.493L1375.56 174.144C1357.57 174.794 1345.1 177.938 1338.16 183.575C1331.23 188.996 1327.76 196.367 1327.76 205.69C1327.76 214.146 1330.14 220.217 1334.91 223.903C1339.9 227.372 1346.4 229.106 1354.43 229.106C1366.35 229.106 1376.43 225.746 1384.67 219.024C1392.91 212.086 1397.03 201.896 1397.03 188.454V173.493Z"
        fill={primaryColor}
      />
      <path
        d="M1530.01 256.75H1489.03V9.58252H1530.01V256.75Z"
        fill={primaryColor}
      />
      <path
        d="M1623.38 256.75H1582.4V9.58252H1623.38V256.75Z"
        fill={primaryColor}
      />
      <path
        d="M1746.68 77.2283C1770.31 77.2283 1788.96 84.2748 1802.61 98.3676C1816.49 112.461 1823.43 132.082 1823.43 157.232V178.047H1707C1707.43 194.091 1711.88 206.558 1720.33 215.447C1728.79 224.119 1740.5 228.456 1755.46 228.456C1766.73 228.456 1776.92 227.372 1786.03 225.203C1795.13 222.819 1804.46 219.458 1814 215.122V247.969C1805.32 252.088 1796.33 255.124 1787 257.075C1777.68 259.026 1766.52 260.002 1753.51 260.002C1736.38 260.002 1721.09 256.75 1707.65 250.245C1694.42 243.524 1684.02 233.442 1676.43 220C1669.06 206.558 1665.37 189.863 1665.37 169.916C1665.37 139.779 1672.85 116.797 1687.81 100.969C1702.77 85.142 1722.39 77.2283 1746.68 77.2283ZM1746.68 107.474C1735.62 107.474 1726.62 111.051 1719.68 118.206C1712.96 125.144 1709.06 135.551 1707.98 149.427H1783.75C1783.54 137.286 1780.39 127.312 1774.32 119.507C1768.47 111.485 1759.25 107.474 1746.68 107.474Z"
        fill={primaryColor}
      />
      <path
        d="M1832.05 80.4805H1876.61L1911.73 179.998C1914.12 186.285 1916.07 192.465 1917.59 198.535C1919.11 204.606 1920.19 210.785 1920.84 217.073H1922.14C1923.01 211.653 1924.31 205.69 1926.04 199.186C1927.78 192.681 1929.73 186.285 1931.9 179.998L1965.72 80.4805H2009.95L1934.82 280.166C1928.1 297.728 1918.89 311.17 1907.18 320.493C1895.69 330.033 1881.49 334.803 1864.58 334.803C1859.16 334.803 1854.49 334.477 1850.59 333.827C1846.69 333.393 1843.22 332.851 1840.19 332.201V299.679C1842.57 300.113 1845.39 300.546 1848.64 300.98C1852.11 301.413 1855.69 301.63 1859.37 301.63C1869.56 301.63 1877.59 298.595 1883.44 292.524C1889.51 286.67 1894.17 279.298 1897.42 270.409L1902.63 256.099L1832.05 80.4805Z"
        fill={primaryColor}
      />
      <path
        d="M198.656 137.663C198.656 176.906 187.707 206.501 165.809 226.448C143.911 246.178 113.34 256.043 74.0968 256.043H8.72754V23.8356H80.9264C104.776 23.8356 125.482 28.1719 143.043 36.8444C160.822 45.517 174.481 58.309 184.021 75.2204C193.778 91.915 198.656 112.729 198.656 137.663ZM155.077 138.964C155.077 111.211 148.681 90.831 135.889 77.8222C123.313 64.8134 104.993 58.309 80.9264 58.309H50.3557V221.244H75.3977C128.517 221.244 155.077 193.817 155.077 138.964Z"
        fill={primaryColor}
      />
      <path
        d="M315.192 76.5213C338.824 76.5213 357.47 83.5677 371.13 97.6606C385.006 111.753 391.944 131.375 391.944 156.525V177.34H275.515C275.948 193.384 280.393 205.851 288.849 214.74C297.305 223.412 309.012 227.749 323.973 227.749C335.247 227.749 345.437 226.665 354.543 224.496C363.649 222.112 372.972 218.751 382.512 214.415V247.262C373.84 251.381 364.842 254.417 355.519 256.368C346.196 258.319 335.03 259.295 322.021 259.295C304.893 259.295 289.608 256.043 276.165 249.538C262.94 242.817 252.533 232.735 244.944 219.293C237.572 205.851 233.887 189.156 233.887 169.209C233.887 139.072 241.367 116.09 256.327 100.262C271.287 84.435 290.909 76.5213 315.192 76.5213ZM315.192 106.767C304.134 106.767 295.136 110.344 288.198 117.499C281.477 124.437 277.575 134.844 276.49 148.72H352.267C352.05 136.579 348.906 126.605 342.835 118.8C336.981 110.778 327.767 106.767 315.192 106.767Z"
        fill={primaryColor}
      />
      <path
        d="M553.271 204.983C553.271 222.762 546.875 236.313 534.083 245.636C521.507 254.742 503.403 259.295 479.771 259.295C467.412 259.295 456.789 258.428 447.899 256.693C439.227 254.959 430.771 252.249 422.532 248.563V213.764C431.205 217.884 440.744 221.353 451.151 224.171C461.775 226.99 471.749 228.399 481.072 228.399C492.346 228.399 500.477 226.665 505.463 223.196C510.45 219.727 512.943 215.065 512.943 209.211C512.943 205.742 511.968 202.707 510.016 200.105C508.065 197.286 504.162 194.251 498.308 190.999C492.671 187.747 484.215 183.736 472.941 178.966C461.667 174.629 452.236 170.185 444.647 165.632C437.275 160.862 431.638 155.333 427.736 149.045C424.05 142.758 422.207 134.627 422.207 124.654C422.207 108.826 428.386 96.9017 440.744 88.8797C453.32 80.6407 470.014 76.5213 490.828 76.5213C501.669 76.5213 511.859 77.6053 521.399 79.7735C531.156 81.9416 540.912 85.1938 550.669 89.5301L537.66 119.776C529.421 116.307 521.399 113.38 513.594 110.995C505.788 108.61 497.766 107.417 489.527 107.417C471.532 107.417 462.534 112.512 462.534 122.703C462.534 126.388 463.618 129.532 465.786 132.134C468.171 134.736 472.182 137.554 477.82 140.59C483.673 143.408 491.912 146.877 502.536 150.997C512.943 155.116 521.941 159.452 529.529 164.006C537.118 168.342 542.972 173.762 547.091 180.267C551.211 186.771 553.271 195.01 553.271 204.983Z"
        fill={primaryColor}
      />
      <path
        d="M613.187 11.8025C619.475 11.8025 624.787 13.4286 629.123 16.6808C633.676 19.7162 635.953 25.2449 635.953 33.267C635.953 41.2891 633.676 46.9263 629.123 50.1785C624.787 53.4307 619.475 55.0568 613.187 55.0568C606.9 55.0568 601.479 53.4307 596.926 50.1785C592.59 46.9263 590.422 41.2891 590.422 33.267C590.422 25.2449 592.59 19.7162 596.926 16.6808C601.479 13.4286 606.9 11.8025 613.187 11.8025ZM633.676 79.7735V256.043H592.698V79.7735H633.676Z"
        fill={primaryColor}
      />
      <path
        d="M745.262 76.5213C767.594 76.5213 785.048 85.1938 797.623 102.539H799.249L803.151 79.7735H837.3V257.344C837.3 282.494 830.145 301.574 815.835 314.582C801.742 327.591 780.603 334.096 752.417 334.096C739.842 334.096 728.242 333.228 717.619 331.494C706.995 329.976 696.913 327.266 687.373 323.363V289.54C707.537 298.647 730.085 303.2 755.019 303.2C768.461 303.2 778.652 299.406 785.59 291.817C792.744 284.445 796.322 273.605 796.322 259.295V254.742C796.322 251.49 796.43 247.695 796.647 243.359C796.864 239.023 797.189 235.554 797.623 232.952H796.322C790.251 242.058 782.771 248.78 773.882 253.116C765.209 257.235 755.344 259.295 744.287 259.295C722.822 259.295 706.019 251.273 693.878 235.229C681.736 219.185 675.665 196.853 675.665 168.233C675.665 139.614 681.736 117.174 693.878 100.913C706.236 84.6518 723.364 76.5213 745.262 76.5213ZM755.995 110.019C730.411 110.019 717.619 129.641 717.619 168.884C717.619 207.477 730.627 226.773 756.645 226.773C770.955 226.773 781.47 222.87 788.191 215.065C795.129 207.043 798.598 193.601 798.598 174.738V168.559C798.598 147.745 795.129 132.784 788.191 123.678C781.47 114.572 770.738 110.019 755.995 110.019Z"
        fill={primaryColor}
      />
      <path
        d="M983.966 76.5213C1003.7 76.5213 1019.09 81.6164 1030.15 91.8066C1041.42 101.997 1047.06 118.475 1047.06 141.24V256.043H1006.08V149.371C1006.08 122.919 995.241 109.694 973.559 109.694C957.081 109.694 945.807 114.897 939.736 125.304C933.666 135.711 930.63 150.563 930.63 169.859V256.043H889.652V79.7735H921.199L927.053 102.864H929.329C934.966 93.758 942.663 87.1452 952.42 83.0257C962.393 78.6894 972.909 76.5213 983.966 76.5213Z"
        fill={primaryColor}
      />
      <path
        d="M1277.19 23.8356L1196.86 256.043H1152.3L1071.97 23.8356H1114.58L1161.41 165.957C1162.71 169.859 1164.23 174.955 1165.96 181.242C1167.69 187.313 1169.32 193.601 1170.84 200.105C1172.57 206.609 1173.87 212.246 1174.74 217.016C1175.39 212.246 1176.48 206.609 1177.99 200.105C1179.51 193.601 1181.14 187.313 1182.87 181.242C1184.61 174.955 1186.23 169.859 1187.75 165.957L1234.58 23.8356H1277.19Z"
        fill={primaryColor}
      />
      <path
        d="M1367.38 76.5213C1390.14 76.5213 1407.38 81.508 1419.09 91.4814C1431.01 101.455 1436.97 116.849 1436.97 137.663V256.043H1408.03L1399.9 231.651H1398.6C1391.01 241.191 1382.99 248.238 1374.53 252.791C1366.08 257.127 1354.59 259.295 1340.06 259.295C1324.23 259.295 1311.11 254.85 1300.71 245.961C1290.3 237.072 1285.1 223.304 1285.1 204.658C1285.1 186.446 1291.71 172.786 1304.94 163.68C1318.38 154.574 1338.65 149.479 1365.75 148.395L1396.32 147.419V138.638C1396.32 127.581 1393.61 119.667 1388.19 114.897C1382.77 109.911 1375.18 107.417 1365.43 107.417C1356.54 107.417 1347.97 108.718 1339.73 111.32C1331.71 113.922 1323.69 116.957 1315.67 120.426L1302.66 91.8066C1311.33 87.2536 1321.2 83.5677 1332.25 80.7492C1343.53 77.9306 1355.24 76.5213 1367.38 76.5213ZM1396.32 172.786L1374.86 173.437C1356.86 174.087 1344.4 177.231 1337.46 182.868C1330.52 188.289 1327.05 195.66 1327.05 204.983C1327.05 213.439 1329.44 219.51 1334.21 223.196C1339.19 226.665 1345.7 228.399 1353.72 228.399C1365.64 228.399 1375.73 225.039 1383.96 218.317C1392.2 211.379 1396.32 201.189 1396.32 187.747V172.786Z"
        fill={primaryColor}
      />
      <path
        d="M1529.3 256.043H1488.32V8.87549H1529.3V256.043Z"
        fill={primaryColor}
      />
      <path
        d="M1622.68 256.043H1581.7V8.87549H1622.68V256.043Z"
        fill={primaryColor}
      />
      <path
        d="M1745.97 76.5213C1769.6 76.5213 1788.25 83.5677 1801.91 97.6606C1815.78 111.753 1822.72 131.375 1822.72 156.525V177.34H1706.29C1706.73 193.384 1711.17 205.851 1719.63 214.74C1728.08 223.412 1739.79 227.749 1754.75 227.749C1766.02 227.749 1776.22 226.665 1785.32 224.496C1794.43 222.112 1803.75 218.751 1813.29 214.415V247.262C1804.62 251.381 1795.62 254.417 1786.3 256.368C1776.97 258.319 1765.81 259.295 1752.8 259.295C1735.67 259.295 1720.39 256.043 1706.94 249.538C1693.72 242.817 1683.31 232.735 1675.72 219.293C1668.35 205.851 1664.66 189.156 1664.66 169.209C1664.66 139.072 1672.14 116.09 1687.1 100.262C1702.06 84.435 1721.69 76.5213 1745.97 76.5213ZM1745.97 106.767C1734.91 106.767 1725.91 110.344 1718.98 117.499C1712.26 124.437 1708.35 134.844 1707.27 148.72H1783.04C1782.83 136.579 1779.68 126.605 1773.61 118.8C1767.76 110.778 1758.54 106.767 1745.97 106.767Z"
        fill={primaryColor}
      />
      <path
        d="M1831.35 79.7735H1875.9L1911.03 179.291C1913.41 185.578 1915.36 191.758 1916.88 197.828C1918.4 203.899 1919.48 210.078 1920.13 216.366H1921.43C1922.3 210.946 1923.6 204.983 1925.34 198.479C1927.07 191.974 1929.02 185.578 1931.19 179.291L1965.01 79.7735H2009.24L1934.12 279.459C1927.4 297.021 1918.18 310.463 1906.47 319.786C1894.98 329.326 1880.78 334.096 1863.87 334.096C1858.45 334.096 1853.79 333.77 1849.89 333.12C1845.98 332.686 1842.51 332.144 1839.48 331.494V298.972C1841.86 299.405 1844.68 299.839 1847.93 300.273C1851.4 300.706 1854.98 300.923 1858.67 300.923C1868.86 300.923 1876.88 297.888 1882.73 291.817C1888.8 285.963 1893.46 278.591 1896.72 269.702L1901.92 255.392L1831.35 79.7735Z"
        fill={primaryColor}
      />
      <path
        d="M197.949 136.956C197.949 176.199 187 205.794 165.102 225.741C143.204 245.471 112.633 255.336 73.3898 255.336H8.02051V23.1286H80.2194C104.069 23.1286 124.775 27.4649 142.336 36.1374C160.115 44.8099 173.774 57.6019 183.314 74.5134C193.071 91.208 197.949 112.022 197.949 136.956ZM154.37 138.257C154.37 110.504 147.974 90.1239 135.182 77.1151C122.606 64.1063 104.286 57.6019 80.2194 57.6019H49.6487V220.537H74.6906C127.81 220.537 154.37 193.11 154.37 138.257Z"
        fill={primaryColor}
      />
      <path
        d="M314.485 75.8142C338.117 75.8142 356.763 82.8607 370.422 96.9536C384.299 111.046 391.237 130.668 391.237 155.818V176.633H274.808C275.241 192.677 279.686 205.143 288.142 214.033C296.598 222.705 308.305 227.042 323.266 227.042C334.54 227.042 344.73 225.958 353.836 223.789C362.942 221.404 372.265 218.044 381.805 213.708V246.555C373.133 250.674 364.135 253.71 354.812 255.661C345.489 257.612 334.323 258.588 321.314 258.588C304.186 258.588 288.901 255.336 275.458 248.831C262.233 242.11 251.826 232.028 244.237 218.586C236.865 205.143 233.18 188.449 233.18 168.502C233.18 138.365 240.66 115.383 255.62 99.5553C270.58 83.728 290.202 75.8142 314.485 75.8142ZM314.485 106.06C303.427 106.06 294.429 109.637 287.491 116.792C280.77 123.73 276.867 134.137 275.783 148.013H351.56C351.343 135.872 348.199 125.898 342.128 118.093C336.274 110.071 327.06 106.06 314.485 106.06Z"
        fill={primaryColor}
      />
      <path
        d="M552.564 204.276C552.564 222.055 546.168 235.606 533.376 244.929C520.8 254.035 502.696 258.588 479.064 258.588C466.705 258.588 456.082 257.721 447.192 255.986C438.52 254.252 430.064 251.542 421.825 247.856V213.057C430.498 217.177 440.037 220.646 450.444 223.464C461.068 226.283 471.042 227.692 480.365 227.692C491.639 227.692 499.769 225.958 504.756 222.489C509.743 219.02 512.236 214.358 512.236 208.504C512.236 205.035 511.261 202 509.309 199.398C507.358 196.579 503.455 193.544 497.601 190.292C491.964 187.04 483.508 183.029 472.234 178.259C460.96 173.922 451.528 169.478 443.94 164.925C436.568 160.155 430.931 154.626 427.029 148.338C423.343 142.051 421.5 133.92 421.5 123.947C421.5 108.119 427.679 96.1947 440.037 88.1726C452.613 79.9337 469.307 75.8142 490.121 75.8142C500.962 75.8142 511.152 76.8983 520.692 79.0664C530.449 81.2346 540.205 84.4868 549.962 88.8231L536.953 119.069C528.714 115.6 520.692 112.673 512.887 110.288C505.081 107.903 497.059 106.71 488.82 106.71C470.825 106.71 461.827 111.805 461.827 121.996C461.827 125.681 462.911 128.825 465.079 131.427C467.464 134.029 471.475 136.847 477.112 139.883C482.966 142.701 491.205 146.17 501.829 150.29C512.236 154.409 521.234 158.745 528.822 163.298C536.411 167.635 542.265 173.055 546.384 179.559C550.504 186.064 552.564 194.303 552.564 204.276Z"
        fill={primaryColor}
      />
      <path
        d="M612.48 11.0954C618.768 11.0954 624.08 12.7215 628.416 15.9737C632.969 19.0091 635.246 24.5379 635.246 32.56C635.246 40.5821 632.969 46.2192 628.416 49.4714C624.08 52.7236 618.768 54.3497 612.48 54.3497C606.193 54.3497 600.772 52.7236 596.219 49.4714C591.883 46.2192 589.715 40.5821 589.715 32.56C589.715 24.5379 591.883 19.0091 596.219 15.9737C600.772 12.7215 606.193 11.0954 612.48 11.0954ZM632.969 79.0664V255.336H591.991V79.0664H632.969Z"
        fill={primaryColor}
      />
      <path
        d="M744.555 75.8142C766.887 75.8142 784.341 84.4868 796.916 101.832H798.542L802.444 79.0664H836.593V256.637C836.593 281.787 829.438 300.867 815.128 313.875C801.035 326.884 779.896 333.389 751.71 333.389C739.135 333.389 727.535 332.521 716.911 330.787C706.288 329.269 696.206 326.559 686.666 322.656V288.833C706.83 297.94 729.378 302.493 754.312 302.493C767.754 302.493 777.945 298.698 784.883 291.11C792.037 283.738 795.615 272.898 795.615 258.588V254.035C795.615 250.783 795.723 246.988 795.94 242.652C796.157 238.316 796.482 234.847 796.916 232.245H795.615C789.544 241.351 782.064 248.073 773.175 252.409C764.502 256.528 754.637 258.588 743.58 258.588C722.115 258.588 705.312 250.566 693.17 234.522C681.029 218.478 674.958 196.146 674.958 167.526C674.958 138.907 681.029 116.467 693.17 100.206C705.529 83.9448 722.657 75.8142 744.555 75.8142ZM755.287 109.312C729.703 109.312 716.911 128.934 716.911 168.177C716.911 206.77 729.92 226.066 755.938 226.066C770.248 226.066 780.763 222.163 787.484 214.358C794.422 206.336 797.891 192.893 797.891 174.031V167.852C797.891 147.037 794.422 132.077 787.484 122.971C780.763 113.865 770.031 109.312 755.287 109.312Z"
        fill={primaryColor}
      />
      <path
        d="M983.259 75.8142C1002.99 75.8142 1018.38 80.9094 1029.44 91.0996C1040.71 101.29 1046.35 117.768 1046.35 140.533V255.336H1005.37V148.664C1005.37 122.212 994.534 108.987 972.852 108.987C956.374 108.987 945.1 114.19 939.029 124.597C932.958 135.004 929.923 149.856 929.923 169.152V255.336H888.945V79.0664H920.492L926.346 102.157H928.622C934.259 93.0509 941.956 86.4381 951.713 82.3187C961.686 77.9824 972.202 75.8142 983.259 75.8142Z"
        fill={primaryColor}
      />
      <path
        d="M1276.48 23.1286L1196.15 255.336H1151.59L1071.26 23.1286H1113.87L1160.7 165.25C1162 169.152 1163.52 174.248 1165.25 180.535C1166.99 186.606 1168.61 192.894 1170.13 199.398C1171.87 205.902 1173.17 211.539 1174.03 216.309C1174.68 211.539 1175.77 205.902 1177.29 199.398C1178.8 192.894 1180.43 186.606 1182.16 180.535C1183.9 174.248 1185.52 169.152 1187.04 165.25L1233.87 23.1286H1276.48Z"
        fill={primaryColor}
      />
      <path
        d="M1366.67 75.8142C1389.44 75.8142 1406.67 80.801 1418.38 90.7744C1430.31 100.748 1436.27 116.142 1436.27 136.956V255.336H1407.32L1399.19 230.944H1397.89C1390.3 240.484 1382.28 247.531 1373.83 252.084C1365.37 256.42 1353.88 258.588 1339.35 258.588C1323.52 258.588 1310.41 254.143 1300 245.254C1289.59 236.365 1284.39 222.597 1284.39 203.951C1284.39 185.739 1291 172.079 1304.23 162.973C1317.67 153.867 1337.94 148.772 1365.04 147.688L1395.62 146.712V137.931C1395.62 126.874 1392.91 118.96 1387.48 114.19C1382.06 109.204 1374.48 106.71 1364.72 106.71C1355.83 106.71 1347.27 108.011 1339.03 110.613C1331.01 113.215 1322.98 116.25 1314.96 119.719L1301.95 91.0996C1310.62 86.5465 1320.49 82.8607 1331.55 80.0421C1342.82 77.2235 1354.53 75.8142 1366.67 75.8142ZM1395.62 172.079L1374.15 172.73C1356.16 173.38 1343.69 176.524 1336.75 182.161C1329.81 187.582 1326.34 194.953 1326.34 204.276C1326.34 212.732 1328.73 218.803 1333.5 222.489C1338.49 225.958 1344.99 227.692 1353.01 227.692C1364.94 227.692 1375.02 224.331 1383.26 217.61C1391.5 210.672 1395.62 200.482 1395.62 187.04V172.079Z"
        fill={primaryColor}
      />
      <path
        d="M1528.59 255.336H1487.62V8.16846H1528.59V255.336Z"
        fill={primaryColor}
      />
      <path
        d="M1621.97 255.336H1580.99V8.16846H1621.97V255.336Z"
        fill={primaryColor}
      />
      <path
        d="M1745.26 75.8142C1768.9 75.8142 1787.54 82.8607 1801.2 96.9536C1815.08 111.046 1822.01 130.668 1822.01 155.818V176.633H1705.59C1706.02 192.677 1710.46 205.143 1718.92 214.033C1727.38 222.705 1739.08 227.042 1754.04 227.042C1765.32 227.042 1775.51 225.958 1784.61 223.789C1793.72 221.404 1803.04 218.044 1812.58 213.708V246.555C1803.91 250.674 1794.91 253.71 1785.59 255.661C1776.27 257.612 1765.1 258.588 1752.09 258.588C1734.96 258.588 1719.68 255.336 1706.24 248.831C1693.01 242.11 1682.6 232.028 1675.02 218.586C1667.64 205.143 1663.96 188.449 1663.96 168.502C1663.96 138.365 1671.44 115.383 1686.4 99.5553C1701.36 83.728 1720.98 75.8142 1745.26 75.8142ZM1745.26 106.06C1734.21 106.06 1725.21 109.637 1718.27 116.792C1711.55 123.73 1707.65 134.137 1706.56 148.013H1782.34C1782.12 135.872 1778.98 125.898 1772.91 118.093C1767.05 110.071 1757.84 106.06 1745.26 106.06Z"
        fill={primaryColor}
      />
      <path
        d="M1830.64 79.0664H1875.2L1910.32 178.584C1912.7 184.871 1914.66 191.051 1916.17 197.121C1917.69 203.192 1918.78 209.371 1919.43 215.659H1920.73C1921.59 210.239 1922.89 204.276 1924.63 197.772C1926.36 191.267 1928.32 184.871 1930.48 178.584L1964.31 79.0664H2008.54L1933.41 278.752C1926.69 296.314 1917.47 309.756 1905.77 319.079C1894.28 328.619 1880.07 333.389 1863.16 333.389C1857.74 333.389 1853.08 333.063 1849.18 332.413C1845.28 331.979 1841.81 331.437 1838.77 330.787V298.265C1841.16 298.698 1843.97 299.132 1847.23 299.566C1850.7 299.999 1854.27 300.216 1857.96 300.216C1868.15 300.216 1876.17 297.181 1882.03 291.11C1888.1 285.256 1892.76 277.884 1896.01 268.995L1901.21 254.685L1830.64 79.0664Z"
        fill={primaryColor}
      />
      <path
        d="M197.242 136.249C197.242 175.492 186.293 205.087 164.395 225.034C142.497 244.764 111.926 254.629 72.6827 254.629H7.31348V22.4216H79.5124C103.362 22.4216 124.068 26.7578 141.629 35.4304C159.408 44.1029 173.067 56.8949 182.607 73.8063C192.364 90.501 197.242 111.315 197.242 136.249ZM153.663 137.549C153.663 109.797 147.267 89.4169 134.475 76.4081C121.899 63.3993 103.579 56.8949 79.5124 56.8949H48.9417V219.83H73.9836C127.103 219.83 153.663 192.403 153.663 137.549Z"
        fill={primaryColor}
      />
      <path
        d="M313.778 75.1072C337.41 75.1072 356.056 82.1537 369.715 96.2465C383.592 110.339 390.53 129.961 390.53 155.111V175.925H274.101C274.534 191.97 278.979 204.436 287.435 213.326C295.89 221.998 307.598 226.335 322.559 226.335C333.833 226.335 344.023 225.251 353.129 223.082C362.235 220.697 371.558 217.337 381.098 213.001V245.848C372.426 249.967 363.428 253.003 354.105 254.954C344.782 256.905 333.616 257.881 320.607 257.881C303.479 257.881 288.194 254.629 274.751 248.124C261.526 241.403 251.118 231.321 243.53 217.879C236.158 204.436 232.473 187.742 232.473 167.795C232.473 137.658 239.953 114.676 254.913 98.8483C269.873 83.0209 289.494 75.1072 313.778 75.1072ZM313.778 105.353C302.72 105.353 293.722 108.93 286.784 116.085C280.063 123.023 276.16 133.43 275.076 147.306H350.853C350.636 135.165 347.492 125.191 341.421 117.386C335.567 109.364 326.353 105.353 313.778 105.353Z"
        fill={primaryColor}
      />
      <path
        d="M551.857 203.569C551.857 221.348 545.461 234.899 532.669 244.222C520.093 253.328 501.989 257.881 478.357 257.881C465.998 257.881 455.375 257.014 446.485 255.279C437.813 253.545 429.357 250.835 421.118 247.149V212.35C429.791 216.47 439.33 219.939 449.737 222.757C460.361 225.576 470.335 226.985 479.658 226.985C490.932 226.985 499.062 225.251 504.049 221.782C509.036 218.313 511.529 213.651 511.529 207.797C511.529 204.328 510.554 201.293 508.602 198.691C506.651 195.872 502.748 192.837 496.894 189.585C491.257 186.333 482.801 182.321 471.527 177.552C460.253 173.215 450.821 168.771 443.233 164.218C435.861 159.448 430.224 153.919 426.322 147.631C422.636 141.344 420.793 133.213 420.793 123.24C420.793 107.412 426.972 95.4877 439.33 87.4656C451.906 79.2267 468.6 75.1072 489.414 75.1072C500.255 75.1072 510.445 76.1913 519.985 78.3594C529.742 80.5276 539.498 83.7798 549.255 88.116L536.246 118.362C528.007 114.892 519.985 111.966 512.18 109.581C504.374 107.196 496.352 106.003 488.113 106.003C470.118 106.003 461.12 111.098 461.12 121.288C461.12 124.974 462.204 128.118 464.372 130.72C466.757 133.322 470.768 136.14 476.405 139.176C482.259 141.994 490.498 145.463 501.122 149.583C511.529 153.702 520.527 158.038 528.115 162.591C535.704 166.928 541.558 172.348 545.677 178.852C549.797 185.357 551.857 193.596 551.857 203.569Z"
        fill={primaryColor}
      />
      <path
        d="M611.773 10.3884C618.061 10.3884 623.373 12.0145 627.709 15.2667C632.262 18.3021 634.539 23.8308 634.539 31.8529C634.539 39.875 632.262 45.5122 627.709 48.7644C623.373 52.0166 618.061 53.6427 611.773 53.6427C605.486 53.6427 600.065 52.0166 595.512 48.7644C591.176 45.5122 589.008 39.875 589.008 31.8529C589.008 23.8308 591.176 18.3021 595.512 15.2667C600.065 12.0145 605.486 10.3884 611.773 10.3884ZM632.262 78.3594V254.629H591.284V78.3594H632.262Z"
        fill={primaryColor}
      />
      <path
        d="M743.848 75.1072C766.18 75.1072 783.633 83.7798 796.209 101.125H797.835L801.737 78.3594H835.885V255.93C835.885 281.08 828.731 300.16 814.421 313.168C800.328 326.177 779.189 332.682 751.003 332.682C738.428 332.682 726.828 331.814 716.204 330.08C705.581 328.562 695.499 325.852 685.959 321.949V288.126C706.123 297.233 728.671 301.786 753.605 301.786C767.047 301.786 777.237 297.991 784.176 290.403C791.33 283.031 794.908 272.191 794.908 257.881V253.328C794.908 250.076 795.016 246.281 795.233 241.945C795.45 237.609 795.775 234.14 796.209 231.538H794.908C788.837 240.644 781.357 247.366 772.468 251.702C763.795 255.821 753.93 257.881 742.873 257.881C721.408 257.881 704.605 249.859 692.463 233.815C680.322 217.77 674.251 195.439 674.251 166.819C674.251 138.2 680.322 115.76 692.463 99.4987C704.822 83.2377 721.95 75.1072 743.848 75.1072ZM754.58 108.605C728.996 108.605 716.204 128.227 716.204 167.47C716.204 206.063 729.213 225.359 755.231 225.359C769.541 225.359 780.056 221.456 786.777 213.651C793.715 205.629 797.184 192.186 797.184 173.324V167.145C797.184 146.33 793.715 131.37 786.777 122.264C780.056 113.158 769.324 108.605 754.58 108.605Z"
        fill={primaryColor}
      />
      <path
        d="M982.552 75.1072C1002.28 75.1072 1017.68 80.2023 1028.73 90.3926C1040.01 100.583 1045.64 117.061 1045.64 139.826V254.629H1004.67V147.957C1004.67 121.505 993.826 108.28 972.145 108.28C955.667 108.28 944.393 113.483 938.322 123.89C932.251 134.297 929.216 149.149 929.216 168.445V254.629H888.238V78.3594H919.785L925.639 101.45H927.915C933.552 92.3439 941.249 85.7311 951.006 81.6116C960.979 77.2754 971.495 75.1072 982.552 75.1072Z"
        fill={primaryColor}
      />
      <path
        d="M1275.77 22.4216L1195.44 254.629H1150.89L1070.56 22.4216H1113.16L1159.99 164.543C1161.29 168.445 1162.81 173.541 1164.55 179.828C1166.28 185.899 1167.91 192.186 1169.42 198.691C1171.16 205.195 1172.46 210.832 1173.33 215.602C1173.98 210.832 1175.06 205.195 1176.58 198.691C1178.1 192.186 1179.72 185.899 1181.46 179.828C1183.19 173.541 1184.82 168.445 1186.34 164.543L1233.17 22.4216H1275.77Z"
        fill={primaryColor}
      />
      <path
        d="M1365.96 75.1072C1388.73 75.1072 1405.97 80.0939 1417.67 90.0673C1429.6 100.041 1435.56 115.435 1435.56 136.249V254.629H1406.62L1398.49 230.237H1397.18C1389.6 239.777 1381.57 246.823 1373.12 251.377C1364.66 255.713 1353.17 257.881 1338.65 257.881C1322.82 257.881 1309.7 253.436 1299.29 244.547C1288.89 235.658 1283.68 221.89 1283.68 203.244C1283.68 185.032 1290.3 171.372 1303.52 162.266C1316.96 153.16 1337.24 148.065 1364.34 146.981L1394.91 146.005V137.224C1394.91 126.167 1392.2 118.253 1386.78 113.483C1381.36 108.496 1373.77 106.003 1364.01 106.003C1355.12 106.003 1346.56 107.304 1338.32 109.906C1330.3 112.508 1322.28 115.543 1314.25 119.012L1301.24 90.3926C1309.92 85.8395 1319.78 82.1537 1330.84 79.3351C1342.11 76.5165 1353.82 75.1072 1365.96 75.1072ZM1394.91 171.372L1373.44 172.023C1355.45 172.673 1342.98 175.817 1336.04 181.454C1329.11 186.875 1325.64 194.246 1325.64 203.569C1325.64 212.025 1328.02 218.096 1332.79 221.782C1337.78 225.251 1344.28 226.985 1352.3 226.985C1364.23 226.985 1374.31 223.624 1382.55 216.903C1390.79 209.965 1394.91 199.775 1394.91 186.333V171.372Z"
        fill={primaryColor}
      />
      <path
        d="M1527.89 254.629H1486.91V7.46143H1527.89V254.629Z"
        fill={primaryColor}
      />
      <path
        d="M1621.26 254.629H1580.28V7.46143H1621.26V254.629Z"
        fill={primaryColor}
      />
      <path
        d="M1744.56 75.1072C1768.19 75.1072 1786.83 82.1537 1800.49 96.2465C1814.37 110.339 1821.31 129.961 1821.31 155.111V175.925H1704.88C1705.31 191.97 1709.76 204.436 1718.21 213.326C1726.67 221.998 1738.38 226.335 1753.34 226.335C1764.61 226.335 1774.8 225.251 1783.91 223.082C1793.01 220.697 1802.34 217.337 1811.88 213.001V245.848C1803.2 249.967 1794.21 253.003 1784.88 254.954C1775.56 256.905 1764.39 257.881 1751.39 257.881C1734.26 257.881 1718.97 254.629 1705.53 248.124C1692.3 241.403 1681.9 231.321 1674.31 217.879C1666.94 204.436 1663.25 187.742 1663.25 167.795C1663.25 137.658 1670.73 114.676 1685.69 98.8483C1700.65 83.0209 1720.27 75.1072 1744.56 75.1072ZM1744.56 105.353C1733.5 105.353 1724.5 108.93 1717.56 116.085C1710.84 123.023 1706.94 133.43 1705.85 147.306H1781.63C1781.41 135.165 1778.27 125.191 1772.2 117.386C1766.35 109.364 1757.13 105.353 1744.56 105.353Z"
        fill={primaryColor}
      />
      <path
        d="M1829.93 78.3594H1874.49L1909.61 177.877C1912 184.164 1913.95 190.344 1915.47 196.414C1916.98 202.485 1918.07 208.664 1918.72 214.952H1920.02C1920.89 209.532 1922.19 203.569 1923.92 197.065C1925.66 190.56 1927.61 184.164 1929.78 177.877L1963.6 78.3594H2007.83L1932.7 278.045C1925.98 295.607 1916.77 309.049 1905.06 318.372C1893.57 327.912 1879.37 332.682 1862.46 332.682C1857.04 332.682 1852.37 332.356 1848.47 331.706C1844.57 331.272 1841.1 330.73 1838.06 330.08V297.558C1840.45 297.991 1843.27 298.425 1846.52 298.859C1849.99 299.292 1853.57 299.509 1857.25 299.509C1867.44 299.509 1875.46 296.474 1881.32 290.403C1887.39 284.549 1892.05 277.177 1895.3 268.288L1900.51 253.978L1829.93 78.3594Z"
        fill={primaryColor}
      />
      <path
        d="M196.535 135.541C196.535 174.784 185.585 204.379 163.687 224.326C141.789 244.056 111.218 253.921 71.9752 253.921H6.60596V21.714H78.8048C102.654 21.714 123.36 26.0503 140.922 34.7228C158.701 43.3954 172.36 56.1874 181.9 73.0988C191.656 89.7935 196.535 110.608 196.535 135.541ZM152.955 136.842C152.955 109.09 146.559 88.7094 133.767 75.7006C121.192 62.6918 102.871 56.1874 78.8048 56.1874H48.2341V219.123H73.2761C126.395 219.123 152.955 191.696 152.955 136.842Z"
        fill={primaryColor}
      />
      <path
        d="M313.07 74.3997C336.703 74.3997 355.349 81.4461 369.008 95.539C382.884 109.632 389.822 129.254 389.822 154.404V175.218H273.393C273.827 191.262 278.272 203.729 286.727 212.618C295.183 221.291 306.891 225.627 321.851 225.627C333.125 225.627 343.316 224.543 352.422 222.375C361.528 219.99 370.851 216.629 380.391 212.293V245.14C371.718 249.26 362.72 252.295 353.397 254.246C344.074 256.198 332.909 257.173 319.9 257.173C302.771 257.173 287.486 253.921 274.044 247.417C260.818 240.696 250.411 230.614 242.823 217.171C235.451 203.729 231.765 187.034 231.765 167.087C231.765 136.95 239.245 113.968 254.205 98.1408C269.165 82.3134 288.787 74.3997 313.07 74.3997ZM313.07 104.645C302.013 104.645 293.015 108.223 286.077 115.377C279.356 122.315 275.453 132.723 274.369 146.599H350.145C349.928 134.457 346.785 124.484 340.714 116.678C334.86 108.656 325.645 104.645 313.07 104.645Z"
        fill={primaryColor}
      />
      <path
        d="M551.149 202.862C551.149 220.64 544.753 234.191 531.961 243.514C519.386 252.62 501.282 257.173 477.649 257.173C465.291 257.173 454.667 256.306 445.778 254.572C437.105 252.837 428.649 250.127 420.41 246.441V211.643C429.083 215.762 438.623 219.231 449.03 222.05C459.654 224.868 469.627 226.278 478.95 226.278C490.224 226.278 498.355 224.543 503.342 221.074C508.328 217.605 510.822 212.943 510.822 207.09C510.822 203.621 509.846 200.585 507.895 197.983C505.943 195.165 502.041 192.129 496.187 188.877C490.55 185.625 482.094 181.614 470.82 176.844C459.545 172.508 450.114 168.063 442.525 163.51C435.154 158.74 429.517 153.211 425.614 146.924C421.928 140.636 420.085 132.506 420.085 122.532C420.085 106.705 426.264 94.7802 438.623 86.7581C451.198 78.5192 467.893 74.3997 488.707 74.3997C499.547 74.3997 509.738 75.4838 519.277 77.6519C529.034 79.82 538.791 83.0723 548.547 87.4085L535.538 117.654C527.3 114.185 519.277 111.258 511.472 108.873C503.667 106.488 495.645 105.296 487.406 105.296C469.41 105.296 460.413 110.391 460.413 120.581C460.413 124.267 461.497 127.411 463.665 130.012C466.05 132.614 470.061 135.433 475.698 138.468C481.552 141.287 489.791 144.756 500.415 148.875C510.822 152.995 519.819 157.331 527.408 161.884C534.996 166.22 540.85 171.641 544.97 178.145C549.089 184.649 551.149 192.888 551.149 202.862Z"
        fill={primaryColor}
      />
      <path
        d="M611.066 9.68089C617.353 9.68089 622.665 11.307 627.002 14.5592C631.555 17.5946 633.831 23.1233 633.831 31.1454C633.831 39.1675 631.555 44.8047 627.002 48.0569C622.665 51.3091 617.353 52.9352 611.066 52.9352C604.778 52.9352 599.358 51.3091 594.805 48.0569C590.468 44.8047 588.3 39.1675 588.3 31.1454C588.3 23.1233 590.468 17.5946 594.805 14.5592C599.358 11.307 604.778 9.68089 611.066 9.68089ZM631.555 77.6519V253.921H590.577V77.6519H631.555Z"
        fill={primaryColor}
      />
      <path
        d="M743.141 74.3997C765.472 74.3997 782.926 83.0722 795.501 100.417H797.127L801.03 77.6519H835.178V255.222C835.178 280.372 828.023 299.452 813.713 312.461C799.621 325.47 778.481 331.974 750.296 331.974C737.72 331.974 726.121 331.107 715.497 329.372C704.873 327.855 694.791 325.144 685.252 321.242V287.419C705.415 296.525 727.964 301.078 752.897 301.078C766.34 301.078 776.53 297.284 783.468 289.695C790.623 282.324 794.2 271.483 794.2 257.173V252.62C794.2 249.368 794.309 245.574 794.526 241.238C794.742 236.901 795.068 233.432 795.501 230.831H794.2C788.129 239.937 780.649 246.658 771.76 250.994C763.088 255.114 753.223 257.173 742.165 257.173C720.701 257.173 703.897 249.151 691.756 233.107C679.614 217.063 673.544 194.731 673.544 166.112C673.544 137.492 679.614 115.052 691.756 98.7912C704.114 82.5302 721.243 74.3997 743.141 74.3997ZM753.873 107.897C728.289 107.897 715.497 127.519 715.497 166.762C715.497 205.355 728.506 224.651 754.523 224.651C768.833 224.651 779.349 220.749 786.07 212.943C793.008 204.921 796.477 191.479 796.477 172.616V166.437C796.477 145.623 793.008 130.663 786.07 121.557C779.349 112.45 768.616 107.897 753.873 107.897Z"
        fill={primaryColor}
      />
      <path
        d="M981.845 74.3997C1001.57 74.3997 1016.97 79.4948 1028.03 89.6851C1039.3 99.8753 1044.94 116.353 1044.94 139.119V253.921H1003.96V147.249C1003.96 120.798 993.119 107.572 971.438 107.572C954.96 107.572 943.686 112.776 937.615 123.183C931.544 133.59 928.509 148.441 928.509 167.738V253.921H887.531V77.6519H919.077L924.931 100.743H927.208C932.845 91.6364 940.542 85.0236 950.298 80.9041C960.272 76.5678 970.787 74.3997 981.845 74.3997Z"
        fill={primaryColor}
      />
      <path
        d="M1275.06 21.714L1194.73 253.921H1150.18L1069.85 21.714H1112.45L1159.29 163.835C1160.59 167.738 1162.1 172.833 1163.84 179.121C1165.57 185.191 1167.2 191.479 1168.72 197.983C1170.45 204.488 1171.75 210.125 1172.62 214.895C1173.27 210.125 1174.35 204.488 1175.87 197.983C1177.39 191.479 1179.02 185.191 1180.75 179.121C1182.48 172.833 1184.11 167.738 1185.63 163.835L1232.46 21.714H1275.06Z"
        fill={primaryColor}
      />
      <path
        d="M1365.26 74.3997C1388.02 74.3997 1405.26 79.3864 1416.97 89.3598C1428.89 99.3333 1434.85 114.727 1434.85 135.541V253.921H1405.91L1397.78 229.53H1396.48C1388.89 239.07 1380.87 246.116 1372.41 250.669C1363.96 255.005 1352.46 257.173 1337.94 257.173C1322.11 257.173 1308.99 252.729 1298.59 243.839C1288.18 234.95 1282.98 221.182 1282.98 202.536C1282.98 184.324 1289.59 170.665 1302.81 161.559C1316.26 152.453 1336.53 147.357 1363.63 146.273L1394.2 145.298V136.517C1394.2 125.459 1391.49 117.546 1386.07 112.776C1380.65 107.789 1373.06 105.296 1363.3 105.296C1354.42 105.296 1345.85 106.597 1337.61 109.198C1329.59 111.8 1321.57 114.835 1313.55 118.304L1300.54 89.6851C1309.21 85.132 1319.07 81.4462 1330.13 78.6276C1341.41 75.809 1353.11 74.3997 1365.26 74.3997ZM1394.2 170.665L1372.74 171.315C1354.74 171.966 1342.27 175.11 1335.34 180.747C1328.4 186.167 1324.93 193.539 1324.93 202.862C1324.93 211.317 1327.31 217.388 1332.08 221.074C1337.07 224.543 1343.57 226.278 1351.6 226.278C1363.52 226.278 1373.6 222.917 1381.84 216.196C1390.08 209.258 1394.2 199.067 1394.2 185.625V170.665Z"
        fill={primaryColor}
      />
      <path
        d="M1527.18 253.921H1486.2V6.75391H1527.18V253.921Z"
        fill={primaryColor}
      />
      <path
        d="M1620.55 253.921H1579.58V6.75391H1620.55V253.921Z"
        fill={primaryColor}
      />
      <path
        d="M1743.85 74.3997C1767.48 74.3997 1786.13 81.4461 1799.79 95.539C1813.66 109.632 1820.6 129.254 1820.6 154.404V175.218H1704.17C1704.6 191.262 1709.05 203.729 1717.51 212.618C1725.96 221.291 1737.67 225.627 1752.63 225.627C1763.9 225.627 1774.09 224.543 1783.2 222.375C1792.31 219.99 1801.63 216.629 1811.17 212.293V245.14C1802.5 249.26 1793.5 252.295 1784.18 254.246C1774.85 256.198 1763.69 257.173 1750.68 257.173C1733.55 257.173 1718.26 253.921 1704.82 247.417C1691.6 240.696 1681.19 230.614 1673.6 217.171C1666.23 203.729 1662.54 187.034 1662.54 167.087C1662.54 136.95 1670.02 113.968 1684.98 98.1408C1699.94 82.3134 1719.57 74.3997 1743.85 74.3997ZM1743.85 104.645C1732.79 104.645 1723.79 108.223 1716.85 115.377C1710.13 122.315 1706.23 132.723 1705.15 146.599H1780.92C1780.71 134.457 1777.56 124.484 1771.49 116.678C1765.64 108.656 1756.42 104.645 1743.85 104.645Z"
        fill={primaryColor}
      />
      <path
        d="M1829.23 77.6519H1873.78L1908.91 177.169C1911.29 183.457 1913.24 189.636 1914.76 195.707C1916.28 201.778 1917.36 207.957 1918.01 214.244H1919.31C1920.18 208.824 1921.48 202.862 1923.21 196.357C1924.95 189.853 1926.9 183.457 1929.07 177.169L1962.89 77.6519H2007.12L1932 277.337C1925.27 294.899 1916.06 308.341 1904.35 317.664C1892.86 327.204 1878.66 331.974 1861.75 331.974C1856.33 331.974 1851.67 331.649 1847.76 330.998C1843.86 330.565 1840.39 330.023 1837.36 329.372V296.85C1839.74 297.284 1842.56 297.718 1845.81 298.151C1849.28 298.585 1852.86 298.802 1856.54 298.802C1866.73 298.802 1874.76 295.766 1880.61 289.695C1886.68 283.841 1891.34 276.47 1894.6 267.58L1899.8 253.271L1829.23 77.6519Z"
        fill={primaryColor}
      />
      <path
        d="M195.828 134.834C195.828 174.077 184.878 203.672 162.98 223.619C141.082 243.349 110.511 253.214 71.2682 253.214H5.89893V21.007H78.0978C101.947 21.007 122.653 25.3433 140.215 34.0158C157.994 42.6884 171.653 55.4804 181.193 72.3918C190.949 89.0864 195.828 109.901 195.828 134.834ZM152.248 136.135C152.248 108.383 145.852 88.0024 133.06 74.9936C120.485 61.9847 102.164 55.4803 78.0978 55.4803H47.5271V218.416H72.5691C125.688 218.416 152.248 190.989 152.248 136.135Z"
        fill={primaryColor}
      />
      <path
        d="M312.363 73.6927C335.996 73.6927 354.642 80.7391 368.301 94.832C382.177 108.925 389.115 128.546 389.115 153.697V174.511H272.686C273.12 190.555 277.564 203.022 286.02 211.911C294.476 220.584 306.184 224.92 321.144 224.92C332.418 224.92 342.608 223.836 351.715 221.668C360.821 219.283 370.144 215.922 379.684 211.586V244.433C371.011 248.553 362.013 251.588 352.69 253.539C343.367 255.491 332.201 256.466 319.193 256.466C302.064 256.466 286.779 253.214 273.337 246.71C260.111 239.989 249.704 229.907 242.115 216.464C234.744 203.022 231.058 186.327 231.058 166.38C231.058 136.243 238.538 113.261 253.498 97.4338C268.458 81.6064 288.08 73.6927 312.363 73.6927ZM312.363 103.938C301.306 103.938 292.308 107.516 285.37 114.67C278.649 121.608 274.746 132.015 273.662 145.892H349.438C349.221 133.75 346.078 123.777 340.007 115.971C334.153 107.949 324.938 103.938 312.363 103.938Z"
        fill={primaryColor}
      />
      <path
        d="M550.442 202.155C550.442 219.933 544.046 233.484 531.254 242.807C518.679 251.913 500.575 256.466 476.942 256.466C464.584 256.466 453.96 255.599 445.071 253.865C436.398 252.13 427.942 249.42 419.703 245.734V210.936C428.376 215.055 437.916 218.524 448.323 221.343C458.947 224.161 468.92 225.57 478.243 225.57C489.517 225.57 497.648 223.836 502.635 220.367C507.621 216.898 510.115 212.236 510.115 206.383C510.115 202.913 509.139 199.878 507.188 197.276C505.236 194.458 501.334 191.422 495.48 188.17C489.843 184.918 481.387 180.907 470.113 176.137C458.838 171.801 449.407 167.356 441.818 162.803C434.447 158.033 428.81 152.504 424.907 146.217C421.221 139.929 419.378 131.799 419.378 121.825C419.378 105.998 425.557 94.0731 437.916 86.051C450.491 77.8121 467.186 73.6927 488 73.6927C498.84 73.6927 509.031 74.7767 518.57 76.9449C528.327 79.113 538.084 82.3652 547.84 86.7015L534.831 116.947C526.593 113.478 518.57 110.551 510.765 108.166C502.96 105.781 494.938 104.589 486.699 104.589C468.703 104.589 459.706 109.684 459.706 119.874C459.706 123.56 460.79 126.704 462.958 129.305C465.343 131.907 469.354 134.726 474.991 137.761C480.845 140.58 489.084 144.049 499.708 148.168C510.115 152.288 519.112 156.624 526.701 161.177C534.289 165.513 540.143 170.933 544.263 177.438C548.382 183.942 550.442 192.181 550.442 202.155Z"
        fill={primaryColor}
      />
      <path
        d="M610.359 8.97386C616.646 8.97386 621.958 10.6 626.294 13.8522C630.848 16.8875 633.124 22.4163 633.124 30.4384C633.124 38.4605 630.848 44.0976 626.294 47.3498C621.958 50.602 616.646 52.2281 610.359 52.2281C604.071 52.2281 598.651 50.602 594.098 47.3498C589.761 44.0976 587.593 38.4605 587.593 30.4384C587.593 22.4163 589.761 16.8875 594.098 13.8522C598.651 10.6 604.071 8.97386 610.359 8.97386ZM630.848 76.9449V253.214H589.87V76.9449H630.848Z"
        fill={primaryColor}
      />
      <path
        d="M742.434 73.6927C764.765 73.6927 782.219 82.3652 794.794 99.7103H796.42L800.323 76.9449H834.471V254.515C834.471 279.665 827.316 298.745 813.006 311.754C798.914 324.763 777.774 331.267 749.589 331.267C737.013 331.267 725.414 330.4 714.79 328.665C704.166 327.148 694.084 324.437 684.544 320.535V286.712C704.708 295.818 727.257 300.371 752.19 300.371C765.633 300.371 775.823 296.577 782.761 288.988C789.916 281.617 793.493 270.776 793.493 256.466V251.913C793.493 248.661 793.602 244.867 793.818 240.531C794.035 236.194 794.36 232.725 794.794 230.124H793.493C787.422 239.23 779.942 245.951 771.053 250.287C762.38 254.407 752.516 256.466 741.458 256.466C719.993 256.466 703.19 248.444 691.049 232.4C678.907 216.356 672.837 194.024 672.837 165.405C672.837 136.785 678.907 114.345 691.049 98.0842C703.407 81.8232 720.536 73.6927 742.434 73.6927ZM753.166 107.19C727.582 107.19 714.79 126.812 714.79 166.055C714.79 204.648 727.799 223.944 753.816 223.944C768.126 223.944 778.642 220.042 785.363 212.236C792.301 204.214 795.77 190.772 795.77 171.909V165.73C795.77 144.916 792.301 129.956 785.363 120.85C778.642 111.743 767.909 107.19 753.166 107.19Z"
        fill={primaryColor}
      />
      <path
        d="M981.138 73.6927C1000.87 73.6927 1016.26 78.7878 1027.32 88.978C1038.59 99.1683 1044.23 115.646 1044.23 138.411V253.214H1003.25V146.542C1003.25 120.091 992.412 106.865 970.731 106.865C954.253 106.865 942.979 112.069 936.908 122.476C930.837 132.883 927.802 147.734 927.802 167.031V253.214H886.824V76.9449H918.37L924.224 100.036H926.501C932.138 90.9294 939.835 84.3165 949.591 80.1971C959.565 75.8608 970.08 73.6927 981.138 73.6927Z"
        fill={primaryColor}
      />
      <path
        d="M1274.36 21.007L1194.03 253.214H1149.47L1069.14 21.007H1111.75L1158.58 163.128C1159.88 167.031 1161.4 172.126 1163.13 178.414C1164.87 184.484 1166.49 190.772 1168.01 197.276C1169.74 203.781 1171.04 209.418 1171.91 214.188C1172.56 209.418 1173.65 203.781 1175.16 197.276C1176.68 190.772 1178.31 184.484 1180.04 178.414C1181.78 172.126 1183.4 167.031 1184.92 163.128L1231.75 21.007H1274.36Z"
        fill={primaryColor}
      />
      <path
        d="M1364.55 73.6927C1387.31 73.6927 1404.55 78.6794 1416.26 88.6528C1428.18 98.6262 1434.15 114.02 1434.15 134.834V253.214H1405.2L1397.07 228.823H1395.77C1388.18 238.362 1380.16 245.409 1371.7 249.962C1363.25 254.298 1351.76 256.466 1337.23 256.466C1321.4 256.466 1308.29 252.022 1297.88 243.132C1287.47 234.243 1282.27 220.475 1282.27 201.829C1282.27 183.617 1288.88 169.958 1302.11 160.852C1315.55 151.746 1335.82 146.65 1362.92 145.566L1393.49 144.591V135.81C1393.49 124.752 1390.78 116.839 1385.36 112.069C1379.94 107.082 1372.35 104.589 1362.6 104.589C1353.71 104.589 1345.14 105.889 1336.91 108.491C1328.88 111.093 1320.86 114.128 1312.84 117.597L1299.83 88.978C1308.5 84.4249 1318.37 80.7391 1329.43 77.9205C1340.7 75.102 1352.41 73.6927 1364.55 73.6927ZM1393.49 169.958L1372.03 170.608C1354.03 171.259 1341.57 174.403 1334.63 180.04C1327.69 185.46 1324.22 192.832 1324.22 202.155C1324.22 210.61 1326.61 216.681 1331.38 220.367C1336.36 223.836 1342.87 225.57 1350.89 225.57C1362.81 225.57 1372.9 222.21 1381.14 215.489C1389.37 208.551 1393.49 198.36 1393.49 184.918V169.958Z"
        fill={primaryColor}
      />
      <path
        d="M1526.47 253.214H1485.5V6.04688H1526.47V253.214Z"
        fill={primaryColor}
      />
      <path
        d="M1619.85 253.214H1578.87V6.04688H1619.85V253.214Z"
        fill={primaryColor}
      />
      <path
        d="M1743.14 73.6927C1766.77 73.6927 1785.42 80.7391 1799.08 94.832C1812.96 108.925 1819.89 128.546 1819.89 153.697V174.511H1703.46C1703.9 190.555 1708.34 203.022 1716.8 211.911C1725.25 220.584 1736.96 224.92 1751.92 224.92C1763.2 224.92 1773.39 223.836 1782.49 221.668C1791.6 219.283 1800.92 215.922 1810.46 211.586V244.433C1801.79 248.553 1792.79 251.588 1783.47 253.539C1774.15 255.491 1762.98 256.466 1749.97 256.466C1732.84 256.466 1717.56 253.214 1704.11 246.71C1690.89 239.989 1680.48 229.907 1672.89 216.464C1665.52 203.022 1661.84 186.327 1661.84 166.38C1661.84 136.243 1669.32 113.261 1684.28 97.4338C1699.24 81.6064 1718.86 73.6927 1743.14 73.6927ZM1743.14 103.938C1732.08 103.938 1723.09 107.516 1716.15 114.67C1709.43 121.608 1705.52 132.015 1704.44 145.892H1780.22C1780 133.75 1776.86 123.777 1770.78 115.971C1764.93 107.949 1755.72 103.938 1743.14 103.938Z"
        fill={primaryColor}
      />
      <path
        d="M1828.52 76.9449H1873.07L1908.2 176.462C1910.58 182.75 1912.53 188.929 1914.05 195C1915.57 201.071 1916.65 207.25 1917.3 213.537H1918.6C1919.47 208.117 1920.77 202.155 1922.51 195.65C1924.24 189.146 1926.19 182.75 1928.36 176.462L1962.18 76.9449H2006.41L1931.29 276.63C1924.57 294.192 1915.35 307.634 1903.64 316.957C1892.15 326.497 1877.95 331.267 1861.04 331.267C1855.62 331.267 1850.96 330.942 1847.06 330.291C1843.15 329.858 1839.68 329.316 1836.65 328.665V296.143C1839.03 296.577 1841.85 297.011 1845.11 297.444C1848.57 297.878 1852.15 298.095 1855.84 298.095C1866.03 298.095 1874.05 295.059 1879.9 288.988C1885.97 283.134 1890.64 275.763 1893.89 266.873L1899.09 252.564L1828.52 76.9449Z"
        fill={primaryColor}
      />
      <path
        d="M195.12 134.127C195.12 173.37 184.171 202.965 162.273 222.912C140.375 242.642 109.804 252.507 70.5611 252.507H5.19189V20.3H77.3908C101.24 20.3 121.946 24.6362 139.508 33.3088C157.287 41.9813 170.946 54.7733 180.486 71.6848C190.242 88.3794 195.12 109.193 195.12 134.127ZM151.541 135.428C151.541 107.676 145.145 87.2953 132.353 74.2865C119.778 61.2777 101.457 54.7733 77.3908 54.7733H46.8201V217.709H71.862C124.981 217.709 151.541 190.282 151.541 135.428Z"
        fill={primaryColor}
      />
      <path
        d="M311.656 72.9856C335.289 72.9856 353.935 80.0321 367.594 94.125C381.47 108.218 388.408 127.839 388.408 152.99V173.804H271.979C272.413 189.848 276.857 202.315 285.313 211.204C293.769 219.877 305.477 224.213 320.437 224.213C331.711 224.213 341.901 223.129 351.008 220.961C360.114 218.576 369.437 215.215 378.977 210.879V243.726C370.304 247.846 361.306 250.881 351.983 252.832C342.66 254.784 331.494 255.759 318.486 255.759C301.357 255.759 286.072 252.507 272.63 246.003C259.404 239.282 248.997 229.2 241.408 215.757C234.037 202.315 230.351 185.62 230.351 165.673C230.351 135.536 237.831 112.554 252.791 96.7267C267.751 80.8993 287.373 72.9856 311.656 72.9856ZM311.656 103.231C300.599 103.231 291.601 106.809 284.663 113.963C277.941 120.901 274.039 131.308 272.955 145.185H348.731C348.514 133.043 345.37 123.07 339.3 115.264C333.446 107.242 324.231 103.231 311.656 103.231Z"
        fill={primaryColor}
      />
      <path
        d="M549.735 201.448C549.735 219.226 543.339 232.777 530.547 242.1C517.972 251.206 499.868 255.759 476.235 255.759C463.877 255.759 453.253 254.892 444.364 253.158C435.691 251.423 427.235 248.713 418.996 245.027V210.229C427.669 214.348 437.209 217.817 447.616 220.636C458.24 223.454 468.213 224.863 477.536 224.863C488.81 224.863 496.941 223.129 501.928 219.66C506.914 216.191 509.408 211.529 509.408 205.675C509.408 202.206 508.432 199.171 506.481 196.569C504.529 193.751 500.627 190.715 494.773 187.463C489.136 184.211 480.68 180.2 469.406 175.43C458.131 171.094 448.7 166.649 441.111 162.096C433.74 157.326 428.103 151.797 424.2 145.51C420.514 139.222 418.671 131.092 418.671 121.118C418.671 105.291 424.85 93.3661 437.209 85.344C449.784 77.1051 466.479 72.9856 487.293 72.9856C498.133 72.9856 508.324 74.0697 517.863 76.2378C527.62 78.406 537.377 81.6582 547.133 85.9945L534.124 116.24C525.885 112.771 517.863 109.844 510.058 107.459C502.253 105.074 494.231 103.882 485.992 103.882C467.996 103.882 458.999 108.977 458.999 119.167C458.999 122.853 460.083 125.997 462.251 128.598C464.636 131.2 468.647 134.019 474.284 137.054C480.138 139.873 488.377 143.342 499.001 147.461C509.408 151.581 518.405 155.917 525.994 160.47C533.582 164.806 539.436 170.226 543.556 176.731C547.675 183.235 549.735 191.474 549.735 201.448Z"
        fill={primaryColor}
      />
      <path
        d="M609.652 8.26683C615.939 8.26683 621.251 9.89293 625.587 13.1451C630.141 16.1805 632.417 21.7093 632.417 29.7314C632.417 37.7535 630.141 43.3906 625.587 46.6428C621.251 49.895 615.939 51.5211 609.652 51.5211C603.364 51.5211 597.944 49.895 593.391 46.6428C589.054 43.3906 586.886 37.7535 586.886 29.7314C586.886 21.7093 589.054 16.1805 593.391 13.1451C597.944 9.89293 603.364 8.26683 609.652 8.26683ZM630.141 76.2378V252.507H589.163V76.2378H630.141Z"
        fill={primaryColor}
      />
      <path
        d="M741.727 72.9856C764.058 72.9856 781.512 81.6582 794.087 99.0033H795.713L799.616 76.2378H833.764V253.808C833.764 278.958 826.609 298.038 812.299 311.047C798.206 324.056 777.067 330.56 748.881 330.56C736.306 330.56 724.707 329.693 714.083 327.958C703.459 326.441 693.377 323.73 683.837 319.828V286.005C704.001 295.111 726.55 299.664 751.483 299.664C764.926 299.664 775.116 295.87 782.054 288.281C789.209 280.91 792.786 270.069 792.786 255.759V251.206C792.786 247.954 792.895 244.16 793.111 239.824C793.328 235.487 793.653 232.018 794.087 229.417H792.786C786.715 238.523 779.235 245.244 770.346 249.58C761.673 253.7 751.808 255.759 740.751 255.759C719.286 255.759 702.483 247.737 690.342 231.693C678.2 215.649 672.13 193.317 672.13 164.698C672.13 136.078 678.2 113.638 690.342 97.3772C702.7 81.1161 719.828 72.9856 741.727 72.9856ZM752.459 106.483C726.875 106.483 714.083 126.105 714.083 165.348C714.083 203.941 727.092 223.237 753.109 223.237C767.419 223.237 777.935 219.335 784.656 211.529C791.594 203.507 795.063 190.065 795.063 171.202V165.023C795.063 144.209 791.594 129.249 784.656 120.143C777.935 111.036 767.202 106.483 752.459 106.483Z"
        fill={primaryColor}
      />
      <path
        d="M980.431 72.9856C1000.16 72.9856 1015.55 78.0808 1026.61 88.271C1037.89 98.4612 1043.52 114.939 1043.52 137.704V252.507H1002.55V145.835C1002.55 119.384 991.705 106.158 970.024 106.158C953.546 106.158 942.271 111.362 936.201 121.769C930.13 132.176 927.094 147.027 927.094 166.324V252.507H886.117V76.2378H917.663L923.517 99.3285H925.794C931.431 90.2223 939.128 83.6095 948.884 79.4901C958.858 75.1538 969.373 72.9856 980.431 72.9856Z"
        fill={primaryColor}
      />
      <path
        d="M1273.65 20.3L1193.32 252.507H1148.77L1068.44 20.3H1111.04L1157.87 162.421C1159.17 166.324 1160.69 171.419 1162.42 177.707C1164.16 183.777 1165.78 190.065 1167.3 196.569C1169.04 203.074 1170.34 208.711 1171.21 213.481C1171.86 208.711 1172.94 203.074 1174.46 196.569C1175.98 190.065 1177.6 183.777 1179.34 177.707C1181.07 171.419 1182.7 166.324 1184.21 162.421L1231.05 20.3H1273.65Z"
        fill={primaryColor}
      />
      <path
        d="M1363.84 72.9856C1386.61 72.9856 1403.84 77.9723 1415.55 87.9458C1427.48 97.9192 1433.44 113.313 1433.44 134.127V252.507H1404.49L1396.36 228.116H1395.06C1387.47 237.655 1379.45 244.702 1371 249.255C1362.54 253.591 1351.05 255.759 1336.52 255.759C1320.7 255.759 1307.58 251.315 1297.17 242.425C1286.77 233.536 1281.56 219.768 1281.56 201.122C1281.56 182.91 1288.17 169.251 1301.4 160.145C1314.84 151.038 1335.11 145.943 1362.22 144.859L1392.79 143.884V135.103C1392.79 124.045 1390.08 116.132 1384.66 111.362C1379.24 106.375 1371.65 103.882 1361.89 103.882C1353 103.882 1344.44 105.182 1336.2 107.784C1328.18 110.386 1320.15 113.421 1312.13 116.89L1299.12 88.271C1307.8 83.7179 1317.66 80.0321 1328.72 77.2135C1339.99 74.3949 1351.7 72.9856 1363.84 72.9856ZM1392.79 169.251L1371.32 169.901C1353.33 170.552 1340.86 173.695 1333.92 179.333C1326.98 184.753 1323.51 192.125 1323.51 201.448C1323.51 209.903 1325.9 215.974 1330.67 219.66C1335.66 223.129 1342.16 224.863 1350.18 224.863C1362.11 224.863 1372.19 221.503 1380.43 214.782C1388.67 207.844 1392.79 197.653 1392.79 184.211V169.251Z"
        fill={primaryColor}
      />
      <path
        d="M1525.77 252.507H1484.79V5.33984H1525.77V252.507Z"
        fill={primaryColor}
      />
      <path
        d="M1619.14 252.507H1578.16V5.33984H1619.14V252.507Z"
        fill={primaryColor}
      />
      <path
        d="M1742.43 72.9856C1766.07 72.9856 1784.71 80.0321 1798.37 94.125C1812.25 108.218 1819.19 127.839 1819.19 152.99V173.804H1702.76C1703.19 189.848 1707.64 202.315 1716.09 211.204C1724.55 219.877 1736.25 224.213 1751.21 224.213C1762.49 224.213 1772.68 223.129 1781.79 220.961C1790.89 218.576 1800.21 215.215 1809.75 210.879V243.726C1801.08 247.846 1792.08 250.881 1782.76 252.832C1773.44 254.784 1762.27 255.759 1749.26 255.759C1732.14 255.759 1716.85 252.507 1703.41 246.003C1690.18 239.282 1679.78 229.2 1672.19 215.757C1664.81 202.315 1661.13 185.62 1661.13 165.673C1661.13 135.536 1668.61 112.554 1683.57 96.7267C1698.53 80.8993 1718.15 72.9856 1742.43 72.9856ZM1742.43 103.231C1731.38 103.231 1722.38 106.809 1715.44 113.963C1708.72 120.901 1704.82 131.308 1703.73 145.185H1779.51C1779.29 133.043 1776.15 123.07 1770.08 115.264C1764.22 107.242 1755.01 103.231 1742.43 103.231Z"
        fill={primaryColor}
      />
      <path
        d="M1827.81 76.2378H1872.37L1907.49 175.755C1909.88 182.043 1911.83 188.222 1913.34 194.293C1914.86 200.364 1915.95 206.543 1916.6 212.83H1917.9C1918.77 207.41 1920.07 201.448 1921.8 194.943C1923.54 188.439 1925.49 182.043 1927.65 175.755L1961.48 76.2378H2005.71L1930.58 275.923C1923.86 293.485 1914.65 306.927 1902.94 316.25C1891.45 325.79 1877.25 330.56 1860.33 330.56C1854.91 330.56 1850.25 330.235 1846.35 329.584C1842.45 329.151 1838.98 328.609 1835.94 327.958V295.436C1838.33 295.87 1841.15 296.303 1844.4 296.737C1847.87 297.171 1851.44 297.388 1855.13 297.388C1865.32 297.388 1873.34 294.352 1879.2 288.281C1885.27 282.427 1889.93 275.056 1893.18 266.166L1898.38 251.857L1827.81 76.2378Z"
        fill={primaryColor}
      />
      <path
        d="M194.413 133.42C194.413 172.663 183.464 202.258 161.566 222.205C139.668 241.935 109.097 251.8 69.8541 251.8H4.48486V19.5929H76.6837C100.533 19.5929 121.239 23.9292 138.801 32.6018C156.579 41.2743 170.239 54.0663 179.779 70.9777C189.535 87.6724 194.413 108.486 194.413 133.42ZM150.834 134.721C150.834 106.969 144.438 86.5883 131.646 73.5795C119.071 60.5707 100.75 54.0663 76.6837 54.0663H46.113V217.002H71.155C124.274 217.002 150.834 189.575 150.834 134.721Z"
        fill={primaryColor}
      />
      <path
        d="M310.949 72.2786C334.582 72.2786 353.228 79.3251 366.887 93.4179C380.763 107.511 387.701 127.132 387.701 152.283V173.097H271.272C271.706 189.141 276.15 201.608 284.606 210.497C293.062 219.17 304.77 223.506 319.73 223.506C331.004 223.506 341.194 222.422 350.301 220.254C359.407 217.869 368.73 214.508 378.27 210.172V243.019C369.597 247.139 360.599 250.174 351.276 252.125C341.953 254.077 330.787 255.052 317.779 255.052C300.65 255.052 285.365 251.8 271.923 245.296C258.697 238.575 248.29 228.493 240.701 215.05C233.33 201.608 229.644 184.913 229.644 164.966C229.644 134.829 237.124 111.847 252.084 96.0197C267.044 80.1923 286.666 72.2786 310.949 72.2786ZM310.949 102.524C299.891 102.524 290.894 106.102 283.956 113.256C277.234 120.194 273.332 130.601 272.248 144.477H348.024C347.807 132.336 344.663 122.363 338.593 114.557C332.739 106.535 323.524 102.524 310.949 102.524Z"
        fill={primaryColor}
      />
      <path
        d="M549.028 200.741C549.028 218.519 542.632 232.07 529.84 241.393C517.265 250.499 499.161 255.052 475.528 255.052C463.17 255.052 452.546 254.185 443.657 252.451C434.984 250.716 426.528 248.006 418.289 244.32V209.522C426.962 213.641 436.502 217.11 446.909 219.929C457.533 222.747 467.506 224.156 476.829 224.156C488.103 224.156 496.234 222.422 501.221 218.953C506.207 215.484 508.701 210.822 508.701 204.968C508.701 201.499 507.725 198.464 505.774 195.862C503.822 193.044 499.92 190.008 494.066 186.756C488.429 183.504 479.973 179.493 468.699 174.723C457.424 170.387 447.993 165.942 440.404 161.389C433.033 156.619 427.396 151.09 423.493 144.803C419.807 138.515 417.964 130.385 417.964 120.411C417.964 104.584 424.143 92.6591 436.502 84.637C449.077 76.3981 465.772 72.2786 486.586 72.2786C497.426 72.2786 507.617 73.3627 517.156 75.5308C526.913 77.6989 536.67 80.9512 546.426 85.2874L533.417 115.533C525.178 112.064 517.156 109.137 509.351 106.752C501.546 104.367 493.524 103.175 485.285 103.175C467.289 103.175 458.291 108.27 458.291 118.46C458.291 122.146 459.376 125.289 461.544 127.891C463.929 130.493 467.94 133.312 473.577 136.347C479.431 139.166 487.67 142.635 498.294 146.754C508.701 150.873 517.698 155.21 525.287 159.763C532.875 164.099 538.729 169.519 542.849 176.024C546.968 182.528 549.028 190.767 549.028 200.741Z"
        fill={primaryColor}
      />
      <path
        d="M608.945 7.5598C615.232 7.5598 620.544 9.1859 624.88 12.4381C629.434 15.4735 631.71 21.0022 631.71 29.0243C631.71 37.0464 629.434 42.6836 624.88 45.9358C620.544 49.188 615.232 50.8141 608.945 50.8141C602.657 50.8141 597.237 49.188 592.684 45.9358C588.347 42.6836 586.179 37.0464 586.179 29.0243C586.179 21.0022 588.347 15.4735 592.684 12.4381C597.237 9.1859 602.657 7.5598 608.945 7.5598ZM629.433 75.5308V251.8H588.456V75.5308H629.433Z"
        fill={primaryColor}
      />
      <path
        d="M741.02 72.2786C763.351 72.2786 780.805 80.9511 793.38 98.2962H795.006L798.909 75.5308H833.057V253.101C833.057 278.251 825.902 297.331 811.592 310.34C797.499 323.349 776.36 329.853 748.174 329.853C735.599 329.853 724 328.986 713.376 327.251C702.752 325.734 692.67 323.023 683.13 319.121V285.298C703.294 294.404 725.843 298.957 750.776 298.957C764.219 298.957 774.409 295.163 781.347 287.574C788.502 280.203 792.079 269.362 792.079 255.052V250.499C792.079 247.247 792.188 243.453 792.404 239.117C792.621 234.78 792.946 231.311 793.38 228.71H792.079C786.008 237.816 778.528 244.537 769.639 248.873C760.966 252.993 751.101 255.052 740.044 255.052C718.579 255.052 701.776 247.03 689.635 230.986C677.493 214.942 671.422 192.61 671.422 163.991C671.422 135.371 677.493 112.931 689.635 96.6701C701.993 80.4091 719.121 72.2786 741.02 72.2786ZM751.752 105.776C726.168 105.776 713.376 125.398 713.376 164.641C713.376 203.234 726.385 222.53 752.402 222.53C766.712 222.53 777.227 218.628 783.949 210.822C790.887 202.8 794.356 189.358 794.356 170.495V164.316C794.356 143.502 790.887 128.542 783.949 119.436C777.227 110.329 766.495 105.776 751.752 105.776Z"
        fill={primaryColor}
      />
      <path
        d="M979.724 72.2786C999.454 72.2786 1014.85 77.3737 1025.9 87.564C1037.18 97.7542 1042.82 114.232 1042.82 136.997V251.8H1001.84V145.128C1001.84 118.677 990.998 105.451 969.317 105.451C952.839 105.451 941.564 110.655 935.494 121.062C929.423 131.469 926.387 146.32 926.387 165.617V251.8H885.41V75.5308H916.956L922.81 98.6215H925.087C930.724 89.5153 938.421 82.9025 948.177 78.783C958.151 74.4467 968.666 72.2786 979.724 72.2786Z"
        fill={primaryColor}
      />
      <path
        d="M1272.94 19.5929L1192.61 251.8H1148.06L1067.73 19.5929H1110.33L1157.16 161.714C1158.47 165.617 1159.98 170.712 1161.72 177C1163.45 183.07 1165.08 189.358 1166.6 195.862C1168.33 202.367 1169.63 208.004 1170.5 212.774C1171.15 208.004 1172.23 202.367 1173.75 195.862C1175.27 189.358 1176.89 183.07 1178.63 177C1180.36 170.712 1181.99 165.617 1183.51 161.714L1230.34 19.5929H1272.94Z"
        fill={primaryColor}
      />
      <path
        d="M1363.14 72.2786C1385.9 72.2786 1403.14 77.2653 1414.85 87.2387C1426.77 97.2122 1432.73 112.606 1432.73 133.42V251.8H1403.79L1395.66 227.409H1394.36C1386.77 236.948 1378.75 243.995 1370.29 248.548C1361.83 252.884 1350.34 255.052 1335.82 255.052C1319.99 255.052 1306.87 250.608 1296.47 241.718C1286.06 232.829 1280.85 219.061 1280.85 200.415C1280.85 182.203 1287.47 168.544 1300.69 159.438C1314.14 150.331 1334.41 145.236 1361.51 144.152L1392.08 143.177V134.396C1392.08 123.338 1389.37 115.424 1383.95 110.655C1378.53 105.668 1370.94 103.175 1361.18 103.175C1352.29 103.175 1343.73 104.475 1335.49 107.077C1327.47 109.679 1319.45 112.714 1311.43 116.183L1298.42 87.564C1307.09 83.0109 1316.95 79.3251 1328.01 76.5065C1339.29 73.6879 1350.99 72.2786 1363.14 72.2786ZM1392.08 168.544L1370.62 169.194C1352.62 169.845 1340.15 172.988 1333.21 178.626C1326.28 184.046 1322.81 191.418 1322.81 200.741C1322.81 209.196 1325.19 215.267 1329.96 218.953C1334.95 222.422 1341.45 224.156 1349.48 224.156C1361.4 224.156 1371.48 220.796 1379.72 214.075C1387.96 207.137 1392.08 196.946 1392.08 183.504V168.544Z"
        fill={primaryColor}
      />
      <path
        d="M1525.06 251.8H1484.08V4.63281H1525.06V251.8Z"
        fill={primaryColor}
      />
      <path
        d="M1618.43 251.8H1577.46V4.63281H1618.43V251.8Z"
        fill={primaryColor}
      />
      <path
        d="M1741.73 72.2786C1765.36 72.2786 1784.01 79.3251 1797.66 93.4179C1811.54 107.511 1818.48 127.132 1818.48 152.283V173.097H1702.05C1702.48 189.141 1706.93 201.608 1715.38 210.497C1723.84 219.17 1735.55 223.506 1750.51 223.506C1761.78 223.506 1771.97 222.422 1781.08 220.254C1790.18 217.869 1799.51 214.508 1809.05 210.172V243.019C1800.38 247.139 1791.38 250.174 1782.05 252.125C1772.73 254.077 1761.57 255.052 1748.56 255.052C1731.43 255.052 1716.14 251.8 1702.7 245.296C1689.47 238.575 1679.07 228.493 1671.48 215.05C1664.11 201.608 1660.42 184.913 1660.42 164.966C1660.42 134.829 1667.9 111.847 1682.86 96.0197C1697.82 80.1923 1717.44 72.2786 1741.73 72.2786ZM1741.73 102.524C1730.67 102.524 1721.67 106.102 1714.73 113.256C1708.01 120.194 1704.11 130.601 1703.03 144.477H1778.8C1778.59 132.336 1775.44 122.363 1769.37 114.557C1763.52 106.535 1754.3 102.524 1741.73 102.524Z"
        fill={primaryColor}
      />
      <path
        d="M1827.1 75.5308H1871.66L1906.78 175.048C1909.17 181.336 1911.12 187.515 1912.64 193.586C1914.16 199.656 1915.24 205.836 1915.89 212.123H1917.19C1918.06 206.703 1919.36 200.741 1921.09 194.236C1922.83 187.732 1924.78 181.336 1926.95 175.048L1960.77 75.5308H2005L1929.87 275.216C1923.15 292.778 1913.94 306.22 1902.23 315.543C1890.74 325.083 1876.54 329.853 1859.63 329.853C1854.21 329.853 1849.55 329.528 1845.64 328.877C1841.74 328.444 1838.27 327.902 1835.24 327.251V294.729C1837.62 295.163 1840.44 295.596 1843.69 296.03C1847.16 296.464 1850.74 296.681 1854.42 296.681C1864.61 296.681 1872.64 293.645 1878.49 287.574C1884.56 281.72 1889.22 274.349 1892.47 265.459L1897.68 251.15L1827.1 75.5308Z"
        fill={primaryColor}
      />
      <path
        d="M193.706 132.713C193.706 171.956 182.757 201.551 160.859 221.498C138.961 241.228 108.39 251.093 69.1471 251.093H3.77783V18.8859H75.9767C99.8262 18.8859 120.532 23.2222 138.094 31.8947C155.872 40.5673 169.532 53.3593 179.072 70.2707C188.828 86.9653 193.706 107.779 193.706 132.713ZM150.127 134.014C150.127 106.262 143.731 85.8813 130.939 72.8725C118.364 59.8637 100.043 53.3592 75.9767 53.3592H45.406V216.295H70.448C123.567 216.295 150.127 188.868 150.127 134.014Z"
        fill={primaryColor}
      />
      <path
        d="M310.242 71.5716C333.875 71.5716 352.521 78.618 366.18 92.7109C380.056 106.804 386.994 126.425 386.994 151.576V172.39H270.565C270.999 188.434 275.443 200.901 283.899 209.79C292.355 218.463 304.063 222.799 319.023 222.799C330.297 222.799 340.487 221.715 349.594 219.547C358.7 217.162 368.023 213.801 377.562 209.465V242.312C368.89 246.432 359.892 249.467 350.569 251.418C341.246 253.37 330.08 254.345 317.072 254.345C299.943 254.345 284.658 251.093 271.216 244.589C257.99 237.867 247.583 227.786 239.994 214.343C232.623 200.901 228.937 184.206 228.937 164.259C228.937 134.122 236.417 111.14 251.377 95.3127C266.337 79.4853 285.959 71.5716 310.242 71.5716ZM310.242 101.817C299.184 101.817 290.187 105.394 283.249 112.549C276.527 119.487 272.625 129.894 271.541 143.77H347.317C347.1 131.629 343.956 121.656 337.886 113.85C332.032 105.828 322.817 101.817 310.242 101.817Z"
        fill={primaryColor}
      />
      <path
        d="M548.321 200.034C548.321 217.812 541.925 231.363 529.133 240.686C516.558 249.792 498.454 254.345 474.821 254.345C462.463 254.345 451.839 253.478 442.95 251.744C434.277 250.009 425.821 247.299 417.582 243.613V208.814C426.255 212.934 435.795 216.403 446.202 219.222C456.826 222.04 466.799 223.449 476.122 223.449C487.396 223.449 495.527 221.715 500.513 218.246C505.5 214.777 507.994 210.115 507.994 204.261C507.994 200.792 507.018 197.757 505.067 195.155C503.115 192.337 499.213 189.301 493.359 186.049C487.721 182.797 479.266 178.786 467.991 174.016C456.717 169.68 447.286 165.235 439.697 160.682C432.326 155.912 426.689 150.383 422.786 144.096C419.1 137.808 417.257 129.678 417.257 119.704C417.257 103.877 423.436 91.952 435.795 83.93C448.37 75.691 465.065 71.5716 485.879 71.5716C496.719 71.5716 506.909 72.6556 516.449 74.8238C526.206 76.9919 535.963 80.2441 545.719 84.5804L532.71 114.826C524.471 111.357 516.449 108.43 508.644 106.045C500.839 103.66 492.817 102.468 484.578 102.468C466.582 102.468 457.584 107.563 457.584 117.753C457.584 121.439 458.669 124.582 460.837 127.184C463.222 129.786 467.233 132.605 472.87 135.64C478.724 138.459 486.963 141.928 497.587 146.047C507.994 150.166 516.991 154.503 524.58 159.056C532.168 163.392 538.022 168.812 542.142 175.317C546.261 181.821 548.321 190.06 548.321 200.034Z"
        fill={primaryColor}
      />
      <path
        d="M608.238 6.85277C614.525 6.85277 619.837 8.47887 624.173 11.7311C628.727 14.7665 631.003 20.2952 631.003 28.3173C631.003 36.3394 628.727 41.9766 624.173 45.2288C619.837 48.4809 614.525 50.107 608.238 50.107C601.95 50.107 596.53 48.4809 591.977 45.2288C587.64 41.9766 585.472 36.3394 585.472 28.3173C585.472 20.2952 587.64 14.7665 591.977 11.7311C596.53 8.47887 601.95 6.85277 608.238 6.85277ZM628.726 74.8238V251.093H587.749V74.8238H628.726Z"
        fill={primaryColor}
      />
      <path
        d="M740.313 71.5716C762.644 71.5716 780.098 80.2441 792.673 97.5892H794.299L798.202 74.8238H832.35V252.394C832.35 277.544 825.195 296.624 810.885 309.633C796.792 322.642 775.653 329.146 747.467 329.146C734.892 329.146 723.293 328.279 712.669 326.544C702.045 325.026 691.963 322.316 682.423 318.414V284.591C702.587 293.697 725.136 298.25 750.069 298.25C763.512 298.25 773.702 294.456 780.64 286.867C787.795 279.496 791.372 268.655 791.372 254.345V249.792C791.372 246.54 791.481 242.746 791.697 238.41C791.914 234.073 792.239 230.604 792.673 228.002H791.372C785.301 237.109 777.821 243.83 768.932 248.166C760.259 252.286 750.394 254.345 739.337 254.345C717.872 254.345 701.069 246.323 688.928 230.279C676.786 214.235 670.715 191.903 670.715 163.284C670.715 134.664 676.786 112.224 688.928 95.9631C701.286 79.7021 718.414 71.5716 740.313 71.5716ZM751.045 105.069C725.461 105.069 712.669 124.691 712.669 163.934C712.669 202.527 725.678 221.823 751.695 221.823C766.005 221.823 776.52 217.921 783.242 210.115C790.18 202.093 793.649 188.651 793.649 169.788V163.609C793.649 142.795 790.18 127.835 783.242 118.729C776.52 109.622 765.788 105.069 751.045 105.069Z"
        fill={primaryColor}
      />
      <path
        d="M979.017 71.5716C998.747 71.5716 1014.14 76.6667 1025.2 86.8569C1036.47 97.0472 1042.11 113.525 1042.11 136.29V251.093H1001.13V144.421C1001.13 117.97 990.291 104.744 968.609 104.744C952.132 104.744 940.857 109.948 934.787 120.355C928.716 130.762 925.68 145.613 925.68 164.91V251.093H884.703V74.8238H916.249L922.103 97.9144H924.38C930.017 88.8083 937.714 82.1954 947.47 78.076C957.444 73.7397 967.959 71.5716 979.017 71.5716Z"
        fill={primaryColor}
      />
      <path
        d="M1272.24 18.8859L1191.91 251.093H1147.35L1067.02 18.8859H1109.63L1156.46 161.007C1157.76 164.91 1159.28 170.005 1161.01 176.292C1162.74 182.363 1164.37 188.651 1165.89 195.155C1167.62 201.66 1168.92 207.297 1169.79 212.067C1170.44 207.297 1171.53 201.66 1173.04 195.155C1174.56 188.651 1176.19 182.363 1177.92 176.292C1179.66 170.005 1181.28 164.91 1182.8 161.007L1229.63 18.8859H1272.24Z"
        fill={primaryColor}
      />
      <path
        d="M1362.43 71.5716C1385.19 71.5716 1402.43 76.5583 1414.14 86.5317C1426.06 96.5051 1432.03 111.899 1432.03 132.713V251.093H1403.08L1394.95 226.702H1393.65C1386.06 236.241 1378.04 243.288 1369.58 247.841C1361.13 252.177 1349.64 254.345 1335.11 254.345C1319.28 254.345 1306.17 249.901 1295.76 241.011C1285.35 232.122 1280.15 218.354 1280.15 199.708C1280.15 181.496 1286.76 167.837 1299.99 158.731C1313.43 149.624 1333.7 144.529 1360.8 143.445L1391.37 142.47V133.689C1391.37 122.631 1388.66 114.717 1383.24 109.948C1377.82 104.961 1370.23 102.468 1360.48 102.468C1351.59 102.468 1343.02 103.768 1334.78 106.37C1326.76 108.972 1318.74 112.007 1310.72 115.476L1297.71 86.8569C1306.38 82.3039 1316.25 78.618 1327.3 75.7994C1338.58 72.9809 1350.29 71.5716 1362.43 71.5716ZM1391.37 167.837L1369.91 168.487C1351.91 169.138 1339.45 172.281 1332.51 177.919C1325.57 183.339 1322.1 190.711 1322.1 200.034C1322.1 208.489 1324.49 214.56 1329.26 218.246C1334.24 221.715 1340.75 223.449 1348.77 223.449C1360.69 223.449 1370.78 220.089 1379.01 213.368C1387.25 206.43 1391.37 196.239 1391.37 182.797V167.837Z"
        fill={primaryColor}
      />
      <path
        d="M1524.35 251.093H1483.37V3.92578H1524.35V251.093Z"
        fill={primaryColor}
      />
      <path
        d="M1617.73 251.093H1576.75V3.92578H1617.73V251.093Z"
        fill={primaryColor}
      />
      <path
        d="M1741.02 71.5716C1764.65 71.5716 1783.3 78.618 1796.96 92.7109C1810.83 106.804 1817.77 126.425 1817.77 151.576V172.39H1701.34C1701.78 188.434 1706.22 200.901 1714.68 209.79C1723.13 218.463 1734.84 222.799 1749.8 222.799C1761.08 222.799 1771.27 221.715 1780.37 219.547C1789.48 217.162 1798.8 213.801 1808.34 209.465V242.312C1799.67 246.432 1790.67 249.467 1781.35 251.418C1772.02 253.37 1760.86 254.345 1747.85 254.345C1730.72 254.345 1715.44 251.093 1701.99 244.589C1688.77 237.867 1678.36 227.786 1670.77 214.343C1663.4 200.901 1659.71 184.206 1659.71 164.259C1659.71 134.122 1667.2 111.14 1682.16 95.3127C1697.12 79.4853 1716.74 71.5716 1741.02 71.5716ZM1741.02 101.817C1729.96 101.817 1720.96 105.394 1714.03 112.549C1707.31 119.487 1703.4 129.894 1702.32 143.77H1778.1C1777.88 131.629 1774.73 121.656 1768.66 113.85C1762.81 105.828 1753.6 101.817 1741.02 101.817Z"
        fill={primaryColor}
      />
      <path
        d="M1826.4 74.8238H1870.95L1906.08 174.341C1908.46 180.629 1910.41 186.808 1911.93 192.879C1913.45 198.949 1914.53 205.129 1915.18 211.416H1916.48C1917.35 205.996 1918.65 200.034 1920.39 193.529C1922.12 187.025 1924.07 180.629 1926.24 174.341L1960.06 74.8238H2004.29L1929.17 274.509C1922.45 292.071 1913.23 305.513 1901.52 314.836C1890.03 324.376 1875.83 329.146 1858.92 329.146C1853.5 329.146 1848.84 328.821 1844.94 328.17C1841.03 327.737 1837.56 327.195 1834.53 326.544V294.022C1836.91 294.456 1839.73 294.889 1842.98 295.323C1846.45 295.757 1850.03 295.973 1853.72 295.973C1863.91 295.973 1871.93 292.938 1877.78 286.867C1883.85 281.013 1888.52 273.642 1891.77 264.752L1896.97 250.443L1826.4 74.8238Z"
        fill={primaryColor}
      />
      <path
        d="M192.999 132.006C192.999 171.249 182.05 200.844 160.152 220.791C138.254 240.521 107.683 250.386 68.44 250.386H3.0708V18.1789H75.2697C99.1192 18.1789 119.825 22.5151 137.387 31.1877C155.165 39.8602 168.825 52.6522 178.364 69.5637C188.121 86.2583 192.999 107.072 192.999 132.006ZM149.42 133.307C149.42 105.555 143.024 85.1742 130.232 72.1654C117.657 59.1566 99.336 52.6522 75.2697 52.6522H44.699V215.588H69.7409C122.86 215.588 149.42 188.161 149.42 133.307Z"
        fill={primaryColor}
      />
      <path
        d="M309.535 70.8645C333.168 70.8645 351.814 77.911 365.473 92.0039C379.349 106.097 386.287 125.718 386.287 150.869V171.683H269.858C270.292 187.727 274.736 200.194 283.192 209.083C291.648 217.756 303.356 222.092 318.316 222.092C329.59 222.092 339.78 221.008 348.887 218.84C357.993 216.455 367.316 213.094 376.855 208.758V241.605C368.183 245.725 359.185 248.76 349.862 250.711C340.539 252.663 329.373 253.638 316.365 253.638C299.236 253.638 283.951 250.386 270.508 243.882C257.283 237.16 246.876 227.079 239.287 213.636C231.916 200.194 228.23 183.499 228.23 163.552C228.23 133.415 235.71 110.433 250.67 94.6056C265.63 78.7782 285.252 70.8645 309.535 70.8645ZM309.535 101.11C298.477 101.11 289.48 104.687 282.542 111.842C275.82 118.78 271.918 129.187 270.834 143.063H346.61C346.393 130.922 343.249 120.948 337.179 113.143C331.325 105.121 322.11 101.11 309.535 101.11Z"
        fill={primaryColor}
      />
      <path
        d="M547.614 199.327C547.614 217.105 541.218 230.656 528.426 239.979C515.851 249.085 497.747 253.638 474.114 253.638C461.756 253.638 451.132 252.771 442.243 251.037C433.57 249.302 425.114 246.592 416.875 242.906V208.107C425.548 212.227 435.088 215.696 445.495 218.514C456.119 221.333 466.092 222.742 475.415 222.742C486.689 222.742 494.82 221.008 499.806 217.539C504.793 214.07 507.287 209.408 507.287 203.554C507.287 200.085 506.311 197.05 504.36 194.448C502.408 191.63 498.506 188.594 492.652 185.342C487.014 182.09 478.559 178.079 467.284 173.309C456.01 168.973 446.579 164.528 438.99 159.975C431.619 155.205 425.982 149.676 422.079 143.389C418.393 137.101 416.55 128.971 416.55 118.997C416.55 103.17 422.729 91.245 435.088 83.2229C447.663 74.984 464.357 70.8645 485.172 70.8645C496.012 70.8645 506.202 71.9486 515.742 74.1167C525.499 76.2849 535.255 79.5371 545.012 83.8734L532.003 114.119C523.764 110.65 515.742 107.723 507.937 105.338C500.132 102.953 492.11 101.76 483.871 101.76C465.875 101.76 456.877 106.856 456.877 117.046C456.877 120.732 457.961 123.875 460.13 126.477C462.515 129.079 466.526 131.898 472.163 134.933C478.017 137.751 486.256 141.221 496.88 145.34C507.287 149.459 516.284 153.796 523.873 158.349C531.461 162.685 537.315 168.105 541.435 174.61C545.554 181.114 547.614 189.353 547.614 199.327Z"
        fill={primaryColor}
      />
      <path
        d="M607.531 6.14574C613.818 6.14574 619.13 7.77183 623.466 11.024C628.019 14.0594 630.296 19.5882 630.296 27.6103C630.296 35.6324 628.019 41.2695 623.466 44.5217C619.13 47.7739 613.818 49.4 607.531 49.4C601.243 49.4 595.823 47.7739 591.27 44.5217C586.933 41.2695 584.765 35.6324 584.765 27.6103C584.765 19.5882 586.933 14.0594 591.27 11.024C595.823 7.77183 601.243 6.14574 607.531 6.14574ZM628.019 74.1167V250.386H587.042V74.1167H628.019Z"
        fill={primaryColor}
      />
      <path
        d="M739.606 70.8645C761.937 70.8645 779.391 79.5371 791.966 96.8822H793.592L797.495 74.1167H831.643V251.687C831.643 276.837 824.488 295.917 810.178 308.926C796.085 321.935 774.946 328.439 746.76 328.439C734.185 328.439 722.586 327.572 711.962 325.837C701.338 324.319 691.256 321.609 681.716 317.707V283.884C701.88 292.99 724.429 297.543 749.362 297.543C762.805 297.543 772.995 293.749 779.933 286.16C787.088 278.789 790.665 267.948 790.665 253.638V249.085C790.665 245.833 790.774 242.039 790.99 237.702C791.207 233.366 791.532 229.897 791.966 227.295H790.665C784.594 236.402 777.114 243.123 768.225 247.459C759.552 251.579 749.687 253.638 738.63 253.638C717.165 253.638 700.362 245.616 688.221 229.572C676.079 213.528 670.008 191.196 670.008 162.577C670.008 133.957 676.079 111.517 688.221 95.2561C700.579 78.9951 717.707 70.8645 739.606 70.8645ZM750.338 104.362C724.754 104.362 711.962 123.984 711.962 163.227C711.962 201.82 724.971 221.116 750.988 221.116C765.298 221.116 775.813 217.214 782.535 209.408C789.473 201.386 792.942 187.944 792.942 169.081V162.902C792.942 142.088 789.473 127.128 782.535 118.021C775.813 108.915 765.081 104.362 750.338 104.362Z"
        fill={primaryColor}
      />
      <path
        d="M978.31 70.8645C998.04 70.8645 1013.43 75.9597 1024.49 86.1499C1035.77 96.3401 1041.4 112.818 1041.4 135.583V250.386H1000.42V143.714C1000.42 117.263 989.584 104.037 967.902 104.037C951.425 104.037 940.15 109.241 934.08 119.648C928.009 130.055 924.973 144.906 924.973 164.203V250.386H883.996V74.1167H915.542L921.396 97.2074H923.673C929.31 88.1012 937.007 81.4884 946.763 77.369C956.737 73.0327 967.252 70.8645 978.31 70.8645Z"
        fill={primaryColor}
      />
      <path
        d="M1271.53 18.1789L1191.2 250.386H1146.64L1066.31 18.1789H1108.92L1155.75 160.3C1157.05 164.203 1158.57 169.298 1160.3 175.585C1162.04 181.656 1163.66 187.944 1165.18 194.448C1166.92 200.953 1168.22 206.59 1169.08 211.36C1169.73 206.59 1170.82 200.953 1172.34 194.448C1173.85 187.944 1175.48 181.656 1177.21 175.585C1178.95 169.298 1180.58 164.203 1182.09 160.3L1228.92 18.1789H1271.53Z"
        fill={primaryColor}
      />
      <path
        d="M1361.72 70.8645C1384.49 70.8645 1401.72 75.8512 1413.43 85.8247C1425.36 95.7981 1431.32 111.192 1431.32 132.006V250.386H1402.37L1394.24 225.995H1392.94C1385.35 235.534 1377.33 242.581 1368.88 247.134C1360.42 251.47 1348.93 253.638 1334.4 253.638C1318.58 253.638 1305.46 249.194 1295.05 240.304C1284.64 231.415 1279.44 217.647 1279.44 199.001C1279.44 180.789 1286.05 167.13 1299.28 158.024C1312.72 148.917 1332.99 143.822 1360.09 142.738L1390.67 141.763V132.982C1390.67 121.924 1387.96 114.01 1382.54 109.241C1377.11 104.254 1369.53 101.76 1359.77 101.76C1350.88 101.76 1342.32 103.061 1334.08 105.663C1326.06 108.265 1318.03 111.3 1310.01 114.769L1297 86.1499C1305.67 81.5968 1315.54 77.911 1326.6 75.0924C1337.87 72.2738 1349.58 70.8645 1361.72 70.8645ZM1390.67 167.13L1369.2 167.78C1351.21 168.431 1338.74 171.574 1331.8 177.212C1324.86 182.632 1321.39 190.004 1321.39 199.327C1321.39 207.782 1323.78 213.853 1328.55 217.539C1333.54 221.008 1340.04 222.742 1348.06 222.742C1359.99 222.742 1370.07 219.382 1378.31 212.661C1386.55 205.723 1390.67 195.532 1390.67 182.09V167.13Z"
        fill={primaryColor}
      />
      <path
        d="M1523.65 250.386H1482.67V3.21875H1523.65V250.386Z"
        fill={primaryColor}
      />
      <path
        d="M1617.02 250.386H1576.04V3.21875H1617.02V250.386Z"
        fill={primaryColor}
      />
      <path
        d="M1740.31 70.8645C1763.95 70.8645 1782.59 77.911 1796.25 92.0039C1810.13 106.097 1817.06 125.718 1817.06 150.869V171.683H1700.64C1701.07 187.727 1705.51 200.194 1713.97 209.083C1722.43 217.756 1734.13 222.092 1749.09 222.092C1760.37 222.092 1770.56 221.008 1779.66 218.84C1788.77 216.455 1798.09 213.094 1807.63 208.758V241.605C1798.96 245.725 1789.96 248.76 1780.64 250.711C1771.32 252.663 1760.15 253.638 1747.14 253.638C1730.01 253.638 1714.73 250.386 1701.29 243.882C1688.06 237.16 1677.65 227.079 1670.07 213.636C1662.69 200.194 1659.01 183.499 1659.01 163.552C1659.01 133.415 1666.49 110.433 1681.45 94.6056C1696.41 78.7782 1716.03 70.8645 1740.31 70.8645ZM1740.31 101.11C1729.26 101.11 1720.26 104.687 1713.32 111.842C1706.6 118.78 1702.7 129.187 1701.61 143.063H1777.39C1777.17 130.922 1774.03 120.948 1767.96 113.143C1762.1 105.121 1752.89 101.11 1740.31 101.11Z"
        fill={primaryColor}
      />
      <path
        d="M1825.69 74.1167H1870.25L1905.37 173.634C1907.75 179.922 1909.71 186.101 1911.22 192.172C1912.74 198.242 1913.83 204.422 1914.48 210.709H1915.78C1916.64 205.289 1917.95 199.327 1919.68 192.822C1921.41 186.318 1923.37 179.922 1925.53 173.634L1959.36 74.1167H2003.59L1928.46 273.802C1921.74 291.364 1912.52 304.806 1900.82 314.129C1889.33 323.669 1875.12 328.439 1858.21 328.439C1852.79 328.439 1848.13 328.114 1844.23 327.463C1840.33 327.03 1836.86 326.488 1833.82 325.837V293.315C1836.21 293.749 1839.02 294.182 1842.28 294.616C1845.75 295.05 1849.32 295.266 1853.01 295.266C1863.2 295.266 1871.22 292.231 1877.08 286.16C1883.15 280.306 1887.81 272.935 1891.06 264.045L1896.26 249.736L1825.69 74.1167Z"
        fill={primaryColor}
      />
      <path
        d="M192.292 131.298C192.292 170.542 181.343 200.137 159.445 220.084C137.546 239.814 106.976 249.679 67.7325 249.679H2.36328V17.4714H74.5622C98.4116 17.4714 119.117 21.8076 136.679 30.4802C154.458 39.1527 168.117 51.9447 177.657 68.8561C187.414 85.5508 192.292 106.365 192.292 131.298ZM148.712 132.599C148.712 104.847 142.316 84.4667 129.524 71.4579C116.949 58.4491 98.6285 51.9447 74.5622 51.9447H43.9915V214.88H69.0334C122.153 214.88 148.712 187.453 148.712 132.599Z"
        fill={primaryColor}
      />
      <path
        d="M308.827 70.157C332.46 70.157 351.106 77.2035 364.765 91.2963C378.641 105.389 385.579 125.011 385.579 150.161V170.975H269.151C269.584 187.019 274.029 199.486 282.485 208.376C290.94 217.048 302.648 221.384 317.608 221.384C328.883 221.384 339.073 220.3 348.179 218.132C357.285 215.747 366.608 212.387 376.148 208.05V240.898C367.475 245.017 358.478 248.052 349.155 250.004C339.832 251.955 328.666 252.931 315.657 252.931C298.529 252.931 283.243 249.679 269.801 243.174C256.575 236.453 246.168 226.371 238.58 212.929C231.208 199.486 227.522 182.792 227.522 162.845C227.522 132.708 235.002 109.725 249.963 93.8981C264.923 78.0707 284.544 70.157 308.827 70.157ZM308.827 100.403C297.77 100.403 288.772 103.98 281.834 111.135C275.113 118.073 271.21 128.48 270.126 142.356H345.902C345.686 130.214 342.542 120.241 336.471 112.436C330.617 104.414 321.403 100.403 308.827 100.403Z"
        fill={primaryColor}
      />
      <path
        d="M546.906 198.619C546.906 216.398 540.51 229.949 527.718 239.272C515.143 248.378 497.039 252.931 473.407 252.931C461.048 252.931 450.424 252.064 441.535 250.329C432.862 248.594 424.407 245.884 416.168 242.198V207.4C424.84 211.519 434.38 214.988 444.787 217.807C455.411 220.626 465.384 222.035 474.707 222.035C485.982 222.035 494.112 220.3 499.099 216.831C504.086 213.362 506.579 208.701 506.579 202.847C506.579 199.378 505.603 196.342 503.652 193.741C501.701 190.922 497.798 187.887 491.944 184.635C486.307 181.382 477.851 177.371 466.577 172.601C455.303 168.265 445.871 163.82 438.283 159.267C430.911 154.497 425.274 148.969 421.371 142.681C417.686 136.394 415.843 128.263 415.843 118.29C415.843 102.462 422.022 90.5375 434.38 82.5154C446.955 74.2765 463.65 70.157 484.464 70.157C495.305 70.157 505.495 71.2411 515.035 73.4092C524.791 75.5774 534.548 78.8296 544.305 83.1658L531.296 113.411C523.057 109.942 515.035 107.015 507.229 104.63C499.424 102.245 491.402 101.053 483.163 101.053C465.168 101.053 456.17 106.148 456.17 116.338C456.17 120.024 457.254 123.168 459.422 125.77C461.807 128.371 465.818 131.19 471.455 134.225C477.309 137.044 485.548 140.513 496.172 144.632C506.579 148.752 515.577 153.088 523.165 157.641C530.754 161.978 536.608 167.398 540.727 173.902C544.847 180.407 546.906 188.646 546.906 198.619Z"
        fill={primaryColor}
      />
      <path
        d="M606.823 5.43822C613.111 5.43822 618.423 7.06431 622.759 10.3165C627.312 13.3519 629.588 18.8806 629.588 26.9027C629.588 34.9249 627.312 40.562 622.759 43.8142C618.423 47.0664 613.111 48.6925 606.823 48.6925C600.535 48.6925 595.115 47.0664 590.562 43.8142C586.226 40.562 584.058 34.9249 584.058 26.9027C584.058 18.8806 586.226 13.3519 590.562 10.3165C595.115 7.06431 600.535 5.43822 606.823 5.43822ZM627.312 73.4092V249.679H586.334V73.4092H627.312Z"
        fill={primaryColor}
      />
      <path
        d="M738.898 70.157C761.23 70.157 778.683 78.8296 791.258 96.1746H792.885L796.787 73.4092H830.935V250.979C830.935 276.13 823.78 295.209 809.471 308.218C795.378 321.227 774.239 327.731 746.053 327.731C733.478 327.731 721.878 326.864 711.254 325.13C700.63 323.612 690.549 320.902 681.009 316.999V283.176C701.172 292.282 723.721 296.835 748.655 296.835C762.097 296.835 772.287 293.041 779.225 285.453C786.38 278.081 789.958 267.24 789.958 252.931V248.378C789.958 245.125 790.066 241.331 790.283 236.995C790.5 232.659 790.825 229.19 791.258 226.588H789.958C783.887 235.694 776.407 242.415 767.517 246.752C758.845 250.871 748.98 252.931 737.922 252.931C716.458 252.931 699.655 244.909 687.513 228.864C675.372 212.82 669.301 190.488 669.301 161.869C669.301 133.25 675.372 110.81 687.513 94.5485C699.872 78.2875 717 70.157 738.898 70.157ZM749.63 103.655C724.046 103.655 711.254 123.276 711.254 162.52C711.254 201.112 724.263 220.409 750.281 220.409C764.59 220.409 775.106 216.506 781.827 208.701C788.765 200.679 792.234 187.236 792.234 168.374V162.194C792.234 141.38 788.765 126.42 781.827 117.314C775.106 108.208 764.374 103.655 749.63 103.655Z"
        fill={primaryColor}
      />
      <path
        d="M977.602 70.157C997.332 70.157 1012.73 75.2522 1023.78 85.4424C1035.06 95.6326 1040.69 112.11 1040.69 134.876V249.679H999.717V143.006C999.717 116.555 988.876 103.329 967.195 103.329C950.717 103.329 939.443 108.533 933.372 118.94C927.301 129.347 924.266 144.199 924.266 163.495V249.679H883.288V73.4092H914.834L920.688 96.4999H922.965C928.602 87.3937 936.299 80.7809 946.056 76.6614C956.029 72.3252 966.544 70.157 977.602 70.157Z"
        fill={primaryColor}
      />
      <path
        d="M1270.82 17.4714L1190.49 249.679H1145.94L1065.61 17.4714H1108.21L1155.04 159.593C1156.34 163.495 1157.86 168.59 1159.6 174.878C1161.33 180.949 1162.96 187.236 1164.47 193.741C1166.21 200.245 1167.51 205.882 1168.38 210.652C1169.03 205.882 1170.11 200.245 1171.63 193.741C1173.15 187.236 1174.77 180.949 1176.51 174.878C1178.24 168.59 1179.87 163.495 1181.39 159.593L1228.22 17.4714H1270.82Z"
        fill={primaryColor}
      />
      <path
        d="M1361.01 70.157C1383.78 70.157 1401.02 75.1437 1412.72 85.1171C1424.65 95.0906 1430.61 110.484 1430.61 131.298V249.679H1401.67L1393.54 225.287H1392.23C1384.65 234.827 1376.62 241.873 1368.17 246.426C1359.71 250.763 1348.22 252.931 1333.7 252.931C1317.87 252.931 1304.75 248.486 1294.34 239.597C1283.94 230.707 1278.73 216.94 1278.73 198.294C1278.73 180.081 1285.35 166.422 1298.57 157.316C1312.01 148.21 1332.29 143.115 1359.39 142.031L1389.96 141.055V132.274C1389.96 121.217 1387.25 113.303 1381.83 108.533C1376.41 103.546 1368.82 101.053 1359.06 101.053C1350.17 101.053 1341.61 102.354 1333.37 104.956C1325.35 107.557 1317.33 110.593 1309.3 114.062L1296.29 85.4424C1304.97 80.8893 1314.83 77.2035 1325.89 74.3849C1337.16 71.5663 1348.87 70.157 1361.01 70.157ZM1389.96 166.422L1368.49 167.073C1350.5 167.723 1338.03 170.867 1331.09 176.504C1324.16 181.924 1320.69 189.296 1320.69 198.619C1320.69 207.075 1323.07 213.145 1327.84 216.831C1332.83 220.3 1339.33 222.035 1347.35 222.035C1359.28 222.035 1369.36 218.674 1377.6 211.953C1385.84 205.015 1389.96 194.825 1389.96 181.382V166.422Z"
        fill={primaryColor}
      />
      <path
        d="M1522.94 249.679H1481.96V2.51123H1522.94V249.679Z"
        fill={primaryColor}
      />
      <path
        d="M1616.31 249.679H1575.33V2.51123H1616.31V249.679Z"
        fill={primaryColor}
      />
      <path
        d="M1739.61 70.157C1763.24 70.157 1781.88 77.2035 1795.54 91.2963C1809.42 105.389 1816.36 125.011 1816.36 150.161V170.975H1699.93C1700.36 187.019 1704.81 199.486 1713.26 208.376C1721.72 217.048 1733.43 221.384 1748.39 221.384C1759.66 221.384 1769.85 220.3 1778.96 218.132C1788.06 215.747 1797.39 212.387 1806.93 208.05V240.898C1798.25 245.017 1789.26 248.052 1779.93 250.004C1770.61 251.955 1759.44 252.931 1746.44 252.931C1729.31 252.931 1714.02 249.679 1700.58 243.174C1687.35 236.453 1676.95 226.371 1669.36 212.929C1661.99 199.486 1658.3 182.792 1658.3 162.845C1658.3 132.708 1665.78 109.725 1680.74 93.8981C1695.7 78.0707 1715.32 70.157 1739.61 70.157ZM1739.61 100.403C1728.55 100.403 1719.55 103.98 1712.61 111.135C1705.89 118.073 1701.99 128.48 1700.9 142.356H1776.68C1776.46 130.214 1773.32 120.241 1767.25 112.436C1761.4 104.414 1752.18 100.403 1739.61 100.403Z"
        fill={primaryColor}
      />
      <path
        d="M1824.98 73.4092H1869.54L1904.66 172.927C1907.05 179.214 1909 185.393 1910.52 191.464C1912.03 197.535 1913.12 203.714 1913.77 210.002H1915.07C1915.94 204.581 1917.24 198.619 1918.97 192.115C1920.71 185.61 1922.66 179.214 1924.83 172.927L1958.65 73.4092H2002.88L1927.75 273.094C1921.03 290.656 1911.82 304.099 1900.11 313.422C1888.62 322.962 1874.42 327.731 1857.51 327.731C1852.09 327.731 1847.42 327.406 1843.52 326.756C1839.62 326.322 1836.15 325.78 1833.11 325.13V292.608C1835.5 293.041 1838.32 293.475 1841.57 293.909C1845.04 294.342 1848.62 294.559 1852.3 294.559C1862.49 294.559 1870.51 291.524 1876.37 285.453C1882.44 279.599 1887.1 272.227 1890.35 263.338L1895.56 249.028L1824.98 73.4092Z"
        fill={primaryColor}
      />
      <path
        d="M191.585 130.591C191.585 169.835 180.636 199.43 158.738 219.376C136.839 239.107 106.269 248.972 67.0255 248.972H1.65625V16.7643H73.8551C97.7046 16.7643 118.41 21.1006 135.972 29.7731C153.751 38.4457 167.41 51.2377 176.95 68.1491C186.707 84.8438 191.585 105.658 191.585 130.591ZM148.005 131.892C148.005 104.14 141.609 83.7597 128.817 70.7509C116.242 57.7421 97.9214 51.2377 73.8551 51.2377H43.2844V214.173H68.3264C121.446 214.173 148.005 186.746 148.005 131.892Z"
        fill={primaryColor}
      />
      <path
        d="M308.12 69.45C331.753 69.45 350.399 76.4964 364.058 90.5893C377.934 104.682 384.872 124.304 384.872 149.454V170.268H268.443C268.877 186.312 273.322 198.779 281.778 207.669C290.233 216.341 301.941 220.677 316.901 220.677C328.176 220.677 338.366 219.593 347.472 217.425C356.578 215.04 365.901 211.68 375.441 207.343V240.191C366.768 244.31 357.771 247.345 348.448 249.297C339.125 251.248 327.959 252.224 314.95 252.224C297.822 252.224 282.536 248.972 269.094 242.467C255.868 235.746 245.461 225.664 237.873 212.222C230.501 198.779 226.815 182.085 226.815 162.138C226.815 132.001 234.295 109.018 249.255 93.1911C264.216 77.3637 283.837 69.45 308.12 69.45ZM308.12 99.6955C297.063 99.6955 288.065 103.273 281.127 110.428C274.406 117.366 270.503 127.773 269.419 141.649H345.195C344.979 129.507 341.835 119.534 335.764 111.729C329.91 103.707 320.696 99.6955 308.12 99.6955Z"
        fill={primaryColor}
      />
      <path
        d="M546.199 197.912C546.199 215.691 539.803 229.242 527.011 238.564C514.436 247.671 496.332 252.224 472.7 252.224C460.341 252.224 449.717 251.356 440.828 249.622C432.155 247.887 423.7 245.177 415.461 241.491V206.693C424.133 210.812 433.673 214.281 444.08 217.1C454.704 219.919 464.677 221.328 474 221.328C485.275 221.328 493.405 219.593 498.392 216.124C503.379 212.655 505.872 207.994 505.872 202.14C505.872 198.671 504.896 195.635 502.945 193.034C500.994 190.215 497.091 187.18 491.237 183.927C485.6 180.675 477.144 176.664 465.87 171.894C454.596 167.558 445.164 163.113 437.576 158.56C430.204 153.79 424.567 148.262 420.664 141.974C416.978 135.687 415.136 127.556 415.136 117.583C415.136 101.755 421.315 89.8305 433.673 81.8084C446.248 73.5695 462.943 69.45 483.757 69.45C494.598 69.45 504.788 70.5341 514.328 72.7022C524.084 74.8703 533.841 78.1225 543.598 82.4588L530.589 112.704C522.35 109.235 514.328 106.308 506.522 103.923C498.717 101.538 490.695 100.346 482.456 100.346C464.461 100.346 455.463 105.441 455.463 115.631C455.463 119.317 456.547 122.461 458.715 125.063C461.1 127.664 465.111 130.483 470.748 133.518C476.602 136.337 484.841 139.806 495.465 143.925C505.872 148.045 514.87 152.381 522.458 156.934C530.047 161.271 535.901 166.691 540.02 173.195C544.14 179.7 546.199 187.939 546.199 197.912Z"
        fill={primaryColor}
      />
      <path
        d="M606.116 4.73119C612.404 4.73119 617.716 6.35728 622.052 9.60948C626.605 12.6449 628.881 18.1736 628.881 26.1957C628.881 34.2178 626.605 39.855 622.052 43.1072C617.716 46.3594 612.404 47.9855 606.116 47.9855C599.828 47.9855 594.408 46.3594 589.855 43.1072C585.519 39.855 583.351 34.2178 583.351 26.1957C583.351 18.1736 585.519 12.6449 589.855 9.60948C594.408 6.35728 599.828 4.73119 606.116 4.73119ZM626.605 72.7022V248.972H585.627V72.7022H626.605Z"
        fill={primaryColor}
      />
      <path
        d="M738.191 69.45C760.523 69.45 777.976 78.1225 790.551 95.4676H792.178L796.08 72.7022H830.228V250.272C830.228 275.423 823.073 294.502 808.764 307.511C794.671 320.52 773.532 327.024 745.346 327.024C732.771 327.024 721.171 326.157 710.547 324.423C699.923 322.905 689.842 320.195 680.302 316.292V282.469C700.465 291.575 723.014 296.128 747.948 296.128C761.39 296.128 771.58 292.334 778.518 284.746C785.673 277.374 789.251 266.533 789.251 252.224V247.671C789.251 244.418 789.359 240.624 789.576 236.288C789.793 231.952 790.118 228.483 790.551 225.881H789.251C783.18 234.987 775.7 241.708 766.81 246.045C758.138 250.164 748.273 252.224 737.215 252.224C715.751 252.224 698.948 244.202 686.806 228.157C674.665 212.113 668.594 189.781 668.594 161.162C668.594 132.543 674.665 110.103 686.806 93.8415C699.165 77.5805 716.293 69.45 738.191 69.45ZM748.923 102.948C723.339 102.948 710.547 122.569 710.547 161.813C710.547 200.405 723.556 219.702 749.574 219.702C763.883 219.702 774.399 215.799 781.12 207.994C788.058 199.972 791.527 186.529 791.527 167.666V161.487C791.527 140.673 788.058 125.713 781.12 116.607C774.399 107.501 763.667 102.948 748.923 102.948Z"
        fill={primaryColor}
      />
      <path
        d="M976.895 69.45C996.625 69.45 1012.02 74.5451 1023.08 84.7354C1034.35 94.9256 1039.99 111.403 1039.99 134.169V248.972H999.01V142.299C999.01 115.848 988.169 102.622 966.488 102.622C950.01 102.622 938.736 107.826 932.665 118.233C926.594 128.64 923.559 143.492 923.559 162.788V248.972H882.581V72.7022H914.127L919.981 95.7928H922.258C927.895 86.6867 935.592 80.0739 945.349 75.9544C955.322 71.6181 965.837 69.45 976.895 69.45Z"
        fill={primaryColor}
      />
      <path
        d="M1270.11 16.7643L1189.78 248.972H1145.23L1064.9 16.7643H1107.5L1154.34 158.886C1155.64 162.788 1157.15 167.883 1158.89 174.171C1160.62 180.242 1162.25 186.529 1163.77 193.034C1165.5 199.538 1166.8 205.175 1167.67 209.945C1168.32 205.175 1169.4 199.538 1170.92 193.034C1172.44 186.529 1174.07 180.242 1175.8 174.171C1177.53 167.883 1179.16 162.788 1180.68 158.886L1227.51 16.7643H1270.11Z"
        fill={primaryColor}
      />
      <path
        d="M1360.31 69.45C1383.07 69.45 1400.31 74.4367 1412.02 84.4101C1423.94 94.3835 1429.9 109.777 1429.9 130.591V248.972H1400.96L1392.83 224.58H1391.53C1383.94 234.12 1375.92 241.166 1367.46 245.719C1359.01 250.056 1347.51 252.224 1332.99 252.224C1317.16 252.224 1304.04 247.779 1293.64 238.89C1283.23 230 1278.03 216.233 1278.03 197.587C1278.03 179.374 1284.64 165.715 1297.86 156.609C1311.31 147.503 1331.58 142.408 1358.68 141.324L1389.25 140.348V131.567C1389.25 120.51 1386.54 112.596 1381.12 107.826C1375.7 102.839 1368.11 100.346 1358.36 100.346C1349.47 100.346 1340.9 101.647 1332.66 104.249C1324.64 106.85 1316.62 109.886 1308.6 113.355L1295.59 84.7354C1304.26 80.1823 1314.13 76.4964 1325.18 73.6779C1336.46 70.8593 1348.16 69.45 1360.31 69.45ZM1389.25 165.715L1367.79 166.366C1349.79 167.016 1337.32 170.16 1330.39 175.797C1323.45 181.217 1319.98 188.589 1319.98 197.912C1319.98 206.368 1322.36 212.438 1327.13 216.124C1332.12 219.593 1338.63 221.328 1346.65 221.328C1358.57 221.328 1368.65 217.967 1376.89 211.246C1385.13 204.308 1389.25 194.118 1389.25 180.675V165.715Z"
        fill={primaryColor}
      />
      <path
        d="M1522.23 248.972H1481.25V1.8042H1522.23V248.972Z"
        fill={primaryColor}
      />
      <path
        d="M1615.6 248.972H1574.63V1.8042H1615.6V248.972Z"
        fill={primaryColor}
      />
      <path
        d="M1738.9 69.45C1762.53 69.45 1781.18 76.4964 1794.84 90.5893C1808.71 104.682 1815.65 124.304 1815.65 149.454V170.268H1699.22C1699.66 186.312 1704.1 198.779 1712.56 207.669C1721.01 216.341 1732.72 220.677 1747.68 220.677C1758.95 220.677 1769.14 219.593 1778.25 217.425C1787.36 215.04 1796.68 211.68 1806.22 207.343V240.191C1797.55 244.31 1788.55 247.345 1779.23 249.297C1769.9 251.248 1758.74 252.224 1745.73 252.224C1728.6 252.224 1713.31 248.972 1699.87 242.467C1686.65 235.746 1676.24 225.664 1668.65 212.222C1661.28 198.779 1657.59 182.085 1657.59 162.138C1657.59 132.001 1665.07 109.018 1680.03 93.1911C1694.99 77.3637 1714.62 69.45 1738.9 69.45ZM1738.9 99.6955C1727.84 99.6955 1718.84 103.273 1711.91 110.428C1705.18 117.366 1701.28 127.773 1700.2 141.649H1775.97C1775.76 129.507 1772.61 119.534 1766.54 111.729C1760.69 103.707 1751.47 99.6955 1738.9 99.6955Z"
        fill={primaryColor}
      />
      <path
        d="M1824.28 72.7022H1868.83L1903.96 172.22C1906.34 178.507 1908.29 184.686 1909.81 190.757C1911.33 196.828 1912.41 203.007 1913.06 209.295H1914.36C1915.23 203.874 1916.53 197.912 1918.27 191.408C1920 184.903 1921.95 178.507 1924.12 172.22L1957.94 72.7022H2002.17L1927.05 272.387C1920.32 289.949 1911.11 303.392 1899.4 312.715C1887.91 322.254 1873.71 327.024 1856.8 327.024C1851.38 327.024 1846.72 326.699 1842.81 326.049C1838.91 325.615 1835.44 325.073 1832.41 324.423V291.901C1834.79 292.334 1837.61 292.768 1840.86 293.201C1844.33 293.635 1847.91 293.852 1851.59 293.852C1861.79 293.852 1869.81 290.817 1875.66 284.746C1881.73 278.892 1886.39 271.52 1889.65 262.631L1894.85 248.321L1824.28 72.7022Z"
        fill={primaryColor}
      />
      <path
        d="M190.878 129.884C190.878 169.128 179.929 198.723 158.031 218.669C136.132 238.399 105.562 248.264 66.3185 248.264H0.949219V16.0573H73.1481C96.9976 16.0573 117.703 20.3936 135.265 29.0661C153.044 37.7387 166.703 50.5306 176.243 67.4421C185.999 84.1367 190.878 104.951 190.878 129.884ZM147.298 131.185C147.298 103.433 140.902 83.0527 128.11 70.0438C115.535 57.035 97.2144 50.5306 73.1481 50.5306H42.5774V213.466H67.6194C120.739 213.466 147.298 186.039 147.298 131.185Z"
        fill={primaryColor}
      />
      <path
        d="M307.413 68.743C331.046 68.743 349.692 75.7894 363.351 89.8823C377.227 103.975 384.165 123.597 384.165 148.747V169.561H267.736C268.17 185.605 272.615 198.072 281.07 206.962C289.526 215.634 301.234 219.97 316.194 219.97C327.469 219.97 337.659 218.886 346.765 216.718C355.871 214.333 365.194 210.973 374.734 206.636V239.484C366.061 243.603 357.064 246.638 347.741 248.59C338.418 250.541 327.252 251.517 314.243 251.517C297.115 251.517 281.829 248.265 268.387 241.76C255.161 235.039 244.754 224.957 237.166 211.515C229.794 198.072 226.108 181.378 226.108 161.431C226.108 131.294 233.588 108.311 248.548 92.484C263.509 76.6567 283.13 68.743 307.413 68.743ZM307.413 98.9884C296.356 98.9884 287.358 102.566 280.42 109.721C273.699 116.659 269.796 127.066 268.712 140.942H344.488C344.272 128.8 341.128 118.827 335.057 111.022C329.203 103 319.988 98.9884 307.413 98.9884Z"
        fill={primaryColor}
      />
      <path
        d="M545.492 197.205C545.492 214.984 539.096 228.534 526.304 237.857C513.729 246.964 495.625 251.517 471.992 251.517C459.634 251.517 449.01 250.649 440.121 248.915C431.448 247.18 422.993 244.47 414.754 240.784V205.986C423.426 210.105 432.966 213.574 443.373 216.393C453.997 219.211 463.97 220.621 473.293 220.621C484.568 220.621 492.698 218.886 497.685 215.417C502.672 211.948 505.165 207.287 505.165 201.433C505.165 197.964 504.189 194.928 502.238 192.327C500.287 189.508 496.384 186.473 490.53 183.22C484.893 179.968 476.437 175.957 465.163 171.187C453.889 166.851 444.457 162.406 436.869 157.853C429.497 153.083 423.86 147.555 419.957 141.267C416.271 134.979 414.429 126.849 414.429 116.876C414.429 101.048 420.608 89.1234 432.966 81.1013C445.541 72.8624 462.236 68.743 483.05 68.743C493.891 68.743 504.081 69.827 513.621 71.9952C523.377 74.1633 533.134 77.4155 542.891 81.7518L529.882 111.997C521.643 108.528 513.621 105.601 505.815 103.216C498.01 100.831 489.988 99.6389 481.749 99.6389C463.754 99.6389 454.756 104.734 454.756 114.924C454.756 118.61 455.84 121.754 458.008 124.356C460.393 126.957 464.404 129.776 470.041 132.811C475.895 135.63 484.134 139.099 494.758 143.218C505.165 147.338 514.163 151.674 521.751 156.227C529.34 160.563 535.194 165.984 539.313 172.488C543.433 178.993 545.492 187.232 545.492 197.205Z"
        fill={primaryColor}
      />
      <path
        d="M605.409 4.02415C611.697 4.02415 617.009 5.65025 621.345 8.90245C625.898 11.9378 628.174 17.4666 628.174 25.4887C628.174 33.5108 625.898 39.1479 621.345 42.4001C617.009 45.6523 611.697 47.2784 605.409 47.2784C599.121 47.2784 593.701 45.6523 589.148 42.4001C584.812 39.1479 582.644 33.5108 582.644 25.4887C582.644 17.4666 584.812 11.9378 589.148 8.90245C593.701 5.65025 599.121 4.02415 605.409 4.02415ZM625.898 71.9952V248.264H584.92V71.9952H625.898Z"
        fill={primaryColor}
      />
      <path
        d="M737.484 68.743C759.816 68.743 777.269 77.4155 789.844 94.7606H791.471L795.373 71.9952H829.521V249.565C829.521 274.716 822.366 293.795 808.057 306.804C793.964 319.813 772.825 326.317 744.639 326.317C732.064 326.317 720.464 325.45 709.84 323.716C699.216 322.198 689.135 319.488 679.595 315.585V281.762C699.758 290.868 722.307 295.421 747.241 295.421C760.683 295.421 770.873 291.627 777.811 284.039C784.966 276.667 788.544 265.826 788.544 251.517V246.964C788.544 243.711 788.652 239.917 788.869 235.581C789.086 231.245 789.411 227.776 789.844 225.174H788.544C782.473 234.28 774.993 241.001 766.103 245.338C757.431 249.457 747.566 251.517 736.508 251.517C715.044 251.517 698.241 243.495 686.099 227.45C673.958 211.406 667.887 189.074 667.887 160.455C667.887 131.836 673.958 109.395 686.099 93.1345C698.458 76.8735 715.586 68.743 737.484 68.743ZM748.216 102.241C722.632 102.241 709.84 121.862 709.84 161.105C709.84 199.698 722.849 218.995 748.867 218.995C763.176 218.995 773.692 215.092 780.413 207.287C787.351 199.265 790.82 185.822 790.82 166.959V160.78C790.82 139.966 787.351 125.006 780.413 115.9C773.692 106.794 762.96 102.241 748.216 102.241Z"
        fill={primaryColor}
      />
      <path
        d="M976.188 68.743C995.918 68.743 1011.31 73.8381 1022.37 84.0283C1033.64 94.2186 1039.28 110.696 1039.28 133.462V248.264H998.303V141.592C998.303 115.141 987.462 101.915 965.781 101.915C949.303 101.915 938.029 107.119 931.958 117.526C925.887 127.933 922.852 142.785 922.852 162.081V248.264H881.874V71.9952H913.42L919.274 95.0858H921.551C927.188 85.9796 934.885 79.3668 944.642 75.2474C954.615 70.9111 965.13 68.743 976.188 68.743Z"
        fill={primaryColor}
      />
      <path
        d="M1269.41 16.0573L1189.08 248.264H1144.52L1064.19 16.0573H1106.8L1153.63 158.178C1154.93 162.081 1156.45 167.176 1158.18 173.464C1159.92 179.535 1161.54 185.822 1163.06 192.327C1164.79 198.831 1166.1 204.468 1166.96 209.238C1167.61 204.468 1168.7 198.831 1170.21 192.327C1171.73 185.822 1173.36 179.535 1175.09 173.464C1176.83 167.176 1178.45 162.081 1179.97 158.178L1226.8 16.0573H1269.41Z"
        fill={primaryColor}
      />
      <path
        d="M1359.6 68.743C1382.36 68.743 1399.6 73.7297 1411.31 83.7031C1423.23 93.6765 1429.2 109.07 1429.2 129.884V248.264H1400.25L1392.12 223.873H1390.82C1383.23 233.413 1375.21 240.459 1366.75 245.012C1358.3 249.349 1346.81 251.517 1332.28 251.517C1316.45 251.517 1303.34 247.072 1292.93 238.183C1282.52 229.293 1277.32 215.526 1277.32 196.88C1277.32 178.667 1283.93 165.008 1297.16 155.902C1310.6 146.796 1330.87 141.701 1357.97 140.617L1388.54 139.641V130.86C1388.54 119.803 1385.83 111.889 1380.41 107.119C1374.99 102.132 1367.4 99.6389 1357.65 99.6389C1348.76 99.6389 1340.19 100.94 1331.96 103.542C1323.93 106.143 1315.91 109.179 1307.89 112.648L1294.88 84.0283C1303.55 79.4752 1313.42 75.7894 1324.48 72.9708C1335.75 70.1523 1347.46 68.743 1359.6 68.743ZM1388.54 165.008L1367.08 165.659C1349.08 166.309 1336.62 169.453 1329.68 175.09C1322.74 180.51 1319.27 187.882 1319.27 197.205C1319.27 205.661 1321.66 211.731 1326.43 215.417C1331.41 218.886 1337.92 220.621 1345.94 220.621C1357.86 220.621 1367.95 217.26 1376.19 210.539C1384.42 203.601 1388.54 193.411 1388.54 179.968V165.008Z"
        fill={primaryColor}
      />
      <path
        d="M1521.52 248.264H1480.55V1.09717H1521.52V248.264Z"
        fill={primaryColor}
      />
      <path
        d="M1614.9 248.264H1573.92V1.09717H1614.9V248.264Z"
        fill={primaryColor}
      />
      <path
        d="M1738.19 68.743C1761.82 68.743 1780.47 75.7894 1794.13 89.8823C1808.01 103.975 1814.94 123.597 1814.94 148.747V169.561H1698.51C1698.95 185.605 1703.39 198.072 1711.85 206.962C1720.3 215.634 1732.01 219.97 1746.97 219.97C1758.25 219.97 1768.44 218.886 1777.54 216.718C1786.65 214.333 1795.97 210.973 1805.51 206.636V239.484C1796.84 243.603 1787.84 246.638 1778.52 248.59C1769.2 250.541 1758.03 251.517 1745.02 251.517C1727.89 251.517 1712.61 248.265 1699.16 241.76C1685.94 235.039 1675.53 224.957 1667.94 211.515C1660.57 198.072 1656.89 181.378 1656.89 161.431C1656.89 131.294 1664.37 108.311 1679.33 92.484C1694.29 76.6567 1713.91 68.743 1738.19 68.743ZM1738.19 98.9884C1727.13 98.9884 1718.14 102.566 1711.2 109.721C1704.48 116.659 1700.57 127.066 1699.49 140.942H1775.27C1775.05 128.8 1771.91 118.827 1765.84 111.022C1759.98 103 1750.77 98.9884 1738.19 98.9884Z"
        fill={primaryColor}
      />
      <path
        d="M1823.57 71.9952H1868.12L1903.25 171.513C1905.63 177.8 1907.58 183.979 1909.1 190.05C1910.62 196.121 1911.7 202.3 1912.35 208.588H1913.66C1914.52 203.167 1915.82 197.205 1917.56 190.701C1919.29 184.196 1921.24 177.8 1923.41 171.513L1957.23 71.9952H2001.46L1926.34 271.68C1919.62 289.242 1910.4 302.685 1898.7 312.008C1887.2 321.547 1873 326.317 1856.09 326.317C1850.67 326.317 1846.01 325.992 1842.11 325.342C1838.2 324.908 1834.74 324.366 1831.7 323.716V291.194C1834.08 291.627 1836.9 292.061 1840.16 292.494C1843.62 292.928 1847.2 293.145 1850.89 293.145C1861.08 293.145 1869.1 290.109 1874.95 284.039C1881.02 278.185 1885.69 270.813 1888.94 261.924L1894.14 247.614L1823.57 71.9952Z"
        fill={primaryColor}
      />
      <path
        d="M190.171 129.177C190.171 168.421 179.222 198.016 157.324 217.962C135.425 237.692 104.855 247.557 65.6114 247.557H0.242188V15.3503H72.4411C96.2905 15.3503 116.996 19.6865 134.558 28.3591C152.337 37.0316 165.996 49.8236 175.536 66.7351C185.292 83.4297 190.171 104.244 190.171 129.177ZM146.591 130.478C146.591 102.726 140.195 82.3456 127.403 69.3368C114.828 56.328 96.5074 49.8236 72.4411 49.8236H41.8704V212.759H66.9123C120.032 212.759 146.591 185.332 146.591 130.478Z"
        fill={hiddenColor}
      />
      <path
        d="M306.706 68.0359C330.339 68.0359 348.985 75.0824 362.644 89.1753C376.52 103.268 383.458 122.89 383.458 148.04V168.854H267.029C267.463 184.898 271.908 197.365 280.363 206.255C288.819 214.927 300.527 219.263 315.487 219.263C326.762 219.263 336.952 218.179 346.058 216.011C355.164 213.626 364.487 210.266 374.027 205.929V238.777C365.354 242.896 356.357 245.931 347.034 247.883C337.711 249.834 326.545 250.81 313.536 250.81C296.408 250.81 281.122 247.557 267.68 241.053C254.454 234.332 244.047 224.25 236.459 210.808C229.087 197.365 225.401 180.671 225.401 160.724C225.401 130.587 232.881 107.604 247.841 91.777C262.802 75.9496 282.423 68.0359 306.706 68.0359ZM306.706 98.2814C295.649 98.2814 286.651 101.859 279.713 109.014C272.992 115.952 269.089 126.359 268.005 140.235H343.781C343.565 128.093 340.421 118.12 334.35 110.315C328.496 102.292 319.281 98.2814 306.706 98.2814Z"
        fill={hiddenColor}
      />
      <path
        d="M544.785 196.498C544.785 214.277 538.389 227.827 525.597 237.15C513.022 246.257 494.918 250.81 471.285 250.81C458.927 250.81 448.303 249.942 439.414 248.208C430.741 246.473 422.286 243.763 414.047 240.077V205.279C422.719 209.398 432.259 212.867 442.666 215.686C453.29 218.504 463.263 219.914 472.586 219.914C483.861 219.914 491.991 218.179 496.978 214.71C501.965 211.241 504.458 206.58 504.458 200.726C504.458 197.257 503.482 194.221 501.531 191.62C499.58 188.801 495.677 185.766 489.823 182.513C484.186 179.261 475.73 175.25 464.456 170.48C453.182 166.144 443.75 161.699 436.162 157.146C428.79 152.376 423.153 146.848 419.25 140.56C415.564 134.272 413.721 126.142 413.721 116.169C413.721 100.341 419.901 88.4164 432.259 80.3943C444.834 72.1554 461.529 68.0359 482.343 68.0359C493.184 68.0359 503.374 69.12 512.914 71.2881C522.67 73.4563 532.427 76.7085 542.183 81.0447L529.175 111.29C520.936 107.821 512.914 104.894 505.108 102.509C497.303 100.124 489.281 98.9319 481.042 98.9319C463.047 98.9319 454.049 104.027 454.049 114.217C454.049 117.903 455.133 121.047 457.301 123.649C459.686 126.25 463.697 129.069 469.334 132.104C475.188 134.923 483.427 138.392 494.051 142.511C504.458 146.631 513.456 150.967 521.044 155.52C528.633 159.856 534.487 165.277 538.606 171.781C542.726 178.286 544.785 186.524 544.785 196.498Z"
        fill={hiddenColor}
      />
      <path
        d="M604.702 3.31712C610.99 3.31712 616.302 4.94322 620.638 8.19542C625.191 11.2308 627.467 16.7595 627.467 24.7816C627.467 32.8038 625.191 38.4409 620.638 41.6931C616.302 44.9453 610.99 46.5714 604.702 46.5714C598.414 46.5714 592.994 44.9453 588.441 41.6931C584.105 38.4409 581.937 32.8038 581.937 24.7816C581.937 16.7595 584.105 11.2308 588.441 8.19542C592.994 4.94322 598.414 3.31712 604.702 3.31712ZM625.191 71.2881V247.557H584.213V71.2881H625.191Z"
        fill={hiddenColor}
      />
      <path
        d="M736.777 68.0359C759.109 68.0359 776.562 76.7085 789.137 94.0536H790.763L794.666 71.2881H828.814V248.858C828.814 274.009 821.659 293.088 807.35 306.097C793.257 319.106 772.117 325.61 743.932 325.61C731.357 325.61 719.757 324.743 709.133 323.009C698.509 321.491 688.428 318.781 678.888 314.878V281.055C699.051 290.161 721.6 294.714 746.534 294.714C759.976 294.714 770.166 290.92 777.104 283.332C784.259 275.96 787.836 265.119 787.836 250.81V246.257C787.836 243.004 787.945 239.21 788.162 234.874C788.379 230.538 788.704 227.069 789.137 224.467H787.836C781.766 233.573 774.286 240.294 765.396 244.63C756.724 248.75 746.859 250.81 735.801 250.81C714.337 250.81 697.534 242.788 685.392 226.743C673.251 210.699 667.18 188.367 667.18 159.748C667.18 131.129 673.251 108.688 685.392 92.4275C697.75 76.1664 714.879 68.0359 736.777 68.0359ZM747.509 101.534C721.925 101.534 709.133 121.155 709.133 160.398C709.133 198.991 722.142 218.288 748.16 218.288C762.469 218.288 772.985 214.385 779.706 206.58C786.644 198.558 790.113 185.115 790.113 166.252V160.073C790.113 139.259 786.644 124.299 779.706 115.193C772.985 106.087 762.253 101.534 747.509 101.534Z"
        fill={hiddenColor}
      />
      <path
        d="M975.481 68.0359C995.211 68.0359 1010.6 73.1311 1021.66 83.3213C1032.94 93.5115 1038.57 109.989 1038.57 132.755V247.557H997.596V140.885C997.596 114.434 986.755 101.208 965.074 101.208C948.596 101.208 937.322 106.412 931.251 116.819C925.18 127.226 922.145 142.078 922.145 161.374V247.557H881.167V71.2881H912.713L918.567 94.3788H920.844C926.481 85.2726 934.178 78.6598 943.935 74.5404C953.908 70.2041 964.423 68.0359 975.481 68.0359Z"
        fill={hiddenColor}
      />
      <path
        d="M1268.7 15.3503L1188.37 247.557H1143.82L1063.49 15.3503H1106.09L1152.92 157.471C1154.22 161.374 1155.74 166.469 1157.47 172.757C1159.21 178.828 1160.84 185.115 1162.35 191.62C1164.09 198.124 1165.39 203.761 1166.26 208.531C1166.91 203.761 1167.99 198.124 1169.51 191.62C1171.03 185.115 1172.65 178.828 1174.39 172.757C1176.12 166.469 1177.75 161.374 1179.26 157.471L1226.1 15.3503H1268.7Z"
        fill={hiddenColor}
      />
      <path
        d="M1358.89 68.0359C1381.66 68.0359 1398.89 73.0226 1410.6 82.9961C1422.53 92.9695 1428.49 108.363 1428.49 129.177V247.557H1399.55L1391.41 223.166H1390.11C1382.53 232.706 1374.5 239.752 1366.05 244.305C1357.59 248.642 1346.1 250.81 1331.57 250.81C1315.75 250.81 1302.63 246.365 1292.22 237.476C1281.82 228.586 1276.61 214.819 1276.61 196.173C1276.61 177.96 1283.22 164.301 1296.45 155.195C1309.89 146.089 1330.16 140.994 1357.27 139.91L1387.84 138.934V130.153C1387.84 119.095 1385.13 111.182 1379.71 106.412C1374.29 101.425 1366.7 98.9319 1356.94 98.9319C1348.05 98.9319 1339.49 100.233 1331.25 102.834C1323.23 105.436 1315.2 108.472 1307.18 111.941L1294.17 83.3213C1302.85 78.7682 1312.71 75.0824 1323.77 72.2638C1335.04 69.4452 1346.75 68.0359 1358.89 68.0359ZM1387.84 164.301L1366.37 164.952C1348.38 165.602 1335.91 168.746 1328.97 174.383C1322.03 179.803 1318.57 187.175 1318.57 196.498C1318.57 204.954 1320.95 211.024 1325.72 214.71C1330.71 218.179 1337.21 219.914 1345.23 219.914C1357.16 219.914 1367.24 216.553 1375.48 209.832C1383.72 202.894 1387.84 192.704 1387.84 179.261V164.301Z"
        fill={hiddenColor}
      />
      <path
        d="M1520.82 247.557H1479.84V0.390137H1520.82V247.557Z"
        fill={hiddenColor}
      />
      <path
        d="M1614.19 247.557H1573.21V0.390137H1614.19V247.557Z"
        fill={hiddenColor}
      />
      <path
        d="M1737.48 68.0359C1761.12 68.0359 1779.76 75.0824 1793.42 89.1753C1807.3 103.268 1814.24 122.89 1814.24 148.04V168.854H1697.81C1698.24 184.898 1702.69 197.365 1711.14 206.255C1719.6 214.927 1731.31 219.263 1746.27 219.263C1757.54 219.263 1767.73 218.179 1776.84 216.011C1785.94 213.626 1795.27 210.266 1804.8 205.929V238.777C1796.13 242.896 1787.13 245.931 1777.81 247.883C1768.49 249.834 1757.32 250.81 1744.31 250.81C1727.19 250.81 1711.9 247.557 1698.46 241.053C1685.23 234.332 1674.83 224.25 1667.24 210.808C1659.87 197.365 1656.18 180.671 1656.18 160.724C1656.18 130.587 1663.66 107.604 1678.62 91.777C1693.58 75.9496 1713.2 68.0359 1737.48 68.0359ZM1737.48 98.2814C1726.43 98.2814 1717.43 101.859 1710.49 109.014C1703.77 115.952 1699.87 126.359 1698.78 140.235H1774.56C1774.34 128.093 1771.2 118.12 1765.13 110.315C1759.27 102.292 1750.06 98.2814 1737.48 98.2814Z"
        fill={hiddenColor}
      />
      <path
        d="M1822.86 71.2881H1867.42L1902.54 170.806C1904.93 177.093 1906.88 183.272 1908.4 189.343C1909.91 195.414 1911 201.593 1911.65 207.881H1912.95C1913.82 202.46 1915.12 196.498 1916.85 189.993C1918.59 183.489 1920.54 177.093 1922.7 170.806L1956.53 71.2881H2000.76L1925.63 270.973C1918.91 288.535 1909.7 301.978 1897.99 311.301C1886.5 320.84 1872.3 325.61 1855.38 325.61C1849.96 325.61 1845.3 325.285 1841.4 324.635C1837.5 324.201 1834.03 323.659 1830.99 323.009V290.487C1833.38 290.92 1836.2 291.354 1839.45 291.787C1842.92 292.221 1846.49 292.438 1850.18 292.438C1860.37 292.438 1868.39 289.402 1874.25 283.332C1880.32 277.478 1884.98 270.106 1888.23 261.217L1893.44 246.907L1822.86 71.2881Z"
        fill={hiddenColor}
      />
    </svg>
  );
}

export default React.memo(Logo);
