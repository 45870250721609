const theme = {
  colors: {
    featured: "#f7a325",
    neutrals: {
      100: "#fff",
      200: "#f4f4f4",
      300: "#DBDBDB",
      400: "#BBBBBB",
      500: "#A0A0A0",
      600: "#898989",
      700: "#515151",
      800: "#383838",
      900: "#212121"
    },
    // primary: {
    //   100: "#F13673",
    //   200: "#EC1058",
    //   300: "#CA0E4B",
    //   400: "#AD0C40",
    //   500: "#940A37",
    //   600: "#7B082E",
    //   700: "#660726",
    //   800: "#550620",
    //   900: "#47051B"
    // },
    // rose
    // primary: {
    //   100: "#CD16DA",
    //   200: "#AF13BA",
    //   300: "#96109F",
    //   400: "#800E88",
    //   500: "#6D0C74",
    //   600: "#5A0A60",
    //   700: "#4B0850",
    //   800: "#3E0742",
    //   900: "#330637"
    // },
    primary: {
      100: "#D6CFFD",
      200: "#B4A7FB",
      300: "#9583F9",
      400: "#7962F8",
      500: "#5F44F7",
      600: "#4526F6",
      700: "#2E0BF5",
      800: "#2909DD",
      900: "#2508C7"
    }
  }
};

export default theme;
